import React, { useEffect, useRef } from 'react';


const Profilechart = ({ data1 , themes }) => {
  const widgetRef = useRef(null);


  useEffect(() => {
    if (data1) {
      const stockValue = data1?.stockData?.stock_code;
      // console.log('stockValue', stockValue);

      if (stockValue && widgetRef.current) {
        // Remove any existing scripts
        Array.from(widgetRef.current.getElementsByTagName('script')).forEach(script => {
          script.remove();
        });

        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js';
        script.async = true;

        // Use `setAttribute` to safely set the JSON configuration
        const scriptContent = {
          "width": "100%",
          "height": "auto",
          "isTransparent": true,
          "colorTheme": themes?"dark":"light",
          "symbol": stockValue,
          "locale": "en"
        };

        // Convert the scriptContent to a JSON string and set it using textContent
        script.textContent = JSON.stringify(scriptContent);
        widgetRef.current.appendChild(script);
      }
    }

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      if (widgetRef.current) {
        widgetRef.current.innerHTML = '';
      }
    };
  }, [data1]);

  return (
    <div>
      <div className="tradingview-widget-container" ref={widgetRef} style={{ height: 'auto' }}>
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
            {/* Add any text or branding information here */}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Profilechart;
