import React, { useEffect, useState, useContext } from 'react';
import FetchData from '../fetchapi/Apifetch';
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../components/Utills/UserContext';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS
import "./screenerstock.css"

const Screener = () => {
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(UserContext);
  const [activeButton, setActiveButton] = useState(0);
  const [Stockscreener, setStockscreener] = useState('');
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [filterdata, setFilterdata] = useState(false); // Add loading state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [industry, setindustry] = useState('');
  const [sectorvalue, setsectorvalue] = useState('');

  const buttons = [
    'Most Active',
    'Most Popular',
    'Top Gainers',
    'Top Losers',
    '52 week High',
  ];
  const industries = [
    { id: '1', name: 'Technology' },
    { id: '2', name: 'Healthcare' },
    { id: '3', name: 'Finance' },
    { id: '4', name: 'Energy' },
    // Add more industries as needed
  ];
  const Exchange = [
    { id: '1', name: 'BSE' },
    { id: '2', name: 'NSE' },

    // Add more industries as needed
  ];
  const Compliant = [
    { id: '1', name: 'Complient' },
    { id: '2', name: 'Non-complient' },
    { id: '3', name: 'Unknow' },
    { id: '4', name: 'Under Review' },

    // Add more industries as needed
  ];

  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const [selectedsector, setSelectedsector] = useState([]);
  const [selectedexchange, setSelectedexchange] = useState([]);
  const [selectedstatus, setSelectedstatus] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedIndustries((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((industryId) => industryId !== id)
        : [...prevSelected, id]
    );
  };

  const handleCheckboxstatus = (id) => {
    setSelectedstatus((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((industryId) => industryId !== id)
        : [...prevSelected, id]
    );
  };



  const handleCheckboxSector = (id) => {
    setSelectedsector((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((industryId) => industryId !== id)
        : [...prevSelected, id]
    );
  };


  const handleCheckboxexChange = (id) => {
    setSelectedexchange((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((industryId) => industryId !== id)
        : [...prevSelected, id]
    );
  };
  const handleRowClick = (item) => {
    navigate('/stock', { state: { value: item } });
  };

  const NextPage = () => {
    setPage(page + 1);
  };
  const NextPageall = () => {
    setPage(page + 2);
  };

  const PreviousPage = () => {
    setPage(page - 1);
  };
  const PreviousPageall = () => {
    setPage(page - 2);
  };

  useEffect(() => {
    if (!filterdata) {

      Stockscreenerlist();
    }
    else {
      Filterdata();
    }
  }, [page]);


  // useEffect(() => {
  //   Filterdata();
  // }, [page]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);  // Adjust the width threshold as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the handler on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(() => {
    console.log('selectedIndustries', selectedIndustries)
  }, [selectedIndustries]);
  useEffect(() => {
    console.log('selectedsector', selectedsector)
  }, [selectedsector]);
  useEffect(() => {
    console.log('selectedexchange', selectedexchange)
  }, [selectedexchange]);



  const Stockscreenerlist = async () => {
    setLoading(true); // Set loading to true before fetching
    const res = await FetchData(`stocklist?page=${page}&limit=10`, 'GET', null, true, false);
    if (res) {
      setStockscreener(res.data.data);
      setPaginationInfo(res.data.pagination);
    }
    setLoading(false); // Set loading to false after fetching
  };


 const Filterdata = async () => {
    setLoading(true);
    const dataObject = {
      limit: 12 || 10,    // Default limit is 10
      page: page || 1,       // Default page is 1
      filters: {
        sectors: selectedsector || [],  // Send sectors array
        industries: selectedIndustries || [],  // Send industries array
        exchanges: selectedexchange || [],  // Send exchanges array
        status:selectedstatus || []
       }
    }
    try {
      const response = await FetchData(`stock-filter?page=${page}&limit=12`, 'POST', JSON.stringify(dataObject), false, false);

      // Response data contains the stock data and pagination
      // console.log(response.data);
      setFilterdata(true);
      setStockscreener(response.data);
      setPaginationInfo(response.pagination);
      setLoading(false);
      

    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };
  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.screenerData.stock;

    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase();
    };

    return (
      <div>
        {!imageError ? (
          <img
            className=""
            src={item?.stockData?.img || ""}
            alt="img"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              marginTop: '-5px'
            }}
            onError={() => setImageError(true)}
          />
        ) : (
          <div
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f0f0f0",
              color: theme ? 'black' : 'black',
              fontSize: "16px",
              fontWeight: 500,
              marginTop: '-5px'
            }}
          >
            {splitStock(item?.screenerData?.stock)}
          </div>
        )}
      </div>
    );
  };

  const styles = {
    pageLink: {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: theme ? "2px solid #ddd" : '1px solid #949393',
      color: theme ? "#fff" : 'black',
      backgroundColor: "transparent",
      marginRight: "5px",
      cursor: "pointer",
    },
    activePage: {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#7E66BC", // Active background color
      color: "#fff", // Active text color
      border: "none",
      marginRight: "5px",
    },
  };

  const PaginationIndex = () => {
    return (
      <div className="mx-auto my-4 text-white pagination-container">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center" style={{ marginLeft: "10px" }}>
          {/* Previous Button */}
          <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
            <img
              onClick={PreviousPageall}
              className="pagination-img"
              src={theme ? "/images/icons/leftdouble.png" : "/images/icons/darkleftdouble.png"}
              alt="Previous All"
            />
          </li>

          <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
            <img
              onClick={PreviousPage}
              className="pagination-img"
              src={theme ? "/images/icons/left.png" : "/images/icons/darkleft.png"}
              alt="Previous"
            />
          </li>

          {/* Page Number Buttons */}
          {paginationNumbers().map((pageNumber) => (
            <li
              key={pageNumber}
              className={`page-item ${page === pageNumber ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => {
                  if (pageNumber !== "...") {
                    setPage(pageNumber);
                  } else {
                    setPage(paginationInfo?.totalPages); // Go to last page
                  }
                }}
                style={page === pageNumber ? styles.activePage : styles.pageLink}
              >
                {pageNumber}
              </button>
            </li>
          ))}

          {/* Next Button */}
          <li className={`page-item ${page === paginationInfo?.totalPages ? "disabled" : ""}`}>
            <img
              onClick={NextPage}
              className="pagination-img"
              src={theme ? "/images/icons/right1.png" : "/images/icons/darkright.png"}
              alt="Next"
            />
          </li>

          <li className={`page-item ${page === paginationInfo?.totalPages ? "disabled" : ""}`}>
            <img
              onClick={NextPageall}
              className="pagination-img"
              src={theme ? "/images/icons/rightdouble.png" : "/images/icons/darkrightdouble.png"}
              alt="Next All"
            />
          </li>
        </ul>
      </nav>

      {/* Pagination Info */}
      <div
        className={`pagination my-2 text-center ${
          theme ? "text-white" : "text-dark"
        } d-flex justify-content-center pagination-info`}
      >
        <p>Total Records: {paginationInfo?.totalItems}</p>
        <p style={{ paddingLeft: "10px" }}>Total Pages: {paginationInfo?.totalPages}</p>
      </div>
    </div>
    );
  };
  

  
  
  const paginationNumbers = () => {
    const totalPages = paginationInfo?.totalPages || 0;
    const currentPage = page;

    const paginationRange = isMobile ? 2 : 4; // Show fewer pages on mobile

    const numbersToShow = [];

    // Always add the first page
    numbersToShow.push(1);

    // Add pages around the current page
    const startPage = Math.max(currentPage - paginationRange, 2);
    const endPage = Math.min(currentPage + paginationRange, totalPages - 1);

    // Add dots if there is a gap between the first page and the start of the range
    if (startPage > 2) {
      numbersToShow.push("...");
    }

    // Add the range of numbers around the current page
    for (let i = startPage; i <= endPage; i++) {
      numbersToShow.push(i);
    }

    // Add dots if there is a gap between the end of the range and the last page
    if (endPage < totalPages - 1) {
      numbersToShow.push("...");
    }

    // Always add the last page if there are more than one page
    if (totalPages > 1) {
      numbersToShow.push(totalPages);
    }

    return numbersToShow;
  };

  



  useEffect(() => {
    fetchData3();
    fetchsecctor();
  }, []);

  const fetchData3 = async () => {
    const res = await FetchData(
      `industrytasis`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setindustry(res.data)
    }
  };
  const fetchsecctor = async () => {
    const res = await FetchData(
      `sectorvalue`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setsectorvalue(res.data)

    }
  };
  // const [selectedexchange, setSelectedExchange] = useState([]);
  // const [selectedsector, setSelectedSector] = useState([]);
  // const [selectedIndustries, setSelectedIndustries] = useState([]);


  const handleclear = () => {
    setFilterdata(false);
    setSelectedexchange('')
    setSelectedsector('')
    setSelectedIndustries('')
    Stockscreenerlist();
  }

  const handleCheckboxChange1 = (value, type) => {
    if (type === 'exchange') {
      setSelectedexchange(prev =>
        prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
      );
    } else if (type === 'sector') {
      setSelectedsector(prev =>
        prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
      );
    } else if (type === 'industry') {
      setSelectedIndustries(prev =>
        prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className='container-fluid padding-header' style={{width: '95%',minHeight:"85vh" }}>
        <div className='row justify-content-center'>
          <div className='card col-lg-12 col-md-12 col-sm-12' style={{ backgroundColor: theme ? "#1B1B1B" : '#f2f2f2' }}>
            <div className='mt-2 p-3'>
           
              <div className='d-flex justify-content-around align-items-center' style={{ borderRadius: '10px', padding: '1rem', flexDirection: isMobile ? 'column' : 'row'  }}>
      <div>
        <h5 className={`fs-4 fw-bold ${theme ? 'text-white' : 'text-dark'}`}>Stock Screener</h5>
      </div>

      <div className="dropdown-container d-flex" style={{ gap: '5px', flexDirection: isMobile ? 'column' : 'row' }}>
        {/* Exchange Dropdown */}
        <div className="dropdown-item1">
          <div className="custom-dropdown">
            <div
              className="dropdown-toggle d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: theme ? "#2C2C2C" : "#fff",
                color: theme ? "#fff" : "#333",
                border: `1px solid ${theme ? "#333" : "#ddd"}`,
                padding: '6px 12px',
                borderRadius: '8px',
                width: '220px',
                cursor: 'pointer'
              }}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Exchanges</span>
              {selectedexchange.length !== 0 && (
                <span className='alian-center' style={{ backgroundColor: 'rgb(126, 102, 188)', color: '#fff', borderRadius: '50%', padding: '4px 10px', fontSize: '12px', width: '26px' }}>
                  {selectedexchange.length}
                </span>
              )}
            </div>
            <ul className="dropdown-menu" style={{
              maxHeight: '200px',
              overflowY: 'auto',
              borderRadius: '8px',
              backgroundColor: theme ? "gray" : '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              border: '1px solid #ddd'
            }}>
              {Exchange && Exchange.map((exchange) => (
                <li
                  key={exchange.id}
                  style={{
                    padding: '10px 8px',
                    marginBottom: '5px',
                    backgroundColor: theme ? "#333" : "#f9f9f9",
                    borderRadius: '5px',
                    transition: 'background-color 0.3s ease'
                  }}
                  className={`text-center ${theme ? 'text-white' : 'text-dark'} `}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme ? "#444" : "#eee"}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = theme ? "#333" : "#f9f9f9"}
                >
                  <input
                    type="checkbox"
                    id={`exchange-${exchange.id}`}
                    checked={selectedexchange.includes(exchange.name)}
                    onChange={() => handleCheckboxChange1(exchange.name, 'exchange')}
                    style={{
                      transform: 'scale(1.2)',
                      marginRight: '8px',
                      accentColor: theme ? "#fff" : "rgb(127 102 188)"
                    }}
                  />
                  <label
                    className='ms-3'
                    htmlFor={`exchange-${exchange.id}`}
                    style={{
                      color: theme ? "#fff" : "#333",
                      marginLeft: '8px',
                      fontWeight: '500',
                      cursor: 'pointer',
                    }}
                  >
                    {exchange.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Sector Dropdown */}
        <div className="dropdown-item1">
          <div className="custom-dropdown">
            <div
              className="dropdown-toggle d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: theme ? "#2C2C2C" : "#fff",
                color: theme ? "#fff" : "#333",
                border: `1px solid ${theme ? "#333" : "#ddd"}`,
                padding: '6px 12px',
                borderRadius: '8px',
                width: '220px',
                cursor: 'pointer'
              }}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Sectors</span>
              {selectedsector.length !== 0 && (
                <span style={{ backgroundColor: 'rgb(126, 102, 188)', color: '#fff', borderRadius: '50%', padding: '4px 10px', fontSize: '12px', width: '26px' }}>
                  {selectedsector.length}
                </span>
              )}
            </div>
            <ul className="dropdown-menu" style={{
              maxHeight: '200px',
              overflowY: 'auto',
              borderRadius: '8px',
              backgroundColor: theme ? "gray" : '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              border: '1px solid #ddd'
            }}>
              {sectorvalue && sectorvalue.map((sector) => (
                <li
                  key={sector.id}
                  style={{
                    padding: '10px 8px',
                    marginBottom: '5px',
                    backgroundColor: theme ? "#333" : "#f9f9f9",
                    borderRadius: '5px',
                    transition: 'background-color 0.3s ease'
                  }}
                  className={` ${theme ? 'text-white' : 'text-dark'} `}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme ? "#444" : "#eee"}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = theme ? "#333" : "#f9f9f9"}
                >
                  <input
                    type="checkbox"
                    id={`sector-${sector.id}`}
                    checked={selectedsector.includes(sector.sector)}
                    onChange={() => handleCheckboxChange1(sector.sector, 'sector')}
                    style={{
                      transform: 'scale(1.2)',
                      marginRight: '8px',
                      accentColor: theme ? "#fff" : "rgb(127 102 188)"
                    }}
                  />
                  <label
                    htmlFor={`sector-${sector.id}`}
                    style={{
                      color: theme ? "#fff" : "#333",
                      marginLeft: '8px',
                      fontWeight: '500',
                      cursor: 'pointer',
                    }}
                  >
                    {sector.sector}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Industry Dropdown */}
        <div className="dropdown-item1">
          <div className="custom-dropdown">
            <div
              className="dropdown-toggle d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: theme ? "#2C2C2C" : "#fff",
                color: theme ? "#fff" : "#333",
                border: `1px solid ${theme ? "#333" : "#ddd"}`,
                padding: '6px 12px',
                borderRadius: '8px',
                width: '220px',
                cursor: 'pointer'
              }}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Industries</span>
              {selectedIndustries.length !== 0 && (
                <span style={{ backgroundColor: 'rgb(126, 102, 188)', color: '#fff', borderRadius: '50%', padding: '4px 10px', fontSize: '12px', width: '26px' }}>
                  {selectedIndustries.length}
                </span>
              )}
            </div>
            <ul
              className="dropdown-menu"
              style={{
                maxHeight: '250px',
                width: '400px',
                overflowY: 'auto',
                borderRadius: '12px',
                backgroundColor: theme ? '#1e1e2d' : '#ffffff',
                padding: '10px',
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
                border: theme ? '1px solid #333' : '1px solid #ddd',
              }}
            >
              {industry && industry.map((industry) => (
                <li
                  key={industry.id}
                  style={{
                    padding: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    marginBottom: '8px',
                    backgroundColor: selectedIndustries.includes(industry.industry_group)
                      ? theme
                        ? 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
                        : 'linear-gradient(135deg, #fce38a 0%, #f38181 100%)'
                      : 'transparent',
                    transition: 'all 0.3s ease',
                    boxShadow: selectedIndustries.includes(industry.industry_group)
                      ? '0 4px 12px rgba(0, 0, 0, 0.15)'
                      : 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCheckboxChange(industry.industry_group, 'industries')}
                >
                  <span style={{ color: theme ? '#ffffff' : '#333333' }}>{industry.industry_group}</span>
                  {selectedIndustries.includes(industry.industry_group) && (
                    <span
                      style={{
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '50%',
                        padding: '4px 10px',
                        fontSize: '12px',
                      }}
                    >
                      &#10003;
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

                <div style={{marginTop:isMobile?"2%":""}}>
                  <button className='btn btn-sm me-2' style={{ backgroundColor: '#4B4B4B', color: 'white' }} onClick={() => { handleclear() }}>
                    Reset
                  </button>

                  <button className='btn btn-sm' style={{ backgroundColor: '#7E66BC', color: 'white' }}
                    onClick={() => { 
                      setFilterdata(true);
                      Filterdata(); 
                      setPage(1);
                      }}>
                    Apply Filter
                  </button>
                </div>

              </div>


              <div className="table-responsive"> {/* Make table responsive */}
                <table className="table mt-2 table-responsive" style={{ border: theme ? "0.5px solid #222222" : "0.5px solid rgba(0, 0, 0, 0.175)", textAlign: "start", backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Name</th>
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Sector</th>
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Industry</th>
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Exchange</th>
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Compliance</th>

                      {/* <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">BSE/NSE</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">52 Weeks High</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">52 Weeks Low</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">Average Volume 10 days</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">Beta 1 year</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      // Render Skeleton Loader for full row width
                      Array.from({ length: 10 }).map((_, index) => (
                        <tr key={index}>
                          <td colSpan={8} style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                            <Skeleton height={40} style={{ width: '100%' }} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      Array.isArray(Stockscreener) && Stockscreener
                        .slice(0, paginationInfo?.totalPages === page ? 7 : Stockscreener.length)
                        .map((item, index) => (
                          <tr className='mx-auto' key={index} onClick={() => (handleRowClick(item))} style={{ cursor: "pointer" }}>
                            <th scope="row" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black' }}>
                              <div className='d-flex justify-content-start'>
                                <Imageview item={item} />
                                <div className=''>

                                  <p className='mb-0' style={{ padding: "0px 10px", fontSize: "14px", fontWeight: '500', letterSpacing: '1px' }}>{item?.stockData?.stock_code}</p>
                                  <h3 className='mb-0' style={{ padding: "0px 10px", fontSize: "12px", color: 'gray' }}>{item?.screenerData.stock}</h3>

                                </div>
                              </div>
                            </th>
                            <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                              {item?.TechinicalData?.sector}
                            </td>
                            <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                              {item?.screenerData?.industry_group}
                            </td>
                            <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                              {item?.stockData?.exchange}
                            </td>
                            {item?.screenerData?.status == 1 ? (
                              <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                                <div className="d-flex p-1 justify-content-center" >
                                  <span className="heder-title" style={{ color: '#218303', fontWeight: "500" }}>Compliant</span>
                                  <span className="ms-1"><img src="/images/checklist.png" width={20} height={20} ></img></span>
                                </div>
                              </td>
                            ) : item?.screenerData?.status == 2 || item?.screenerData?.status == 4 || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ? (
                              <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                                <div className="d-flex p-1 justify-content-center">
                                  <span className="heder-title" style={{ color: '#D43A3A', fontWeight: "500" }}>Non Compliant</span>
                                  <span className="ms-1"><img src="/images/non-permisable.png" width={20} height={20} ></img></span>
                                </div>
                              </td>
                            ) : item?.screenerData?.status == 3 ? (
                              <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                                <div className="d-flex p-1 justify-content-center" >
                                  <span className="heder-title" style={{ color: '#2132CA', fontWeight: "500" }}>Unknown</span>
                                  <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                                </div>
                              </td>
                            ) :
                              (<td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                                <div className="d-flex p-1 justify-content-center">
                                  <span className="heder-title" style={{ color: '#EFA625', fontWeight: "500" }}>Under Review</span>
                                  <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                                </div>
                              </td>
                              )
                            }

                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className='d-flex justify-content-center'>
                <PaginationIndex />
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div
                className="modal-content"
                style={{
                  borderRadius: "15px", // More rounded corners
                  overflow: "hidden",
                  backgroundColor: theme ? '#2c2c2c' : '#fff',
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" // Subtle shadow for depth
                }}
              >
                {/* Modal Header */}
                <div
                  className="modal-header text-white"
                  style={{
                    background: "linear-gradient(90deg, rgba(126,102,188,1) 0%, rgba(154,123,187,1) 100%)",
                    borderBottom: "none",
                    borderRadius: "15px 15px 0 0", // Rounded top corners
                    padding: "1.5rem 2rem", // More padding for a spacious look
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Centered Title and Icon */}
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <h2 className="mb-0 me-3" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Filter</h2>
                    {/* <i className="fa-solid fa-filter fs-5" style={{ color: theme ? "white" : "black" }}></i> */}
                  </div>
                  {/* Close Button */}
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                {/* Modal Body */}
                <div
                  className="modal-body"
                  style={{
                    padding: "1.5rem 2rem",
                    backgroundColor: theme ? '#2c2c2c' : '#fff'
                  }}
                >
                  <div className="accordion" id="accordionExample" style={{ border: "none" }}>
                    {[
                      {
                        id: "1", title: "Exchange",
                        content: (
                          <div className="p-2"
                            style={{
                              maxHeight: '200px', // Adjust the height as needed
                              overflowY: 'auto',  // Enable vertical scrolling
                            }}>
                            {Exchange && Exchange.map((exchange) => (
                              <div key={exchange.id} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`industry-${exchange.id}`}
                                  checked={selectedexchange.includes(exchange.name)}
                                  onChange={() => handleCheckboxexChange(exchange.name)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`industry-${exchange.id}`}
                                  style={{ color: theme ? "#fff" : "#333" }}
                                >
                                  {exchange.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        )
                      },
                      {
                        id: "2", title: "Sector",
                        content: (
                          <div className="p-2"
                            style={{
                              maxHeight: '200px', // Adjust the height as needed
                              overflowY: 'auto',  // Enable vertical scrolling
                            }}>
                            {sectorvalue && sectorvalue.map((sector) => (
                              <div key={sector.id} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`industry-${sector.id}`}
                                  checked={selectedsector.includes(sector.sector)}
                                  onChange={() => handleCheckboxSector(sector.sector)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`industry-${sector.id}`}
                                  style={{ color: theme ? "#fff" : "#333" }}
                                >
                                  {sector.sector}
                                </label>
                              </div>
                            ))}
                          </div>
                        )
                      },
                      {
                        id: "3",
                        title: "Industry",
                        content: (
                          <div className="p-2"
                            style={{
                              maxHeight: '200px', // Adjust the height as needed
                              overflowY: 'auto',  // Enable vertical scrolling
                            }}>
                            {industry && industry.map((industry) => (
                              <div key={industry.id} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`industry-${industry.id}`}
                                  checked={selectedIndustries.includes(industry.industry_group)}
                                  onChange={() => handleCheckboxChange(industry.industry_group)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`industry-${industry.id}`}
                                  style={{ color: theme ? "#fff" : "#333" }}
                                >
                                  {industry.industry_group}
                                </label>
                              </div>
                            ))}
                          </div>
                        )
                      },

                    ].map((item) => (
                      <div
                        className="accordion-item"
                        key={item.id}
                        style={{
                          backgroundColor: theme ? "#1B1B1B" : "#fff",
                          borderColor: theme ? "#333" : "#ddd",
                          borderRadius: "10px",
                          marginBottom: "1rem",
                          boxShadow: theme
                            ? "0 2px 4px rgba(255, 255, 255, 0.1)"
                            : "0 2px 4px rgba(0, 0, 0, 0.1)",
                          overflow: "hidden",
                          transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        }}
                      >
                        <h2 className="accordion-header" id={`heading${item.id}`}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${item.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse${item.id}`}
                            style={{
                              backgroundColor: theme ? "#2C2C2C" : "#f9f9f9",
                              color: theme ? "#fff" : "#333",
                              borderColor: "transparent",
                              padding: "1rem 1.5rem",
                              transition: "all 0.3s ease",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            {item.title}
                          </button>
                        </h2>
                        <div
                          id={`collapse${item.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading${item.id}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            style={{
                              backgroundColor: theme ? "#1B1B1B" : "#fff",
                              color: theme ? "#fff" : "#333",
                              padding: "1rem 1.5rem",
                              transition: "background-color 0.3s ease",
                              borderTop: theme ? "1px solid #333" : "1px solid #ddd",
                              fontSize: "0.95rem",
                            }}
                          >
                            {item.content}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className="modal-footer"
                    style={{
                      borderTop: "none",
                      padding: "1rem 2rem",
                      backgroundColor: theme ? '#2c2c2c' : '#fff',
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        borderRadius: "8px",
                        background: "linear-gradient(90deg, rgba(126,102,188,1) 0%, rgba(154,123,187,1) 100%)",
                        border: "none",
                        padding: "0.75rem 2rem",
                        fontWeight: "bold",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)"
                      }}
                      onClick={() => { Filterdata() }}
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default Screener;
