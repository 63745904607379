import React, { useContext,useState } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './footer.css';
import { UserContext } from '../../components/Utills/UserContext';
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const { theme } = useContext(UserContext); // Get the theme from context
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const navigate = useNavigate();
  // Define colors for dark and light modes
  const textColor = theme ? '#fff' : '#000';
  const iconColor = theme ? '#fff' : '#000'; // White for dark mode, black for light mode
  const hoverColor = '#ffcb05';

  return (
    <div className="footer-container mt-3"
     style={{
      // backgroundImage: theme ? `url("images/darkbackground2.png")`: `url("images/lightbackground.png")`, // Add the background image URL
      // backgroundSize: "cover", // Ensure the image covers the navbar area
      // backgroundPosition: "center", // Center the background image
      backgroundColor: theme ? "#171717" : "#fff", // Conditional background color
      boxShadow: "0px 0px 4px 0px #00000040", // Box shadow for a subtle effect
      // position:'fixed',
      // width:'100%',
      // bottom:'0'
    }}
    >
 <div className="d-flex justify-content-between flex-wrap">
        
        {/* Website Name Section */}
        <div className="footer-right ms-3 ">
          <div className='mb-3'>
          <a href="/dashboard" >
          <img src={"logonew.png"} className='me-2' width={35} height={35} alt="Logo" />
            <img src={theme ? "images/app/itg-logo1.png" : "images/app/itg-logo-light1.png"} className='app-logo' alt="Logo" />
          </a>
          </div>
          <p className="footer-subtitle ps-1" style={{ color: textColor,fontWeight:'400',fontSize:'14px' }}>
            Empowering Ethical Trade Worldwide
          </p>
    
          <p className={`footer-links-title text-start ${theme?"ms-4":''}`} style={{ color:"#7E66BC" ,fontWeight:'500' }}>     Address </p>
          <ul className={`${theme ? "footer-links-dark":'footer-links'}`}>
            <li className='mb-2'  style={{ color: textColor,fontWeight:'400',fontSize:'14px' }}>
            Shop No.2, Pearl Oasis Apartment,
</li>
<li className='mb-2' style={{ color: textColor,fontWeight:'400',fontSize:'14px'  }}>   
Pathanwadi, Malad East,
</li>
<li  style={{ color: textColor,fontWeight:'400',fontSize:'14px'  }}>
Mumbai - 400097
</li>
           
          </ul>
    
          {/* <p className="footer-subtitle ps-1" style={{ color: textColor,fontWeight:'400',fontSize:'14px'  }}>
          Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit 
          </p> */}
          
 
        </div>

        {/* Quick Links Section */}
       

        <div className="footer-right text-start" style={{marginTop:isMobile?"3%":""}}>
          
          <p className="footer-links-title text-center">Quick Links</p>
          <div className={`${theme ? "footer-links-dark":'footer-links'}  text-center`} >
            <div className='mb-3 '><a href="" onClick={()=>{navigate('/dashboard')}} style={{ color: textColor,fontWeight:'400' }}>Home</a></div>
            <div  className='mb-3'><a href="" onClick={()=>{navigate('/screener')}} style={{ color: textColor,fontWeight:'400' }}>Screener</a></div>
            <div  className='mb-3'><a href="" onClick={()=>{navigate('/watchlist')}} style={{ color: textColor,fontWeight:'400' }}>Watchlist</a></div>
            <div  className='mb-3'><a href="" onClick={()=>{navigate('/premium')}} style={{ color: textColor,fontWeight:'400' }}>Premium</a></div>
          </div>
        </div>

        <div className="footer-right text-start ">
        <p className="footer-links-title text-start">Follow Us</p>
        <div className={`${theme ? "social-icons-dark" : "social-icons"} mb-4  mt-0`}>
          <a href="https://www.facebook.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/facebook.png' className=''  width={30} height={30} />
            </a>
            <a href="https://www.instagram.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/insta.png' className=''   width={35} height={35} />
            </a>
            <a href="https://x.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/twitter.png'  className=''  width={30} height={30} />
            </a>
            <a href="https://www.youtube.com/islamictijarat" target="_blank" rel="noopener noreferrer">

            <img src='images/youtube.png' className=''   width={30} height={30} />
          </a>
          <a href="https://www.linkedin.com/in/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/linkedin.png'  width={30} height={30} />
            </a>

          </div>
        </div>
        <div className="footer-right text-start ">
        <p className="footer-links-title text-start">Downloads</p>
        <div className={`p-4 d-flex align-items-center ${!theme ? "newsemail-dark" : "newsemail"}`}>
  <i className="fab fa-google-play me-2" style={{ fontSize: "24px", color: theme ? "#fff" : "#000" }}></i>
  <span style={{ color: theme ? "#fff" : "#000", fontWeight: "600", fontSize:isMobile?"12px":"14px" }}>
    Google Play
  </span>
</div>
<div className={`p-4 d-flex align-items-center mt-2 ${!theme ? "newsemail-dark" : "newsemail"}`}>
  <i className="fab fa-apple me-2" style={{ fontSize: "24px", color: theme ? "#fff" : "#000" }}></i>
  <span style={{ color: theme ? "#fff" : "#000", fontWeight: "600", fontSize:isMobile? "12px":"14px" }}>
    App Store
  </span>
</div>

        </div>
      </div>

      {/* Copyright Section */}
      <div className="text-center pb-1 pt-1" style={{backgroundColor:theme ? "rgb(61 61 61)":'#ced4da'}}>
        <p className={`${theme ?'footer-copyright-dark':'footer-copyright'}`} style={{ color: textColor,fontWeight:'600' }} >
          © 2024 Islamic Tijarat. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
