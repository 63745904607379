import React, { useEffect, useState, useContext } from 'react';
import FetchData from '../fetchapi/Apifetch';
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import { useNavigate,useLocation } from 'react-router-dom';
import { UserContext } from '../../components/Utills/UserContext';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS
import "./screenerstock.css"

const Similarstock = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme, setTheme } = useContext(UserContext);
  const [activeButton, setActiveButton] = useState(0);
  const [Stockscreener, setStockscreener] = useState('');
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [industry, setindustry] = useState('');
  const [sectorvalue, setsectorvalue] = useState('');

  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const [selectedsector, setSelectedsector] = useState([]);
  const [selectedexchange, setSelectedexchange] = useState([]);

  const handleRowClick = (item) => {
    navigate('/stock', { state: { value: item } });
  };


  useEffect(() => {
    console.log(location?.state?.value)
    setStockscreener(location?.state?.value);
  }, [location?.state?.value]);








   
  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.screenerData.stock;

    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase();
    };

    return (
      <div>
        {!imageError ? (
          <img
            className=""
            src={item?.stockData?.img || ""}
            alt="img"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              marginTop:'-5px'
            }}
            onError={() => setImageError(true)}
          />
        ) : (
          <div
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f0f0f0",
              color: theme ? 'black' : 'black',
              fontSize: "16px",
              fontWeight: 500,
              marginTop:'-5px'
            }}
          >
            {splitStock(item?.screenerData?.stock)}
          </div>
        )}
      </div>
    );
  };
// const [selectedexchange, setSelectedExchange] = useState([]);
// const [selectedsector, setSelectedSector] = useState([]);
// const [selectedIndustries, setSelectedIndustries] = useState([]);


  return (
    <>
      <Navbar />
      <div className='container-fluid' style={{ paddingTop:isMobile?"16%" :"9%", width: '90%' }}>
        <div className='row justify-content-center'>
        <div className='card col-lg-12 col-md-12 col-sm-12' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
      <div className='mt-2 p-3'>
      <div className='' style={{ padding: '1rem', borderRadius: '10px' }}>
    <div>
        <h5 className={`fs-4 ms-3 fw-bold ${theme ? 'text-white' : 'text-dark'}`}>Similar <span style={{color:'rgb(126, 102, 188)'}}>Compliant Stock</span></h5>
    </div>
  </div>
        <div className="table-responsive"> {/* Make table responsive */}
        <table className="table mt-2 table-responsive" style={{ border: theme ? "0.5px solid #222222" : "0.5px solid rgba(0, 0, 0, 0.175)", textAlign: "start", backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
          <thead>
            <tr>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">  Name</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Sector</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Industry</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Exchange</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Compliance</th>

              {/* <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">BSE/NSE</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">52 Weeks High</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">52 Weeks Low</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">Average Volume 10 days</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '12px' }} scope="col">Beta 1 year</th> */}
            </tr>
          </thead>
          <tbody>
            
             {Array.isArray(Stockscreener) && Stockscreener.map((item, index) => (
                <tr className='mx-auto' key={index}>
                  <th scope="row"  style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black' }}>
                    <div className='d-flex justify-content-start ms-2' onClick={() => (handleRowClick(item))}>
                      <Imageview item={item} />
                      <div className=''>
                    
                        <p  className='mb-0'  style={{ padding: "0px 10px", fontSize: "14px",fontWeight:'500'}}>{item?.stockData?.stock_code ?item?.stockData?.stock_code:item?.screenerData.stock }</p>
                        <h3 className='mb-0' style={{ padding: "0px 10px", fontSize: "12px",color:'gray'  }}>{item?.screenerData.stock}</h3>

                      </div>
                    </div>
                  </th>
                  <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                    {item?.TechinicalData?.sector                    }
                  </td>
                  <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                    {item?.screenerData?.industry_group}
                  </td>
                  <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                    {item?.stockData?.exchange                    }
                  </td>
                  {item?.screenerData?.status == 1 ? (
                <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff'}}>
                  <div className="d-flex p-1 justify-content-center" >
                    <span className="heder-title" style={{color:'#218303',fontWeight:"500"}}>Compliant</span>
                    <span className="ms-1"><img src="/images/checklist.png" width={20} height={20} ></img></span>
                  </div>
                </td>
              ) : item?.screenerData?.status == 2  || item?.screenerData?.status == 4  || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ?   (
                <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                  <div className="d-flex p-1 justify-content-center">
                    <span className="heder-title" style={{color:'#D43A3A',fontWeight:"500"}}>Non Compliant</span>
                    <span className="ms-1"><img src="/images/non-permisable.png" width={20} height={20} ></img></span>
                  </div>
                </td>
              ) :item?.screenerData?.status == 3 ? (
                <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                  <div className="d-flex p-1 justify-content-center" >
                    <span className="heder-title" style={{color:'#2132CA',fontWeight:"500"}}>Unknown</span>
                    <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                  </div>
                </td>
              ):
             ( <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                  <div className="d-flex p-1 justify-content-center">
                    <span className="heder-title" style={{color:'#EFA625',fontWeight:"500"}}>Under Review</span>
                    <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                  </div>
                </td> 
             )
              }

                </tr>
              ))
            }
          </tbody>
        </table>
        </div> {/* End of table-responsive */}
      </div>
    </div>
    
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Similarstock;
