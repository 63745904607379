import React, { useEffect, useState, useContext } from 'react';
import FetchData from '../fetchapi/Apifetch';
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import { json, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from '../../components/Utills/UserContext';
import Skeleton from 'react-loading-skeleton'; 
import 'react-loading-skeleton/dist/skeleton.css'; 
import "./screenerstock.css"

const Industrybasestock = () => {
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(UserContext);
  const [activeButton, setActiveButton] = useState(0);
  const [Stockscreener, setStockscreener] = useState('');
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [data, setData] = useState();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    setData(location?.state?.value);

    console.log('value',location?.state?.value);
  }, [location?.state?.value]);


  useEffect(() => {
    const encodedQuery = encodeURIComponent(data?.industry_group);
    const fetchData1 = async () => {
      const res = await FetchData(
        `industrybasestock?q=${encodedQuery}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        console.log('res value', res.data)
        setStockscreener(res.data.data);
        setLoading(false);
      }
    };
    if (data?.industry_group) {
      fetchData1();
    }
  }, [data]);


  const handleRowClick = (item) => {
    navigate('/stock', { state: { value: item } });
  };

  const NextPage = () => {
    setPage(page + 1);
  };

  const PreviousPage = () => {
    setPage(page - 1);
  };

//   useEffect(() => {
//     Stockscreenerlist();
//   }, [page]);




    


// const Stockscreenerlist = async () => {
//     setLoading(true); // Set loading to true before fetching
//     const res = await FetchData(`stocklist?page=${page}&limit=12`, 'GET', null, true, false);
//     if (res) {
//       console.log(res.data);
//       setStockscreener(res.data.data);
//       setPaginationInfo(res.data.pagination);
//     }
//     setLoading(false); // Set loading to false after fetching
//   };
   
  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.screenerData.stock;

    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase();
    };

    return (
      <div>
        {!imageError ? (
          <img
            className=""
            src={item?.stockData?.img || ""}
            alt="img"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              marginTop:'-5px'
            }}
            onError={() => setImageError(true)}
          />
        ) : (
          <div
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f0f0f0",
              color: theme ? 'black' : 'black',
              fontSize: "16px",
              fontWeight: 500,
                      marginTop:'-5px'
            }}
          >
            {splitStock(item?.screenerData?.stock)}
          </div>
        )}
      </div>
    );
  };

  const PaginationIndex = () => {
    return (
      <div className="mx-auto my-4 text-white">
        <nav aria-label="Page navigation example">
          <ul className="pagination" style={{ marginLeft: "10px" }}>
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={PreviousPage}>
                Previous
              </button>
            </li>
            {paginationNumbers().map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${page === pageNumber ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => setPage(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                page === paginationInfo?.totalPages ? "disabled" : ""
              }`}
            >
              <button className="page-link" onClick={NextPage}>
                Next
              </button>
            </li>
          </ul>
        </nav>
        <div className="pagination my-2" style={{ marginLeft: "17px" }}>
          <p>Total Records: {paginationInfo?.totalItems}</p>
          <br />
          <p style={{ paddingLeft: "10px" }}>
            Total Pages: {paginationInfo?.totalPages}
          </p>
        </div>
      </div>
    );
  };

  const paginationNumbers = () => {
    const totalPages = paginationInfo?.totalPages || 0;
    const currentPage = page;
    const paginationRange = 10;
    const paginationStart = Math.max(
      1,
      currentPage - Math.floor(paginationRange / 2)
    );
    const paginationEnd = Math.min(
      totalPages,
      paginationStart + paginationRange - 1
    );
    const numbersToShow = [];
    for (let i = paginationStart; i <= paginationEnd; i++) {
      numbersToShow.push(i);
    }
    return numbersToShow;
  };

  return (
    <>
      <Navbar />
      <div className='container-fluid padding-header' style={{width: '95%' }}>
        <div className='p-1'>
      <button className='btn btn-sm' onClick={()=>{navigate("/dashboard")}} style={{backgroundColor:'rgb(126, 102, 188)',color:"#fff"}}>
  <i className="fa fa-arrow-left me-2"></i>
  Back
</button>
</div>

        <div className='row justify-content-center mb-5'>

            <div className='card mb-2 p-1 text-center' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black' }}>
            <h4>
       {data?.industry_group}
  </h4>
            </div>
        <div className='card col-lg-12 col-md-12 col-sm-12' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
      <div className='mt-2 p-3'>
       

 
        <div className="table-responsive"> {/* Make table responsive */}
        <table className="table mt-2 table-responsive" style={{ border: theme ? "0.5px solid #222222" : "0.5px solid rgba(0, 0, 0, 0.175)", textAlign: "start", backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
          <thead>
            <tr>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Name</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Sector</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Industry</th>
              <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Exchange</th>
              <th className='text-center' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Compliance</th>

             
            </tr>
          </thead>
          <tbody>
            {loading ? (
              // Render Skeleton Loader for full row width
              Array.from({ length: 10 }).map((_, index) => (
                <tr key={index}>
                  <td colSpan={8} style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "12px", color: theme ? '#fff' : 'black' }}>
                    <Skeleton height={40} style={{ width: '100%' }} />
                  </td>
                </tr>
              ))
            ) : (
              Array.isArray(Stockscreener) && Stockscreener.map((item, index) => (
                <tr className='mx-auto' key={index} style={{cursor:'pointer'}}>
                  <th scope="row" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black' }}>
                    <div className='d-flex justify-content-start' onClick={() => (handleRowClick(item))}>
                      <Imageview item={item} />
                      <div className=''>
                        <h3 className='mb-0' style={{ padding: "0px 10px", fontSize: "16px" }}>{item?.screenerData.stock}</h3>
                        <p style={{ padding: "0px 10px", fontSize: "12px",color:'gray' }}>{item?.stockData?.stock_code}</p>
                      </div>
                    </div>
                  </th>
                  <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                    {item?.TechinicalData?.sector}
                  </td>
                  <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                    {item?.screenerData?.industry_group}
                  </td>
                  <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                    {item?.stockData?.exchange}
                  </td>
                  {item?.screenerData?.status == 1 ? (
                <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff'}}>
                  <div className="d-flex p-1 justify-content-center" >
                    <span className="heder-title" style={{color:'#218303',fontWeight:"500"}}>Compliant</span>
                    <span className="ms-1"><img src="/images/checklist.png" width={20} height={20} ></img></span>
                  </div>
                </td>
              ) : item?.screenerData?.status == 2  || item?.screenerData?.status == 4  || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ?   (
                <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                  <div className="d-flex p-1 justify-content-center">
                    <span className="heder-title" style={{color:'#D43A3A',fontWeight:"500"}}>Non Compliant</span>
                    <span className="ms-1"><img src="/images/non-permisable.png" width={20} height={20} ></img></span>
                  </div>
                </td>
              ) :item?.screenerData?.status == 3 ? (
                <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                  <div className="d-flex p-1 justify-content-center" >
                    <span className="heder-title" style={{color:'#2132CA',fontWeight:"500"}}>Unknown</span>
                    <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                  </div>
                </td>
              ):
             ( <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                  <div className="d-flex p-1 justify-content-center">
                    <span className="heder-title" style={{color:'#EFA625',fontWeight:"500"}}>Under Review</span>
                    <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                  </div>
                </td> 
             )
              }
                </tr>
              ))
            )}
          </tbody>
        </table>
        </div> {/* End of table-responsive */}
        {/* <PaginationIndex /> */}
      </div>
    </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Industrybasestock;
