import React,{useState,useContext} from 'react'
import "./style.css"
import { UserContext } from '../../../Utills/UserContext';
const Purifylight = () => {
    const [formdata, setFormdata] = useState({
        company: "",
        sharesowne: "",
        purchase: "",
        Sell: '',
        calculate: "",
    })
    
    const { theme, setTheme } = useContext(UserContext);
    
      const inputField = (title, col = 6, name, type, value,onChange) => {
        return (
            <div className={`outlined-input1 col-lg-${col} col-md-${col} col-sm-12 my-2`}>
                <input
                    className="w-100"
                    type={type}
                    name={name}
                    value={value}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      var capitalizedValue = inputValue
                          .toLowerCase()
                          .split(' ')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ');
                      onChange({ target: { name: e.target.name, value: capitalizedValue } });
                  }}
                    placeholder=" "
                />
                <label>{title}</label>
            </div>
        );
    };
    
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      // setErrors({ ...errors, [name]: error });
      setFormdata({ ...formdata, [name]: value });
    };
    
    const SelectOption = (title, col = 12, name, type, value) => {
      var options = []
      if (name == "calculate") {
          options = [
              "calculate",
          ]
      }
    
      return (
          <div className={`outlined-input1 col-lg-${col} col-md-${col} col-sm-12 my-2`}>
              <select
                  name="test"
                  // value={valuedata}
                  onChange={(e) => {
                      const newValue = e.target.value.slice(0, 6); 
                  }}
              >
                  {options.map(item => (
    
                      <option value={item}>{item}</option>
                  ))}
              </select>
              <label htmlFor="test">{title}</label>
          </div>
      )
    }
    
      return (
        <div>
        {/* <Navbarwhite /> */}
    
    <div
      className=" home-header"
      style={{ width: "90%", margin:'auto' }}
    >
      <div className="row" style={{marginTop:'7%'}}>
        <div className="col-lg-6 col-md-6 mt-2" style={{backgroundColor:theme?"":"#fff"}}>
          <div
            className="card p-4 pt-2 "
            style={{ alignItems: "center" ,width:'95%',borderRadius:'10px'}}
          >
            <div>
              <div className="mt-2 mb-3">
         <span className='fs-4'><span style={{color:'#7E66BC'}}>Purification</span> Calculator</span>
              </div>
    
              <p className=""  style={{fontWeight:'400',lineHeight:'25px' ,fontSize:'16px',fontFamily:'Poppins, sans-serif'}}>
              Earning from Halal Stocks also need to be purified based on their business and financial earnings. Calculate the amount using our Purification calculator
              </p>
              {/* <button
                className="btn text-white btn-sm"
                style={{ backgroundColor: "#009000",borderRadius:'20px' ,fontFamily:'Poppins, sans-serif'}}
              >
            Learn more
              </button> */}
            </div>
            <div className='row w-100 mt-3 p-0'>
    
    
                <div className='col-md-12 col-lg-12 outlined-input'  style={{fontFamily:'Poppins, sans-serif',fontSize:'14px'}}>
                    {/* <label className='text-white'>company (Ticker or Name)</label>
                    <input type="text" className="form-control pt-2 pb-2" name="Enter Company Name or Ticker" /> */}
                            {inputField("company (Ticker or Name)", 12, "company", "text", formdata.company,handleInputChange)}
                </div>
                <div className='col-md-12 col-lg-12 mt-4'  style={{fontFamily:'Poppins, sans-serif',fontSize:'14px'}}>
                {inputField("Shares Owned", 12, "sharesowne", "text", formdata.sharesowne ,handleInputChange)}
          
                </div>  
                <div className='col-md-12 col-lg-12 d-flex justify-content-between mt-4'>
                <div className='col-md-6 col-lg-6 ' style={{width:'48%',fontFamily:'Poppins, sans-serif',fontSize:'14px'}}>
                    {inputField("Purchase Date", 12, "purchase", "date", formdata.purchase ,handleInputChange)}
                    </div>
                    <div className='col-md-6 col-lg-6 text-white'  style={{width:'48%',fontFamily:'Poppins, sans-serif',fontSize:'14px'}}>
                    {inputField("Sell Date", 12, "sell", "date", formdata.sell ,handleInputChange)}
    
                    </div>
                </div>
            </div>
            <div className='w-100 mt-5  mb-5 text-center'>
                   
                    <button className='btn w-50 text-white pt-2 pb-2'  style={{ backgroundColor: "#7E66BC"  }}>Calculator</button>
                </div>
    
           
            
          </div>
        </div>
        <div className="col-lg-6 col-md-6 herocard mt-2">
        <div className='card p-4 '
          style={{ alignItems: "center" ,borderRadius:'10px'}}>
            <div className="card-body text-center mt-5 pb-5 pt-5" style={{border:'1px solid #D1D1D1',paddingLeft:'20%',paddingRight:'20%',borderRadius:'10px'}} >
                <div className='p-1'>
                <div className='text-dark' style={{fontSize:'20px'}}>Total Purification Amount</div>
                <div className='mt-5'>
                    - -
                </div>
    
    
                
                </div>
                
            </div>
    
            <div className='col-md-12 col-lg-12 mt-5 mb-5'  style={{width:'90%',fontSize:'14px'}}>
                    {SelectOption("Calculation", 12, "calculate", "text", formdata.calculate ,handleInputChange)}
                </div>
    
        </div>
        
      
        </div>  
      </div>
    </div>
    
    {/* <Footer /> */}
        </div>
      )
    }

export default Purifylight