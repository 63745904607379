import React,{useState , useContext} from  'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../footer/Footer'
import "./setting.css"
import { UserContext } from '../../components/Utills/UserContext';



const Setting = () => {
  const profiledetails = JSON.parse(localStorage.getItem("email"));
  const [pages, setpages] = useState("Setting");

  const { theme, setTheme } = useContext(UserContext);


const Handlethemeset = (e) =>{
  setTheme(!theme)
}
  
  return (

    <>
    <Navbar  />
    <div className='d-flex justify-content-center' style={{paddingTop:'6%'}}>
    <div className={`card  d-flex ${theme ?'text-white':'text-black '}`} style={{width:'95%',borderTopRightRadius:"10px",borderTopLeftRadius:'10px',backgroundColor:theme?'#1B1B1B':'#fff'}}>
        <div className='d-flex p-5'>
            <div><img src='/images/85709 1.png' className='' width={135} height={130}  alt="profile" /></div>
            <div className='ms-3'>
                <h6 className='fw-bold'>{profiledetails.first_name} <span className='ms-2'>{profiledetails.last_name}</span></h6>
                <p><span className='text-success'><i className="fa fa-clock text-secondary me-1" />online</span><span className='ms-4'><i className="fa fa-calendar text-secondary me-1" />Joined an hour ago</span></p>
                <p><span className='fw-bold'>100 Followers</span><span className='fw-bold ms-5'>500 Following</span> </p>
            </div>
        </div>
<div style={{marginTop:'1%'}}>
    <div className='d-flex justify-content-around p-2' style={{borderTop:'1px solid #484848',borderBottom:'1px solid #484848'}}>
        <div onClick={()=>{setpages('Ideas')}} style={{borderBottom: pages == 'Ideas'?'2px solid green':''}}>Ideas </div>
        <div onClick={()=>{setpages('Followers')}} style={{borderBottom: pages == 'Followers'?'2px solid green':''}}>Followers </div>
        <div onClick={()=>{setpages('Following')}} style={{borderBottom: pages == 'Following'?'2px solid green':''}}>Following</div>
        <div className='' onClick={()=>{setpages('Setting')}} style={{borderBottom: pages == 'Setting'?'2px solid green':''}}>Setting</div>
    </div>
    {pages == 'Setting' &&
<div className='row'>


<div  className='col-9 mt-3'>
<div>
<div className='ms-3' style={{borderBottom:'1px solid #484848'}}>
  <p className='' style={{marginLeft:'15%'}}>Profile Details</p>
</div>
<div className='ms-3 d-flex justify-content-around text-start mt-2' style={{borderBottom:'1px solid #484848'}}>
  <p className='text-start'>First Name</p>
  <p>{profiledetails.first_name}</p>
</div>
<div className='ms-3 d-flex justify-content-around text-start  mt-2' style={{borderBottom:'1px solid #484848'}}>
  <p>Last Name</p>
  <p>{profiledetails.last_name}</p>
</div>
<div className='ms-3 d-flex justify-content-around text-start  mt-2' style={{borderBottom:'1px solid #484848'}}>
  <p>Email Address</p>
  <p>{profiledetails.email}</p>
</div>
<div className='ms-3 d-flex justify-content-around text-start  mt-2' style={{borderBottom:'1px solid #484848'}}>
  <p>Phone Number</p>
  <p>{profiledetails.phone}</p>
</div>
{/* <div className='ms-3 d-flex justify-content-around text-start  mt-2' style={{borderBottom:'1px solid #484848'}}>
  <p>Gender</p>
  <p>male</p>
</div> */}
{/* <div className='ms-3 d-flex justify-content-around text-start  mt-2' style={{borderBottom:'1px solid #484848'}}>
  <p>Age Group</p>
  <p>28</p>
</div> */}
</div>
</div>
<div className='col-3' >
    <div className='m-3'>
        <div className='card d-flex justify-content-center text-center'style={{borderRadius:'10PX',backgroundColor:theme?"#1B1B1B":'#fff',color:theme?'#fff':'black'}} >
            <div className='text-center' >
      
                <p className='text-white p-2' style={{backgroundColor:'#7E66BC',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}><span>      <i className="fa fa-user text-white me-5" /></span>Profile Details</p>

            </div>
            <div  className='text-start ms-4'>
                <p><span>  <i className="fa fa-unlock-alt me-3" /></span>Reset Password</p>
                <p><span>  <i className="fa fa-star me-3 text-warning" /></span>Premium Membership</p>
                <p><span>  <i className="fa fa-file-text me-3" /></span>Refer a friend</p>
            </div>
            <div className='p-1 text-start ms-4' style={{borderTop:'1px solid #484848',borderBottom:'1px solid #484848'}}>
            {/* <p>
  <span>
    <i className="fa fa-adjust me-3 text-white"></i>
  </span>
  Theme
  <span>
    <button className="btn theme-btn me-3">Dark</button>
    <button className="btn theme-btn-light">Light</button>
  </span>
</p> */}
              
              <div class="dongle-container mb-3 mt-3">
    <span class={`dongle-label ${theme ?'text-white':'text-black'}`}>  <span>
    <i className={`fa fa-adjust me-3 ${theme?"text-white":'text-black'} `}></i>
  </span>Theme</span>
    <div class="dongle-toggle">
        <input type="checkbox" id="themeToggle" value={theme}  onChange={(e)=>{Handlethemeset(e)}} />
        <label for="themeToggle" class="dongle-slider"></label>
    </div>
</div>
  <p> <span>  <i className={`fa fa-question-circle me-3 ${theme?'text-white':'text-black'}`} /></span>Help Center</p>
    <p> <span>  <i className={`fa fa-bell me-3 ${theme ?'text-white':'text-black'}`} /></span>Notification</p>
            </div>
        <div className='mt-2 text-start ms-4'>
        <p className='text-danger'> <span>  <i className="fa fa-power-off me-3 text-danger" /></span>logout</p>
        </div>

        </div>
    </div>

</div>
</div>
}
</div>

    </div>
</div>
{/* <Footer /> */}

    </>
  )
}

export default Setting
