import React ,{useState ,useEffect,useContext } from 'react';
import "./css/login.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import FetchData from "../fetchapi/Apifetch";
import { useNavigate,useLocation } from "react-router-dom";
import { UserContext } from '../../components/Utills/UserContext';
import { signInWithEmailAndPassword,signInWithPopup } from 'firebase/auth';
import { auth ,googleAuthProvider} from "../../firebase";
import OtpInput from 'react-otp-input';
import OTPInput from './Otpinput';
import DisclaimerModal from '../pages/DisclaimerModal';


const Otpverification = () => {
  const location = useLocation();
  const [Showmodel, setShowmodel] = useState(false);
  const [userid, setUserid] = useState('');
        const { theme, setTheme } = useContext(UserContext);
      
        const [buttonclick, setbuttonclick] = useState(false);
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const navigate = useNavigate();
        const { user, setUser } = useContext(UserContext);
        const [formdata1, setFormdata1] = useState({
           email:location?.state?.data,  
           otp:""
        });
      
        const CloseModel = async () => {

          try {
            const token = false;
            const data = {
              userstatus: true,
            };
            const res = await FetchData(`userstatus/${userid}`, 'POST', JSON.stringify(data), token, false);
            if (res.data) {
                localStorage.setItem("login",true);
                setUser(true)
                console.log('res.data',res.data) ;
                const jsonLoginData = JSON.stringify(res.data);
                localStorage.setItem("email",jsonLoginData);
                toast.success("Login successfully"); 
                // navigate("/dashboard");
                // window.location.href = "/dashboard" 
                setShowmodel(false)
            }
        } catch (error) {
            await toast.error(error.message);
            setbuttonclick(false);
          }
          
        }



        const handleOtpChange = (otp1) => {
    
          // Update formdata1 with the new OTP value
          setFormdata1((prevFormdata) => ({
            ...prevFormdata, // Spread the previous form data to retain other fields
            otp: otp1,
            email:location?.state?.data // Set the otp field to the new OTP value
          }));
        };

        const LoginCheck = async () => {
          try {
              setbuttonclick(true)
        
              const token = false;
      
              const res = await FetchData("otpverification", 'POST', JSON.stringify(formdata1), token, false);
              if (res.data) {
                 await toast.success("Otp verified Sucessfully");
                 if(!res?.data?.userstatus){
                  setShowmodel(true);
                  console.log('res',res.data);
                  setUserid(res?.data?._id)
                 }else{
                    localStorage.setItem("login",true);
                    setUser(true)
                    console.log('res.data',res.data) ;
                    const jsonLoginData = JSON.stringify(res.data);
                    localStorage.setItem("email",jsonLoginData);
                    toast.success("Login successfully");
                 }

                  // window.location.href = "/dashboard" 
              }
          } catch (error) {
              toast.error(error.message);
              setbuttonclick(false)
          }
      }
      

      
      
      
        return (
          <>
          <div className={`container-fluid mt-5 ${theme?'text-white':'text-black'}`} style={{width:"80%",border:"1px solid #ededed"}}>
          <ToastContainer />
          <DisclaimerModal showModal={Showmodel} handleClose={CloseModel}  />
              <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12  d-flex align-items-center bg-white'>
              <div className='p-4 w-100'>
              <h3 className='mb-1 ' style={{marginTop:"-10%"}}>OTP Verification</h3>
              <p className='mb-4'>Please enter the One-Time Password to verify your account</p>
            <form>
        <div class="mb-3 mt-3 ">
          <label for="exampleInputEmail1" class="form-label">Enter OTP</label>
          <OTPInput length={6} onChange={handleOtpChange} />
        </div>
        
        <div className='mt-5'>
        <button type="submit" class="btn btn-primary w-100 mt-5" disabled={buttonclick} style={{backgroundColor:"#208203"}} onClick={()=>{LoginCheck()}}>Verify</button>
        <p className='text-center my-2'>Or login with</p>
        </div>
      </form>
          </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 p-0 img-layout' >
        <img src="images/loginpage.png" alt='logo' style={{width:"100%",height:"100%"}} /> 
          <div className='text-overlay'>
          <img src="logonew.png" alt='logo' className='mt-1' style={{width:"130px",height:"130px"}} /> 
             <img src={"images/app/itg-logo1.png"}  alt="Logo" style={{width:"280px",height:"60px"}} />

          <p className='paratext px-4'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
incididunt ut labore et dolore magna aliqua. Ut enim  veniam, quis nostrud 
exercitation  nisi ut aliquip ex ea commodo consequat. </p>
          <div className='mt-3'>
          <div className={`${theme ? "social-icons-dark" : "social-icons"} mb-4  mt-0`}>
          <a href="https://www.facebook.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/facebook.png' className='p-2 ' style={{backgroundColor:"white",borderRadius:'50%'}}  width={40} height={40} />
            </a>
            <a href="https://www.instagram.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/insta.png' className=' p-2 ' style={{backgroundColor:"white",borderRadius:'50%'}}    width={40} height={40} />
            </a>
            <a href="https://x.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/twitter.png'  className=' p-2 '  style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
            <a href="https://www.youtube.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/youtube.png' className='  p-2 '  style={{backgroundColor:"white",borderRadius:'50%'}}   width={35} height={35} />
            </a>
            <a href="https://www.linkedin.com/in/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/linkedin.png'   className='p-2' style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
          </div>
          </div>
          </div>
        </div>
              </div>
          </div>
      
      
          
          </>
        )
      
}

export default Otpverification