import React, { useEffect, useRef } from 'react';

const Advancegraph = ({ symbol ,themes }) => {
    const containerRef = useRef(null);


    useEffect(() => {
        if (!containerRef.current) return;

        // Clear the container before appending the script
        containerRef.current.innerHTML = '';

        const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
            "autosize": true,
            "symbol":symbol,
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme":themes? "dark":'light',
            "style": "1",
            "locale": "en",
            "backgroundColor":themes? "#1B1B1B":"#fff",
            "withdateranges": true,
            "hide_side_toolbar": false,
            "allow_symbol_change": true,
            "details": true,
            "hotlist": true,
            "calendar": false,
            "show_popup_button": true,
            "popup_width": "1000",
            "popup_height": "650",
            "support_host": "https://www.tradingview.com"
        });

        containerRef.current.appendChild(script);

        // Cleanup function to remove the script on unmount
        return () => {
            if (containerRef.current) {
                containerRef.current.innerHTML = '';
            }
        };
    }, [symbol]);

    return (
        <div className="tradingview-widget-container" ref={containerRef} style={{ height: "500px", width: "100%" }}>
            <div className="tradingview-widget-container__widget" style={{ height: "500px", width: "100%" }}></div>
            <div className="tradingview-widget-copyright">
                <a href="https://www.tradingview.com/" rel="noopener noreferrer" target="_blank">
                    <span className="blue-text">Track all markets on TradingView</span>
                </a>
            </div>
        </div>
    );
};

export default Advancegraph;
