import React from 'react'
import "./profilr.css"
import Footer from '../../../footer/Footer'

const Profilelight = () => {
    return (
        <>
        {/* <Navbarwhite  /> */}
        <div className='d-flex justify-content-center' style={{marginTop:'6%'}}>
        <div className='card  d-flex cardsize'>
            <div className='d-flex p-5'>
                <div><img src='/images/85709 1.png' className='' width={135} height={130}  alt="profile" /></div>
                <div className='ms-3'>
                    <h6 className='fw-bold'>Muazzim Hussain</h6>
                    <p><span className='text-success'><i className="fa fa-clock text-secondary me-1" />online</span><span className='ms-4'><i className="fa fa-calendar text-secondary me-1" />Joined an hour ago</span></p>
                    <p><span className='fw-bold'>100 Followers</span><span className='fw-bold ms-5'>500 Following</span> </p>
                </div>
            </div>
    <div style={{marginTop:'1%'}}>
        <div className='d-flex justify-content-around p-2' style={{borderTop:'1px solid #E8E7E7',borderBottom:'1px solid #E8E7E7'}}>
            <div>Ideas </div>
            <div>Followers </div>
            <div>Following</div>
            <div className='' style={{borderBottom:'3px solid #7E66BC'}}>Setting</div>
        </div>
    <div className='row'>
    
    
    <div  className='col-9 mt-3'>
    {/* <table class="table text-center" >
      <thead>
        <tr>
          <th scope="col">Profile Details</th>
          <th scope="col"></th>
    
        </tr>
      </thead>
      <tbody >
        <tr >
          <td>First Name</td>
          <td>Muazzim</td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>Hussain</td>
        </tr>
        <tr>
          <td>Email Address</td>
          <td>Muazzimqcodes2gmail.com</td>
        </tr>
        <tr>
          <td>Phone Number</td>
          <td>91+ 1234567890</td>
        </tr>
        <tr>
          <td>Gender</td>
          <td>male</td>
        </tr>
        <tr>
          <td>Age Group</td>
          <td>28</td>
        </tr>
      </tbody>
    </table> */}
    <div>
    <div className='ms-3 profileborder' >
      <p className='' style={{marginLeft:'15%'}}>Profile Details</p>
    </div>
    <div className='ms-3 d-flex justify-content-around text-start mt-2 profileborder'>
      <p className='text-start'>First Name</p>
      <p>Muazzim</p>
    </div>
    <div className='ms-3 d-flex justify-content-around text-start  mt-2 profileborder'>
      <p>Last Name</p>
      <p>Hussain</p>
    </div>
    <div className='ms-3 d-flex justify-content-around text-start  mt-2 profileborder'>
      <p>Email Address</p>
      <p>Muazzimqcodes2gmail.com</p>
    </div>
    <div className='ms-3 d-flex justify-content-around text-start  mt-2 profileborder'>
      <p>Phone Number</p>
      <p>91+1234567890</p>
    </div>
    <div className='ms-3 d-flex justify-content-around text-start  mt-2 profileborder' >
      <p>Gender</p>
      <p>male</p>
    </div>
    <div className='ms-3 d-flex justify-content-around text-start  mt-2 profileborder'>
      <p>Age Group</p>
      <p>28</p>
    </div>
    </div>
    
    
    
    </div>
    <div className='col-3' >
        <div className='m-3'>
            <div className='card d-flex justify-content-center text-center'style={{borderRadius:'10PX'}} >
                <div className='text-center' >
                    <p className='text-white p-2 backgroundborder'><span> <i className="fa fa-user text-white me-5" /></span>Profile Details</p>
    
                </div>
                <div  className='text-start ms-4'>
                    <p><span>  <i className="fa fa-unlock-alt me-3" /></span>Reset Password</p>
                    <p><span>  <i className="fa fa-star me-3 text-warning" /></span>Premium Membership</p>
                    <p><span>  <i className="fa fa-file-text me-3" /></span>Refer a friend</p>
                </div>
                <div className='p-1 text-start ms-4 borderaround'>
                    <p> <span>  <i className="fa fa-question-circle me-3 " /></span>Help Center</p>
                    <p> <span>  <i className="fa fa-bell me-3 " /></span>Notification</p>
            
                </div>
            <div className='mt-2 text-start ms-4'>
            <p className='text-danger'> <span>  <i className="fa fa-power-off me-3 text-danger" /></span>logout</p>
    
    
             
            </div>
    
            </div>
    
    
        </div>
    
    </div>
    </div>
    </div>
    
        </div>
    </div>
    <Footer />
    
        </>
    )}
export default Profilelight