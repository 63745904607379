import React, { useEffect, useRef } from 'react';

const Graphmini = ({ symbol, Themes }) => {
  const widgetRef = useRef(null);

  useEffect(() => {
    // Ensure the widget container is cleared before adding a new script
    if (widgetRef.current) {
      widgetRef.current.innerHTML = '';  // Clear the previous widget content

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      script.innerHTML = JSON.stringify({
        symbol: symbol,
        width: "100%",
        height: "100%",
        locale: "en",
        dateRange: "12M",
        trendLineColor: "rgba(0, 255, 0, 1)",
        colorTheme: Themes ? 'dark' : 'light',  // Handle theme dynamically
        isTransparent: true,
        autosize: true,
        largeChartUrl: "",
      });
    
      widgetRef.current.appendChild(script);
    }
  }, [symbol, Themes]);  // Re-run effect when symbol or Themes changes

  return (
    <div className="tradingview-widget-container" ref={widgetRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default Graphmini;
