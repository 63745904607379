import React,{useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import './style.css'
import Footer from '../footer/Footer'

const Relatednews = () => {
  
  return (
    <>
     <div>
        <div>
            
        </div>
        <div className='card p-4'>
            <div className='mx-3 mt-2'>
                <h3>Realated News</h3>
                <p>what out happening with the company</p>
            </div>
            <div>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 ' >
                        <div className='card text-center'>
                        <div>
                            <img src='./images/21.png' style={{width:'100%'}} />

                        </div>
                        <div className='mt-1 d-flex justify-content-start p-3'>
                            <button className='btn btn-danger btn-sm'>
                                Live
                            </button>
                            <button className='btn btn-warning btn-sm mx-3'>
                                Breaking
                            </button>
                            <button className='btn btn-secondary btn-sm'>
                                INR
                            </button>

                        </div>
                        <div className='p-2'>


                            <p className='font-para' style={{fontWeight:'600' ,color:'gray'}}>
                                A report from Reliance  Industries Ltd (RIL) which owns Reliance Retail,
                                which in turn operates Reliance Treads, a fashion retail format said,The Opportunity of integrating an offline-online model would truly
                            </p>
                            </div>



                        </div>


                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 ' >
                        <div className='card text-center'>
                        <div>
                            <img src='./images/57887.png' style={{width:'100%'}} />

                        </div>
                        <div className='mt-1 d-flex justify-content-start p-3'>
                            <button className='btn btn-danger btn-sm'>
                                Live
                            </button>
                            <button className='btn btn-warning btn-sm mx-2'>
                                Breaking
                            </button>
                            <button className='btn btn-secondary btn-sm'>
                                INR
                            </button>

                        </div>
                        <div className='p-2'>


                            <p className='font-para' style={{fontWeight:'600' ,color:'gray'}}>
                                A report from Reliance  Industries Ltd (RIL) which owns Reliance Retail,
                                which in turn operates Reliance Treads, a fashion retail format said,The Opportunity of integrating an offline-online model would truly
                            </p>
                            </div>



                        </div>


                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 ' >
                        <div className='card text-center'>
                        <div>
                            <img src='./images/122987.png' style={{width:'100%'}} />

                        </div>
                        <div className='mt-1 d-flex justify-content-start p-3'>
                            <button className='btn btn-danger btn-sm'>
                                Live
                            </button>
                            <button className='btn btn-warning btn-sm mx-2'>
                                Breaking
                            </button>


                        </div>
                        <div className='p-2'>


                            <p className='font-para' style={{fontWeight:'600' ,color:'gray'}}>
                                A report from Reliance  Industries Ltd (RIL) which owns Reliance Retail,
                                which in turn operates Reliance Treads, a fashion retail format said,The Opportunity of integrating an offline-online model would truly
                            </p>
                            </div>



                        </div>


                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12' >
                        <div className='card text-center'>
                        <div>
                            <img src='./images/91.png' style={{width:'100%'}} />

                        </div>
                        <div className='mt-1 d-flex justify-content-start p-3'>
                            <button className='btn btn-danger btn-sm'>
                                Live
                            </button>
                            <button className='btn btn-warning btn-sm mx-2'>
                                Breaking
                            </button>
                            <button className='btn btn-secondary btn-sm'>
                                INR
                            </button>

                        </div>
                        <div className='p-2'>


                            <p className='font-para' style={{fontWeight:'600' ,color:'gray'}}>
                                A report from Reliance  Industries Ltd (RIL) which owns Reliance Retail,
                                which in turn operates Reliance Treads, a fashion retail format said,The Opportunity of integrating an offline-online model would truly
                            </p>
                            </div>



                        </div>


                    </div>
                    
                </div>
                
            </div>


        </div>
        <div className='card p-4 mt-4 mb-4 mb-4'>
            <div className='mx-3 mt-2'>
                <h3>Press Release</h3>
                <p>what out happening with the company</p>
            </div>
            <div>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12' >
                        <div className='card text-center'>
                        <div>
                            <img src='./images/57887.png' style={{width:'100%'}} />

                        </div>
                        <div className='mt-1 d-flex justify-content-start p-3'>
                            <button className='btn btn-danger btn-sm'>
                                Live
                            </button>
                            <button className='btn btn-warning btn-sm mx-2'>
                                Breaking
                            </button>
                           

                        </div>
                        <div className='p-2'>


                            <p className='font-para' style={{fontWeight:'600' ,color:'gray'}}>
                                A report from Reliance  Industries Ltd (RIL) which owns Reliance Retail,
                                which in turn operates Reliance Treads, a fashion retail format said,The Opportunity of integrating an offline-online model would truly
                            </p>
                            </div>



                        </div>


                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 ' >
                        <div className='card text-center'>
                        <div>
                            <img src='./images/85709 1.png' style={{width:'100%'}} />

                        </div>
                        <div className='mt-1 d-flex justify-content-start p-3'>
                            <button className='btn btn-danger btn-sm'>
                                Live
                            </button>
                            <button className='btn btn-warning btn-sm mx-2'>
                                Breaking
                            </button>
                            <button className='btn btn-secondary btn-sm'>
                                INR
                            </button>

                        </div>
                        <div className='p-2'>


                            <p className='font-para' style={{fontWeight:'600' ,color:'gray'}}>
                                A report from Reliance  Industries Ltd (RIL) which owns Reliance Retail,
                                which in turn operates Reliance Treads, a fashion retail format said,The Opportunity of integrating an offline-online model would truly
                            </p>
                            </div>



                        </div>

                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 ' >
                        <div className='card text-center'>
                        <div>
                            <img src='./images/57887.png' style={{width:'100%'}} />

                        </div>
                        <div className='mt-1 d-flex justify-content-start p-3'>
                            <button className='btn btn-danger btn-sm'>
                                Live
                            </button>
                            <button className='btn btn-warning btn-sm mx-2'>
                                Breaking
                            </button>
                            <button className='btn btn-secondary btn-sm'>
                                INR
                            </button>

                        </div>
                        <div className='p-2'>


                            <p className='font-para' style={{fontWeight:'600' ,color:'gray'}}>
                                A report from Reliance  Industries Ltd (RIL) which owns Reliance Retail,
                                which in turn operates Reliance Treads, a fashion retail format said,The Opportunity of integrating an offline-online model would truly
                            </p>
                            </div>



                        </div>


                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 ' >
                        <div className='card text-center'>
                        <div>
                            <img src='./images/57887.png' style={{width:'100%'}} />

                        </div>
                        <div className='mt-1 d-flex justify-content-start p-3'>
                            <button className='btn btn-danger btn-sm'>
                                Live
                            </button>
                            <button className='btn btn-warning btn-sm mx-2'>
                                Breaking
                            </button>

                        </div>
                        <div className='p-2'>


                            <p className='font-para' style={{fontWeight:'600' ,color:'gray'}}>
                                A report from Reliance  Industries Ltd (RIL) which owns Reliance Retail,
                                which in turn operates Reliance Treads, a fashion retail format said,The Opportunity of integrating an offline-online model would truly
                            </p>
                            </div>



                        </div>


                    </div>
                    
                </div>
                
            </div>


        </div>

     </div>

      </>
  )
}

export default Relatednews
