import React ,{useState ,useEffect,useContext } from 'react';
import "./css/login.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import FetchData from "../fetchapi/Apifetch";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../components/Utills/UserContext';
import { signInWithEmailAndPassword,signInWithPopup } from 'firebase/auth';
import { auth ,googleAuthProvider} from "../../firebase";
import DisclaimerModal from '../pages/DisclaimerModal';



const Login = () => {
  const { theme, setTheme } = useContext(UserContext);
  const [buttonclick, setbuttonclick] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [formdata1, setFormdata1] = useState({
    first_name: "",
    last_name: "",
    email: "",  
    password:"",
    status:'1'
  });
  const [Showmodel, setShowmodel] = useState(false);
  const [userid, setUserid] = useState('');

const CloseModel = async () => {

  try {
    const token = false;
    const data = {
      userstatus: true,
    };
    const res = await FetchData(`userstatus/${userid}`, 'POST', JSON.stringify(data), token, false);
    if (res.data) {
        localStorage.setItem("login",true);
        setUser(true)
        console.log('res.data',res.data) ;
        const jsonLoginData = JSON.stringify(res.data);
        localStorage.setItem("email",jsonLoginData);
        toast.success("Login successfully");
        setEmail('');
        setPassword('');  
        // navigate("/dashboard");
        // window.location.href = "/dashboard" 
        setShowmodel(false)
    }
} catch (error) {
    await toast.error(error.message);
    setbuttonclick(false);
  }
  
}
   
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await FetchData(
  //       `technical`,
  //       "GET",
  //       null,
  //       true,
  //       false
  //     );
  //     if (res) {
  //       // console.log('res', res.data)
  //       const purify = res.data;
  //       const purificationdata = JSON.stringify(purify);
  //       localStorage.setItem("purification",purificationdata);
  //     }
  //   };
  
  //     fetchData();
  // }, []);

  // const [Value,setValue] =
  useEffect(() => {
    stocksall();
    Indstry();

    // stocks1();
  }, []);

  const stocksall = async (value) => {
    try {
      // alert('hello');
      const res = await FetchData("stocksall", "GET", null, true, false);
      if (res) {
        const searchstock = res.data;
        const jsonLoginData = JSON.stringify(searchstock);
        localStorage.setItem("stockdata",jsonLoginData);
        // setStocklist(searchstock);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 
   const Indstry = async () => {
     try {
       const res = await FetchData("Industry", "GET", null, true, false);
       if (res) {
         const searchindustry = res.data.data;
        //  console.log('searchindustry',searchindustry);
         const AllIndustry = JSON.stringify(searchindustry);
         localStorage.setItem("Industry",AllIndustry);
       }
     } catch (error) {
       console.error("Error fetching data:", error);
     }
   };




    const CheckEmaildata = async (emailvalue) => { 
      const res = await FetchData(
        `usercheck?q=${emailvalue}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
    //  console.log('res',res.data)
        // setData(res.data.data);
        // setloader(false);
      }
    };


  const LoginCheck = async () => {
    try {

        setbuttonclick(true)
        if (!email || !password) {
            setbuttonclick(false)
            toast.error("Please enter both email and password.");
            return;
        }
        const token = false;
        const data = {
            email: email,
            password: password,
            user_type:1
        };
        const res = await FetchData("login", 'POST', JSON.stringify(data), token, false);
        if (res.data) {

         if(!res?.data?.userstatus){
          setShowmodel(true);
          setUserid(res?.data?._id)
         }else{
            localStorage.setItem("login",true);
            setUser(true)
            console.log('res.data',res.data) ;
            const jsonLoginData = JSON.stringify(res.data);
            localStorage.setItem("email",jsonLoginData);
            toast.success("Login successfully");
            setEmail('');
            setPassword('');  
         }
          
      
            // navigate("/dashboard");
            // window.location.href = "/dashboard" 
        }
    } catch (error) {
    
      if (error.message === 'Your account is not verified.') {
        await toast.error(error.message);
        setbuttonclick(false);
        setTimeout(() => {
          navigate("/otpverification", { state: { data:email} });
        }, 3000); // Adjust the timeout duration as needed (2000 ms = 2 seconds)
      } else {
        await toast.error(error.message);
        setbuttonclick(false);
      }
      
     
    }
}

// const handlegooglelogin = () => {
//   signInWithPopup(auth,googleAuthProvider)
//   .then(async (result) => {
//     const { user } = result;
//     console.log('result',result)
//    const checkUser = await CheckEmaildata(user.email);
//    console.log('checkUser',checkUser)
  //  if(!checkUser){
  //     const formselect = new FormData();
  //     formselect.append("data", JSON.stringify({ 
  //         gmail: user.email,
  //         first_name: user.displayName,
  //         profile: user.photoURL,
  //         phone: user.providerData[0]?.phoneNumber,
  //         registerType: "USER",
  //      }));
  //     const res = await FetchData("add/user", 'POST', formselect, false, true);
  //     if (res.success) {
  //         localStorage.setItem("LOGINDATA", JSON.stringify(res.data.data))
  //         RedirectRoute("/feed-page")
  //          return true;
  //     }
  //  }
//   })
//   .catch((err) => {
//     toast.error(err.message);
//   });
// }
const handlegooglelogin = () => {
  signInWithPopup(auth,googleAuthProvider)
    .then(async (result) => {
      const { user } = result;
      // console.log('result', result);

      // Define CheckEmaildata function here
      const checkEmailData = async (emailValue) => {
        const res = await FetchData(
          `usercheck?q=${emailValue}`,
          "GET",
          null,
          true,
          false
        );
        if (res.success === true) {
          // console.log('data',res.data);
          localStorage.setItem("login",true);
          setUser(true)
          toast.success("Login successfully");
          const fullName = user.displayName.split(' ');
          const userdetails = {     
            email: user.email,
            first_name:fullName[0],
            last_name:fullName[1] ? fullName[1] : '',
            img:user.photoURL,
        };
          // console.log('user',userdetails)
          localStorage.setItem("email",JSON.stringify(res.data));
          navigate("/dashboard");
        } 
        else {
          const token = false;
          const fullName = user.displayName.split(' ');
          const formselect = {     
               email: user.email,
               first_name:fullName[0],
               last_name:fullName[1] ? fullName[1] : '',
               password:'',
               status:'1',
               img:user.photoURL,
               otpstatus: true,
               userstatus: false,
               goglelogin:true
           };

          const res = await FetchData("add/user", 'POST', JSON.stringify(formselect), token, false);
          if (res.success) {
          if(!res?.data?.userstatus){
              setShowmodel(true);
              setUserid(res?.data?._id)
             }else{
            localStorage.setItem("login",true);
            setUser(true)
            toast.success("Login successfully");
            const jsonLoginData = JSON.stringify(res.data);
            localStorage.setItem("email",jsonLoginData);
            navigate("/dashboard");
            return true;
             }
          }
          
        }
      
      };
      const checkUser = await checkEmailData(user.email);
    })
    .catch((error) => {
      console.error('Error during Google login:', error);
    });
};




  return (
    <>
  
    <div className={`container-fluid mt-5 ${theme?'text-white':'text-black'}`} style={{width:"80%",border:"1px solid #ededed",height:'85vh'}}>
    <ToastContainer />

    <DisclaimerModal showModal={Showmodel} handleClose={CloseModel}  />
        <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-12  d-flex align-items-center bg-white'>
        <div className='p-4 w-100'>
        <h3 className='mb-5 ' style={{marginTop:"-10%"}}>Welcome to Islamic Tijarat <sup style={{fontSize:'12px'}}>TM</sup></h3>
      <form>
  <div class="mb-3 mt-3 ">
    <label for="exampleInputEmail1" class="form-label">Email</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>{setEmail(e.target.value)}} />
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1" onChange={(e)=>{setPassword(e.target.value)}} />
  </div>
  <div className='text-end mb-2'><a href='' className=' text-primary' onClick={()=>{navigate('/forgotpassword')}}>Forgot Password?</a></div>
  
  <button type="submit" class="btn btn-primary w-100" disabled={buttonclick} style={{backgroundColor:"#208203"}} onClick={()=>{LoginCheck()}}>LOG IN</button>
  <p className='text-center my-2'>Or login with</p>
     <div className='d-flex'>
     <div className='card d-flex flex-row w-50 me-1 py-2 align-items-center justify-content-center btn-card' 
     onClick={()=>{handlegooglelogin()}}>
        <img src="images/icons/Google.png" alt='logo' style={{width:"21px",height:"22px"}} /> 
         <p className='mb-0 ms-1'>Google</p>
     </div>
     <div className='card d-flex flex-row w-50 py-2 align-items-center justify-content-center btn-card'>
        <img src="images/icons/meta.png" alt='logo' style={{width:"33px",height:"18.56px"}} /> 
         <p className='mb-0 ms-1'>Meta</p>
     </div>
     </div>
</form>
    </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 p-0 img-layout' >
        <img src="images/loginpage.png" alt='logo' style={{width:"100%",height:"100%"}} /> 
          <div className='text-overlay'>
          <img src="logonew.png" alt='logo' className='mt-1' style={{width:"130px",height:"130px"}} /> 
             <img src={"images/app/itg-logo1.png"}  alt="Logo" style={{width:"280px",height:"60px"}} />
          <p className='paratext px-4'>
          At Islamic Tijarat , we redefine what it means to invest with faith. Our industry-leading Shariah screening technology and uncompromising compliance standards make your investment journey seamless and precise, so you never have to choose between profits and principles.
Pure Profits. Absolute Peace of Mind.
Invest the Right Way—Shariah Compliant, Always. </p>
          <div className='mt-3'>
          <div className={`${theme ? "social-icons-dark" : "social-icons"} mb-4  mt-0`}>
          <a href="https://www.facebook.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/facebook.png' className='p-2 ' style={{backgroundColor:"white",borderRadius:'50%'}}  width={40} height={40} />
            </a>
            <a href="https://www.instagram.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/insta.png' className=' p-2 ' style={{backgroundColor:"white",borderRadius:'50%'}}    width={40} height={40} />
            </a>
            <a href="https://x.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/twitter.png'  className=' p-2 '  style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
            <a href="https://www.youtube.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/youtube.png' className='  p-2 '  style={{backgroundColor:"white",borderRadius:'50%'}}   width={35} height={35} />
            </a>
            <a href="https://www.linkedin.com/in/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/linkedin.png'   className='p-2' style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
          </div>
          <p className='paratext mt-3'>Don’t have an account</p>
          <a className='paratext btn-card text-white' onClick={()=>{window.location.href="/register"}} >Sign Up?</a>
          </div>
          </div>
        </div>
        </div>
     </div>
    </>
  )
}

export default Login