import React, { useState, useEffect } from "react";
import FetchData from "../fetchapi/Apifetch";
import Footer from "../footer/Footer";
import Navbar from "../Navbar/Navbar";
import "../style.css";
import { useNavigate } from 'react-router-dom';
const StockPage = () => {
  const [activeButton, setActiveButton] = useState(0);
  const [Stockscreener, setStockscreener] = useState("");
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const buttons = [
    "Most Active",
    "Most Popular",
    "Top Gainers",
    "Top Losers",
    "52 week High",
  ];

  const NextPage = () => {
    setPage(page + 1);
  };

  const PreviousPage = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    Stockscreenerlist();
  }, [page]);

  const Stockscreenerlist = async () => {
    // const res = await FetchData(`get/stockscreener?page=${page}&limit=12`, 'GET', null, true, false)
    const res = await FetchData(
      `stocklist?page=${page}&limit=12`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setStockscreener(res.data.data);
      setPaginationInfo(res.data.pagination);
    }
  };

  const navigate = useNavigate();

  const handleRowClick = (item) => {
    navigate('/stock', { state: { data: item } });
  };

  const getButtonColor = (Compliance) => {
    switch (Compliance) {
      case "Halal":
        return "#dfffd6";

      case "Haram":
        return "#ffe7d6";
      case "Doubtful !":
        return "#f6f7b0";
    }
  };

  const PaginationIndex = () => {
    return (
      <div className="mx-auto my-4">
        <nav aria-label="Page navigation example">
          <ul className="pagination" style={{ marginLeft: "10px" }}>
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={PreviousPage}>
                Previous
              </button>
            </li>
            {paginationNumbers().map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${page === pageNumber ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => setPage(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                page === paginationInfo?.totalPages ? "disabled" : ""
              }`}
            >
              <button className="page-link" onClick={NextPage}>
                Next
              </button>
            </li>
          </ul>
        </nav>
        <div className="pagination my-2" style={{ marginLeft: "17px" }}>
          <p>Total Records: {paginationInfo?.totalItems}</p>
          <br />
          <p style={{ paddingLeft: "10px" }}>
            Total Pages: {paginationInfo?.totalPages}
          </p>
        </div>
      </div>
    );
  };
  const paginationNumbers = () => {
    const totalPages = paginationInfo?.totalPages || 0;
    const currentPage = page;
    const paginationRange = 10;
    const paginationStart = Math.max(
      1,
      currentPage - Math.floor(paginationRange / 2)
    );
    const paginationEnd = Math.min(
      totalPages,
      paginationStart + paginationRange - 1
    );
    const numbersToShow = [];
    for (let i = paginationStart; i <= paginationEnd; i++) {
      numbersToShow.push(i);
    }
    return numbersToShow;
  };

  return (
   <>
   <Navbar/>
   <div className='container-fluid stpage-header' style={{width:"100%",marginTop:'8%'}}>
   <div className='col-lg-12 col-md-12 '>
    <div className='card mt-3 p-3'>
                <div className='d-flex justify-content-between'>
                <h4 className='fonttitle '>Trending Halal Stocks</h4>
                {/* <button className='btn  text-success '>View All <i class="fa-solid fa-arrow-right text-success" style={{color:"black",marginLeft:"5px"}}></i> </button>  */}
                </div>

            <div>
              {buttons.map((button, index) => (
                <button
                  key={index}
                  className="btn"
                  style={{
                    borderRadius: "20px",
                    backgroundColor:
                      activeButton === index ? "green" : "transparent",
                    color: activeButton === index ? "white" : "black",
                  }}
                  onClick={() => setActiveButton(index)}
                >
                  {button}
                </button>
              ))}
            </div>

            <table
              class="table mt-2 table-responsive "
              style={{ border: "0.5px solid #ebebeb", textAlign: "start" }}
            >
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">CashTotalAsset</th>
                  <th scope="col">DebtAsset</th>
                  <th scope="col">Compliance</th>
                </tr>
              </thead>
              <tbody>
                {Stockscreener &&
                  Stockscreener.map((item, index) => {
                    // const buttonColor = getButtonColor(index);
                    // const styleBtn =getButtonColor(item.Compliance)
                    return (
                      <tr className="mx-auto">
                        <th scope="row">
                          <div
                            className="d-flex justify-content-start"
                            onClick={() => (handleRowClick(item))}
                          >
                            {/* <img className='' src={item.stockData.img }  alt='img' style={{height:"40px",width:"40px",borderRadius:"50%",border:"0.5px solid #ededed"}}/> */}
                            <img
                              className=""
                              src={item.stockData.img}
                              alt="img"
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "50%",
                                border: "0.5px solid #ededed",
                              }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "./images/dummy.png";
                              }}
                            />
                            <div className="">
                              <h3
                                className="mb-0"
                                style={{
                                  padding: "0px 10px",
                                  fontSize: "1rem",
                                }}
                              >
                                {item?.screenerData.stock}
                              </h3>
                            </div>
                          </div>
                        </th>
                        <td>
                          {item?.screenerData.cashTotalAsset === undefined ||
                          item?.screenerData.cashTotalAsset === null ||
                          isNaN(Number(item?.screenerData.cashTotalAsset))
                            ? "N/A"
                            : Number(item.screenerData.cashTotalAsset).toFixed(
                                2
                              )}
                        </td>
                        <td>
                          {item?.screenerData.debtAsset === undefined ||
                          item?.screenerData.debtAsset === null ||
                          isNaN(Number(item?.screenerData.debtAsset))
                            ? "N/A"
                            : Number(item.screenerData.debtAsset).toFixed(2)}
                        </td>
                        {item?.screenerData?.status == 1 ? (
                          <td>
                            {" "}
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#DFFFD6",
                                color: "#218303",
                              }}
                            >
                              Halal{" "}
                              <i
                                class="fa-solid fa-check"
                                style={{ color: "green" }}
                              ></i>{" "}
                            </button>
                          </td>
                        ) : item?.screenerData?.status == 2 ? (
                          <td>
                            {" "}
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#ffd6d6",
                                color: "#9f0000",
                              }}
                            >
                              Haram{" "}
                              <i
                                class="fa-solid fa-times"
                                style={{ color: "red" }}
                              ></i>{" "}
                            </button>
                          </td>
                        ) : (
                          <td>
                            {" "}
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#feffd6",
                                color: "#9f8500",
                              }}
                            >
                              {" "}
                              Unknown
                              <i
                                class="fa-solid fa-circle-info"
                                style={{ color: "#9f8500" }}
                              ></i>{" "}
                            </button>
                          </td>
                        )}
                        {/* {item?.status == 2 &&<td> <button className='btn' style={{backgroundColor:"#ffd6d6",color:"#9f0000"}}>Haram <i class="fa-solid fa-times" style={{color:"red"}}></i> </button></td>} */}
                        {/* {item?.status == 3 &&<td> <button className='btn' style={{backgroundColor:"#ffd6d6",color:"#9f0000"}}>Haram <i class="fa-solid fa-times" style={{color:"red"}}></i> </button></td>}
            {item?.status == 4 &&<td> <button className='btn' style={{backgroundColor:"#ffd6d6",color:"#9f0000"}}>Haram <i class="fa-solid fa-times" style={{color:"red"}}></i> </button></td>}
            {item?.status == 5 &&<td> <button className='btn' style={{backgroundColor:"#ffd6d6",color:"#9f0000"}}>Haram <i class="fa-solid fa-times" style={{color:"red"}}></i> </button></td>}
            {item?.status == 6 &&<td> <button className='btn' style={{backgroundColor:"#ffd6d6",color:"#9f0000"}}>Haram <i class="fa-solid fa-times" style={{color:"red"}}></i> </button></td>}
            {item?.status == 7 &&<td> <button className='btn' style={{backgroundColor:"#ffd6d6",color:"#9f0000"}}>Haram <i class="fa-solid fa-times" style={{color:"red"}}></i> </button></td>} */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <PaginationIndex />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default StockPage;
