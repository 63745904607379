import React ,{useContext} from 'react'
import Navbar from '../Navbar/Navbar'
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../components/Utills/UserContext';


const Profile = (item) => {
  const Industry = item?.value;
  
  const { theme, setTheme } = useContext(UserContext);

  const location = useLocation();
  const { data } = location.state;

  console.log('Data received:', data);


  const Fixedvalue2 = (value) => {
    const numberValue = parseFloat(value);

    // Check if numberValue is a valid number
    if (!isNaN(numberValue)) {
      const fixedValue = numberValue.toFixed(2); // Format number to 2 decimal places
      return fixedValue;
    } else {
      return ''; // Return empty string or handle invalid input as needed
    }
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Options for formatting
    const options = { 
      month: 'short', // Short month name (e.g., "Jan", "Feb", etc.)
      day: '2-digit', // Two-digit day of the month (e.g., "01", "02", etc.)
      hour: 'numeric', // Numeric hour (e.g., "01", "02", ..., "12")
      minute: '2-digit', // Two-digit minute (e.g., "00", "01", ..., "59")
      hour12: true // Use 12-hour clock (true) or 24-hour clock (false)
    };
  
    // Format the date
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    return formattedDate;
  };
  return (
<>
{/* <Navbar/> */}
  {/* <div class="container-fluid" style={{width:"95%",marginTop:"8%"}}> */}
      
       {/* <header
    _ngcontent-qal-c183=""
    className="stock-details-container overflow-hidden"
  >
    <app-details-header _ngcontent-qal-c183="" _nghost-qal-c141="">
      <div _ngcontent-qal-c141="" className="container-fluid details-wrapper">
        <div
          _ngcontent-qal-c141=""
          className="d-flex align-items-start justify-content-between mb-4 inner-wrapper"
        >
          <div
            _ngcontent-qal-c141=""
            className="intro-wrapper d-flex align-items-center"
          >
            <div
              _ngcontent-qal-c141=""
              className="stock-logo-container flex-shrink-0 ratio ratio-1x1 me-3 me-md-4"
            >
              <img
                _ngcontent-qal-c141=""
                alt=""
                className="img-fluid rounded-circle bg-light stock-image"
                src={data.stockData.img}
              />
       
            </div>
            <div _ngcontent-qal-c141="">
              <div
                _ngcontent-qal-c141=""
                className="company-name-wrapper d-flex align-items-end mb-2 mb-lg-3 flex-wrap"
              >
                <h5
                  _ngcontent-qal-c141=""
                  className="mb-0 stock-name lh-1 me-2"
                  style={{fontSize:'30px',fontWeight:'700'}}

                >
                {data.screenerData.stock}
                </h5>
             
                <h6 _ngcontent-qal-c141="" className="mb-0 ticker-name lh-l" style={{color:'gray',fontWeight:'700'}}>
                {data.stockData.stock_code}
                </h6>
            
              </div>
              <div
                _ngcontent-qal-c141=""
                className="align-items-end gap-2 stock-price-details resp-slider d-lg-none"
              >
                <span
                  _ngcontent-qal-c141=""
                  className="stock-price lh-1 resp-slider-item"
                >
                  $129.24
                </span>
             
                <span
                  _ngcontent-qal-c141=""
                  className="d-flex align-items-center resp-slider-item"
                >
                  <i
                    _ngcontent-qal-c141=""
                    className="bi bi-triangle-fill d-inline-block lh-1 positive"
                  />
                  <span
                    _ngcontent-qal-c141=""
                    className="stock-price-changes lh-1 positive"
                  >
                    {" "}
                    $111.08{" "}
                  </span>
                </span>
           
                <span
                  _ngcontent-qal-c141=""
                  className="d-flex align-items-center percent-change resp-slider-item"
                >
                  <i
                    _ngcontent-qal-c141=""
                    className="bi bi-triangle-fill d-inline-block lh-1 positive"
                  />
                  <span
                    _ngcontent-qal-c141=""
                    className="stock-price-changes lh-1 positive"
                  >
                    {" "}
                    611.67%{" "}
                  </span>
                </span>
          
                <span
                  _ngcontent-qal-c141=""
                  className="stock-years lh-1 resp-slider-item"
                >
                  Past 3 Years
                </span>
           
              </div>
           
              <div _ngcontent-qal-c141="">
                <div
                  _ngcontent-qal-c141=""
                  className="chips-container resp-slider align-items-center gap-2 d-none d-lg-flex"
                >
                  <span
                    _ngcontent-qal-c141=""
                    className="stock-chip rounded-pill resp-slider-item "
                    style={{border:"2px solid #E9FAE9",borderRadius:'50%',padding:'3px',backgroundColor:'#e9fae9', fontSize:'12px'}}
                  >
                    {" "}
                    United States{" "}
                  </span>
              
                  <span
                    _ngcontent-qal-c141=""
                    className="stock-chip rounded-pill resp-slider-item"
                    style={{border:"2px solid #E9FAE9",borderRadius:'50%',padding:'3px',backgroundColor:'#e9fae9', fontSize:'12px'}}

                  >
                    {" "}
                    Information Technology{" "}
                  </span>
             
                  <span
                    _ngcontent-qal-c141=""
                    className="stock-chip rounded-pill resp-slider-item"
                    style={{border:"2px solid #E9FAE9",borderRadius:'50%',padding:'3px',backgroundColor:'#e9fae9', fontSize:'12px'}}

                  >
                    {" "}
                    Mega Cap{" "}
                  </span>
               
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-qal-c141=""
            className="watchlist-wrapper d-none d-lg-block" style={{marginTop:"50px"}}
          >
          
              <span
                _ngcontent-qal-c141=""
                className="stock-price-changes lh-1 positive fonttitle"
                style={{color:'green',fontSize:'12px',fontWeight:'800',fontSize:"28px",}}
              >
                {" "}
                ₹{data.screenerData.BSE_NSE}
              </span>
           
          </div>
        </div>
        <div _ngcontent-qal-c141="" className="d-flex">
          <div
            _ngcontent-qal-c141=""
            className="chips-container d-flex align-items-center resp-slider gap-2 d-lg-none"
          >
            <span
              _ngcontent-qal-c141=""
              className="stock-chip rounded-pill resp-slider-item"
            >
              {" "}
              United States{" "}
            </span>
    
            <span
              _ngcontent-qal-c141=""
              className="stock-chip rounded-pill resp-slider-item"
            >
              {" "}
              Information Technology{" "}
            </span>
  
            <span
              _ngcontent-qal-c141=""
              className="stock-chip rounded-pill resp-slider-item"
            >
              {" "}
              Mega Cap{" "}
            </span>
           
          </div>
        </div>
        <div _ngcontent-qal-c141="" className="d-flex">
          <div
            _ngcontent-qal-c141=""
            className="stock-exchange-container d-flex align-items-center resp-slider gap-2 d-lg-none"
          >
            <span
              _ngcontent-qal-c141=""
              className="stock-exchange-chip red-chip d-flex align-items-center gap-1 rounded-pill resp-slider-item"
            >
              <i
                _ngcontent-qal-c141=""
                className="bi bi-clock text-secondary"
              />
              <span _ngcontent-qal-c141="">Jul 15, 03:10 PM</span>
            </span>
            <span
              _ngcontent-qal-c141=""
              className="stock-exchange-chip green-chip d-flex align-items-center gap-2 rounded-pill resp-slider-item"
            >
              <i
                _ngcontent-qal-c141=""
                className="bi bi-graph-up text-secondary"
              />
              <span _ngcontent-qal-c141="">NASDAQ</span>
            </span>
            <span
              _ngcontent-qal-c141=""
              className="stock-exchange-chip d-flex align-items-center gap-2 green-chip rounded-pill resp-slider-item"
            >
              <i _ngcontent-qal-c141="" className="bi bi-cash text-secondary" />
              <span _ngcontent-qal-c141="">Currency in USD</span>
            </span>
          </div>
        </div>

        <div _ngcontent-qal-c141="" className="d-none d-lg-block" style={{marginLeft:"8%"}}>
          <div
            _ngcontent-qal-c141=""
            className="align-items-end gap-2 mb-3 stock-price-details d-flex"
          >
            
           
            <span _ngcontent-qal-c141="" className="d-flex align-items-center">
              <i
                _ngcontent-qal-c141=""
                className="bi bi-triangle-fill d-inline-block lh-1 positive"
              />
              <span
                _ngcontent-qal-c141=""
                className="stock-price-changes lh-1 positive"
                style={{color:'green',fontSize:'12px',fontWeight:'800'}}
              >
                {" "}
                &#9652; ₹ {Fixedvalue2(data.screenerData.cashTotalAsset)}
              </span>
            </span>
       
            <span
              _ngcontent-qal-c141=""
              className="d-flex align-items-center percent-change"
            >
              <i
                _ngcontent-qal-c141=""
                className="bi bi-triangle-fill d-inline-block lh-1 positive"
              />
              <span
                _ngcontent-qal-c141=""
                className="stock-price-changes lh-1 positive"
                style={{color:'green',fontSize:'12px',fontWeight:'800'}}
              >
                {" "}
                &#9652; 611.67%{" "}
              </span>
            </span>
           
            <span _ngcontent-qal-c141="" className="stock-years lh-1" style={{color:'gray',fontSize:'14px'}}>
              Past 3 Years
            </span>
         
          </div>
          <p
            _ngcontent-qal-c141=""
            className="mb-0 d-flex update-date align-items-center"
            style={{color:'grey',fontSize:'14px'}}
          >
            <span _ngcontent-qal-c141="">Last Updated:{formatDate(data.screenerData.resultMonth)}</span> ·{" "}
            <span className='ms-1' _ngcontent-qal-c141="">{data.stockData.stock_code}</span>
          </p>
          <button
              _ngcontent-qal-c141=""
              className="btn shadow-none d-flex mt-2 align-items-center watchlist-btn  btn-outline-success"
              tabIndex={0}
            >
              <i _ngcontent-qal-c141="" className="bi bi-star" />
              <span _ngcontent-qal-c141="">Watchlist</span>
            </button>
      
        
        </div>

      
      </div>
    </app-details-header>
    <div _ngcontent-qal-c183="">
      <app-details-tab-btns
        _ngcontent-qal-c183=""
        _nghost-qal-c142=""
        className="bordered-tabs-btns-container"
      >
        <div
          _ngcontent-qal-c142=""
          className="container-md px-0 bordered-tabs-btns-container bg-white"
        
        >
          <div _ngcontent-qal-c142="" className="d-flex">
            <ul
              _ngcontent-qal-c142=""
              className="resp-slider btns-wrapper align-items-center justify-content-end"
            >
              <li _ngcontent-qal-c142="" className="resp-slider-item" >
              <button
                  _ngcontent-qal-c142=""
                  className="btn tab-button shadow-none"
                >
                  Overview{" "}
                  </button>
              </li>
              <li _ngcontent-qal-c142="" className="resp-slider-item">
                <button
                  _ngcontent-qal-c142=""
                  className="btn tab-button shadow-none"
                >
                  {" "}
                  Compliance{" "}
                </button>
              </li>
              <li _ngcontent-qal-c142="" className="resp-slider-item">
                <button
                  _ngcontent-qal-c142=""
                  className="btn tab-button shadow-none"
                >
                  {" "}
                  Forecast{" "}
                </button>
              </li>
              <li _ngcontent-qal-c142="" className="resp-slider-item">
                <button
                  _ngcontent-qal-c142=""
                  className="btn tab-button shadow-none"
                >
                  {" "}
                  News{" "}
                </button>
              </li>
              <li _ngcontent-qal-c142="" className="resp-slider-item me-3" style={{borderBottom:"3px solid green"}}>
                <button
                  _ngcontent-qal-c142=""
                  className="btn tab-button shadow-none"
                >
                  {" "}
                  Profile{" "}
                </button>
              </li>
            
            </ul>
          </div>
        </div>
      </app-details-tab-btns>
    </div>
  
  </header> */}
 
    
      <div className='card p-4'>
      <h2 className='font-subtitle text-start'>Profile</h2>
          <p className='font-para mb-2 text-secondary'>Adani Ports and Economic Zone Ltd. is an information technology services, consulting, and business solutions organization. The company is headquartered in Mumbai, Maharashtra and currently employs 603,305 full-time employees. The company went IPO on 2004-08-25. The company operates through Banking; Capital Markets; Consumer Goods and Distribution; Communications, Media, and Information Services; Education; Energy, Resources, and Utilities; Healthcare; High Tech; Insurance; Life Sciences; Manufacturing; Public Services; Retail; Travel and Logistics. The Company’s products include TCS ADD, TCS BaNCS, TCS BFSI Platforms, TCS CHROMA, TCS Customer Intelligence & Insights, TCS ERP on Cloud, TCS Intelligent Urban Exchange, Quartz-The Smart Ledgers, Jile, TCS Optumera, TCS TwinX, TCS TAP and TCS OmniStore. Its services portfolio consists of Cloud, Cognitive Business Operations, Consulting, Cybersecurity, Data and Analytics, Enterprise Solutions, IoT and Digital Engineering, Sustainability Services, TCS Interactive, TCS and AWS Cloud, TCS Enterprise Cloud, TCS and Google Cloud, TCS and Microsoft Cloud.</p>
        <div  className='d-flex'>
            <div>
                <p className='font-para fw-bold'>Sector :</p>
                <p className='font-para fw-bold'>Industry : </p> 
            </div>
            <div>
                <p className='font-para'> Industrials  </p>
                <p className='font-para'>{Industry}</p> 
            </div>
        </div>
        <h2 className='font-subtitle text-start mt-2'>Contact Information</h2>
        <div  className='d-flex mt-1'>
            <div>
                <p className='font-para fw-bold'>Phone :  </p>
                <p className='font-para fw-bold'>Website :  </p> 
                <p className='font-para fw-bold'>Address :</p> 
            </div>
            <div>
                <p className='font-para'> +91 21234 334567  </p>
                <p className='font-para'> https://www.loreum.com/ </p>
                <p className='font-para'> 9th Floor, Eiffle Building, Final Point </p>  
            </div>
        </div>
      </div>
  {/* </div> */}
</>
  )
}

export default Profile