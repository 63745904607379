import React, { useState, useRef, useEffect } from 'react';

const OTPInput = ({ length = 6, onChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));
  const inputs = useRef([]);

  useEffect(() => {
    if (inputs.current[0]) {
      inputs.current[0].focus();
    }
  }, []);

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];

    // Only allow digits and handle empty values
    if (/^\d$/.test(value) || value === '') {
      newOtp[index] = value;
      setOtp(newOtp);
      onChange(newOtp.join(''));

      // Move to the next input if value is not empty
      if (value && index < length - 1) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    const { key } = e;
    
    // Handle backspace
    if (key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';  // Clear the current value
      setOtp(newOtp);
      onChange(newOtp.join(''));
      
      // Move focus to the previous input if there is one
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste behavior
    const pastedData = e.clipboardData.getData('text'); // Get pasted data
    const dataArray = pastedData.split('').filter((char) => /^\d$/.test(char)); // Filter only digits

    // Only fill up to the length of the OTP
    const newOtp = [...otp];

    dataArray.forEach((char, index) => {
      if (index < length) {
        newOtp[index] = char; // Fill the OTP array
      }
    });

    setOtp(newOtp);
    onChange(newOtp.join(''));

    // Focus on the next empty input after pasting
    const nextIndex = dataArray.length < length ? dataArray.length : length - 1;
    if (inputs.current[nextIndex]) {
      inputs.current[nextIndex].focus();
    }
  };

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      {otp.map((_, index) => (
        <input
          key={index}
          ref={el => (inputs.current[index] = el)}
          type="text"
          maxLength="1"
          value={otp[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste} // Handle paste event
          style={{ width: '40px', height: '40px', textAlign: 'center', border: '1px solid #ccc' }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
