import React ,{useEffect ,useState,useContext ,useRef} from 'react'
import FetchData from "../fetchapi/Apifetch";
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import "./purify.css";
import { UserContext } from '../../components/Utills/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import { formatDate } from '@canvasjs/charts';
import { FaTimes } from 'react-icons/fa';

const Purificationcaluculator = () => {
  const [formdata, setFormdata] = useState({
    company: "",
    sharesowne: "",
    purchase: "",
    sell: '',
    calculate: "",
});

const [search, setsearch] = useState(false);
const [searchvalue, setsearchvalue] = useState('');
const [query, setQuery] = useState([]);
const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
const [data1, setData] = useState('');
const [calculatevalue, setCalculatevalue] = useState('');
const [selectedIndex, setSelectedIndex] = useState(-1);

// npm


const itemRefs = useRef([]);

const handleKeyDown = (e) => {
  if (e.key === 'ArrowDown') {
    setSelectedIndex((prevIndex) =>
      prevIndex < query.length - 1 ? prevIndex + 1 : prevIndex
    );
  } else if (e.key === 'ArrowUp') {
    setSelectedIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  } else if (e.key === 'Enter' && selectedIndex !== -1) {
    handlesearch(query[selectedIndex]);
  }
};

useEffect(() => {
  if (selectedIndex !== -1 && itemRefs.current[selectedIndex]) {
    itemRefs.current[selectedIndex].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }
}, [selectedIndex]);

const handleClearSearch = () => {
  setsearchvalue('');
  setCalculatevalue('');
  setFormdata((prevState) => ({
    ...prevState, // Keep other form fields unchanged
    company: ""
  }));
};


const { theme, setTheme } = useContext(UserContext);

 
const handlesearch = async (items) => {
  console.log('items', items.stock);
  setsearchvalue(items.stock);
    const res = await FetchData(
      `stockbasedlist?q=${items?.isin}`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
  setFormdata((prevState) => ({
    ...prevState, // Keep other form fields unchanged
    company: res?.data?.data?.screenerData?.intIncInv 
  }));
    }


  setsearch(false);
};



useEffect(() => {
  const fetchStockData = async () => {
    const purificationlist = JSON.parse(localStorage.getItem('stockdata'));
    // console.log('stockData', stockData);
    // Filter stock data based on stock or stockCode

  
    const items = purificationlist && purificationlist?.filter((item) => {
      const stockName = item.stock?.replaceAll(' ', '').toLowerCase();
      const stockCode = item.stock_code?.replaceAll(' ', '').toLowerCase();
      const searchTerm = searchvalue.replaceAll(' ', '').toLowerCase(); // Remove spaces from searchvalue

      // Check if either stock name or stock code matches the search value
      return (
        stockName.includes(searchTerm) || stockCode.includes(searchTerm)
      );
    }).slice(0, 20);

    console.log('items', items);
    setQuery(items || []);
  };
  fetchStockData();
}, [searchvalue]);



  const inputField = (title, col = 6, name, type, value,onChange) => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

    return (
        <div className={`${theme ?'outlined-input':'outlined-input1'} col-lg-${col} col-md-${col} col-sm-12 my-2`}>
            <input
                className={`w-100 ${theme ? "dark-theme" : "light-theme"}`}
                type={type}
                name={name}
                value={value}
                max={type === "date" ? currentDate : undefined} 
                onChange={(e) => {
                  const inputValue = e.target.value;
                  var capitalizedValue = inputValue
                    .toLowerCase()
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                    onChange({ target: { name: e.target.name, value: capitalizedValue } });
              }}
                placeholder=" "
            />
            <label>{title}</label>
        </div>
    );
};


const handleInputChange = (e) => {
  const { name, value } = e.target;
  console.log('value', value);

  // Reset calculated value or any other state if needed

  // Update form data
  setFormdata(prevFormData => ({
      ...prevFormData,
      [name]: value
  }));

  // Validate Sell Date
  if (name === "sell") {
      const purchaseDate = new Date(formdata.purchase); // Get the purchase date
      const sellDate = new Date(value); // Get the sell date from the input

      // Check if the sell date is less than the purchase date
      if (sellDate < purchaseDate) {
          toast.error("Sell Date cannot be earlier than Purchase Date.");
          // Resetting sell date if it's invalid
          setFormdata(prevFormData => ({
              ...prevFormData,
              sell: '' // Optionally, you can just not set this if you want to keep the invalid value
          }));
      }
  }
};


const SelectOption = (title, col = 12, name, type, value) => {
  var options = []
  if (name == "calculate") {
      options = [
          "calculate",
      ]
  }

  return (
      <div className={`${theme ?'outlined-input':'outlined-input1'} col-lg-${col} col-md-${col} col-sm-12 my-2`}>
           <input
        className={`search-input  ${theme ? "searchitem" : "searchitemlight"} w-100 ` }
        type="search"
        value={searchvalue}
        onChange={(e) => {
          setsearchvalue(e.target.value);
          setsearch(e.target.value.length > 0);
        }}
        onKeyDown={(e) => handleKeyDown(e)}
        aria-label="Search"
        style={{
          border: "0.5px solid #ededed",
          width: isMobile ? "100%" : "",
        }}
      />
       {searchvalue && (
        <FaTimes
          className="clear-icon"
          onClick={handleClearSearch}
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        />
      )}
          <label htmlFor="test">{title}</label>
      </div>
  )
}



const Handlecalculate = () => {
  console.log('company',formdata.company);
  console.log('sharesowne',formdata.sharesowne);
  console.log('purchase',formdata.purchase);
  console.log('sell',formdata.sell);
  
  if(formdata.company && formdata.sharesowne && formdata.purchase && formdata.sell){
    const CalculateValue = ((formdata.sharesowne * formdata.company) / 100).toFixed(2);
    setCalculatevalue(CalculateValue)

    
  }
  else{
    setCalculatevalue('');
      toast.error("Please enter mandatory fields");
  }
 

  
}
  return (
    <div>
          <style>
        {`
          input[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
          }
        `}
      </style>
      
    <Navbar />

<ToastContainer />
<div
  className=" home-header"
  style={{ width: "90%", margin:'auto' }}
>
  <div className="row mobilemarign padding-header" style={{paddingBottom:'7%'}} >
    <div className="col-lg-6 col-md-6 mt-2">
      <div
        className="card p-4 pt-2 cardwidth "
        style={{ alignItems: "center" ,backgroundColor: theme?'#1B1B1B':'#fff',width:'95%',borderRadius:'10px'}}
      >
        <div>
          <div className="mt-2 mb-3">
     <span className={`fs-4 mobile-font ${theme ?'text-white':'text-black'}`}><span style={{color:'#7E66BC'}}>Purification</span> Calculator</span>
          </div>

          <p className=""  style={{color:'#9D9D9D',fontWeight:'400',lineHeight:'25px' ,fontSize:'16px',fontFamily:'Poppins, sans-serif'}}>
          Earnings from Shariah-compliant stocks may still include non-permissible income that needs to be purified. Use our Shariah Purification Calculator to determine the amount to purify, ensuring your wealth remains clean, ethically sound, and in accordance with Islamic values.
          </p>
          {/* <button
            className="btn text-white btn-sm"
            style={{ backgroundColor: "#009000",borderRadius:'20px' ,fontFamily:'Poppins, sans-serif'}}
          >
        Learn more
          </button> */}
        </div>
        <div className='row w-100 mt-3 p-0'>


            <div className={`col-md-12 col-lg-12 ${theme ? 'outlined-input':'outlined-input1' }`}  style={{fontFamily:'Poppins, sans-serif',fontSize:'14px'}}>
                {/* <label className='text-white'>company (Ticker or Name)</label>
                <input type="text" className="form-control pt-2 pb-2" name="Enter Company Name or Ticker" /> */}
                {/* {SelectOption("Company (Ticker or Name)", 12, "company", "text", formdata.company ,handleInputChange)} */}
                {/* <div className="d-flex">
                <input
                  className={`search-input ms-2 me-2 ${theme ? "navsearchheader1" : "navsearchheaderlight"}`}
                  type="search"
                  value={searchvalue}
                  onChange={(e) => {
                    setsearch(true);
                    if (e.target.value.length === 0) {
                      setsearch(false);
                    }
                    setsearchvalue(e.target.value);
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                  placeholder="Search Stocks"
                  aria-label="Search"
                  style={{
                    border: "0.5px solid #ededed",
                    width: isMobile ? "100%" : "",
                  }}
                />
              </div> */}
         
              
   
                     {SelectOption("Company (Ticker or Name)", 12, "company", "text", formdata.company)}

    {searchvalue  && 
              <div
                className={`${theme?'':'card'}`}
                style={{
                  position: "absolute",
                  top: '100%',  // Place it directly below the input
                  left: 0,  // Align the left edge with the search bar
                  width: '100%',  // Match the width of the input
                  display: search ? "block" : "none",
                  zIndex: 999,
                }}
              >
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <div
                    className="w-100"
                    style={{
                      maxHeight: '300px',
                      overflowY: 'auto',
                      fontSize: 14,
                      backgroundColor: theme ? "rgb(51, 51, 51)" : "transparent"
                    }}
                  >
                    {query.length > 0 ? (
                      query.map((item, index) => (
                        <div
                          key={index}
                          ref={(el) => (itemRefs.current[index] = el)}  // Assign each item ref
                          onClick={() => handlesearch(item)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: selectedIndex === index ? '#8871c0' : theme ? 'rgb(51, 51, 51)' : 'transparent',
                            color: selectedIndex === index ? 'white' : theme ? "white" : 'black',
                            cursor: 'pointer',
                            borderBottom: theme?'1px solid #424242':'1px solid #ddd',
                          }}
                        >

                          {/* Container to distribute space evenly between stock_code and stock */}
                          <div className="w-50">{item.stock}</div>
                        </div>

                      ))
                    ) : (
                      <div className={`text-center`}>
                        <p className={`fs-6 fw-bold ${theme ? 'text-white' : 'text-dark'}`}>No Company found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
}
                   
                        {/* {inputField("Company (Ticker or Name)", 12, "company", "text", formdata.company,handleInputChange)} */}
            </div>
            <div className='col-md-12 col-lg-12 mt-4'  style={{fontFamily:'Poppins, sans-serif',fontSize:'14px'}}>
            {inputField("Profit / Dividend Amount", 12, "sharesowne", "text", formdata.sharesowne ,handleInputChange)}
      
            </div>  
            <div className='col-md-12 col-lg-12 d-flex justify-content-between mt-4'>
            <div className='col-md-6 col-lg-6 ' style={{width:'48%',fontFamily:'Poppins, sans-serif',fontSize:'14px'}}>
                {inputField("Purchase Date", 12, "purchase", "date", formdata.purchase ,handleInputChange)}
                </div>
                <div className='col-md-6 col-lg-6 text-white'  style={{width:'48%',fontFamily:'Poppins, sans-serif',fontSize:'14px'}}>
                {inputField("Sell Date", 12, "sell", "date", formdata.sell ,handleInputChange)}

                </div>
            </div>
        </div>
        <div className='w-100 mt-5  mb-5 text-center'>
               
                <button className='btn w-50 widthbutton text-white pt-2 pb-2'  style={{ backgroundColor: "#7E66BC"  }} onClick={()=>{Handlecalculate()}}>Calculate</button>
            </div>

       
        
      </div>
    </div>
    <div className="col-lg-6 col-md-6 herocard mt-2">
    <div className={`card p-4 ${theme?'text-white':'text-black'}`}
      style={{ alignItems: "center" ,backgroundColor:theme?'#1B1B1B':'#fff',borderRadius:'10px'}}>
        <div className="card-body text-center mt-5 pb-5 pt-5" style={{border:theme?'1px solid #ffff':'1px solid black',paddingLeft:'20%',paddingRight:'20%',borderRadius:'10px'}} >
            <div className='p-1'>
            <div className='widthbutton' style={{fontSize:'20px'}}>Total Purification Amount</div>
            {calculatevalue ?
                   <div className='mt-5 font-bolder fs-3' style={{color:theme?'#ffff':'black'}}>
                  {calculatevalue} INR
               </div>
            :
            <div className='mt-5' style={{color:theme?'#ffff':'black'}}>
                - -
            </div>
}
            </div>
            
            
        </div>

        <div className='col-md-12 col-lg-12 mt-5 mb-5 text-center'  style={{width:'100%',fontSize:'16px'}}>
        <div>
              <p className={`${theme ? 'text-white':'text-dark'} fw-bold`}>However, we recommend donating 2.5% of your earnings to purify your wealth and eliminate any possibilities of non-compliance income, ensuring your investments remain compliant always.</p>
      
            </div>
            </div>

    </div>
    
  
    </div>  
  </div>
</div>

<Footer />
    </div>
  )
}

export default Purificationcaluculator