import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../footer/Footer";
import FetchData from "../../fetchapi/Apifetch";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Alert } from "bootstrap";
import { UserContext } from "../../Utills/UserContext";
import News from "../Detailpage/News";
import "./whithscreen.css";
import Blogs from "../Detailpage/Blogs";
const Whitedashboard = () => {
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("login"));
    if (!token) {
      navigate("/");
    }
  }, []);


  const { theme, setTheme } = useContext(UserContext);

  // const fetchStockData = async (data) => {
  //   const stockData = JSON.parse(localStorage.getItem('Industry'));
  //   const items = stockData?.data?.find((item) => item._id === data );
  //   // setQuery(items || []);
  //   return
  // };

    const [isExpanded, setIsExpanded] = useState(false);
    const [visibleItems, setVisibleItems] = useState(12); // Show only 3 items initially
  
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
      setVisibleItems(isExpanded ? 12 : industry.length); // Toggle between showing 3 or all items
    };

  const [search, setsearch] = useState(false);
  const [searchvalue, setsearchvalue] = useState('');
  const [query, setQuery] = useState([]);

  const [Stockscreener, setStockscreener] = useState("");
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [filteredItems, setfilteredItems] = useState("");
  const [imageError, setImageError] = useState(false);

  const [Stocklist, setStocklist] = useState("");
  const [category, setCategory] = useState("");
  const [industry, setindustry] = useState('');


  // const stockvalueData = JSON.parse(localStorage.getItem('stockdata'));

  // Step 2: Create a Map to hold the unique industry_group values
  // const uniqueIndustryGroupsMap = new Map();

  // Step 3: Iterate over the stock data and add unique industry_group objects to the Map
  // stockvalueData.forEach(stock => {
  //   if (!uniqueIndustryGroupsMap.has(stock.industry_group)) {
  //     uniqueIndustryGroupsMap.set(stock.industry_group, stock);
  //   }
  // });

  // Step 4: Convert the Map back to an array of unique objects
  // const uniqueIndustryGroupsArray = Array.from(uniqueIndustryGroupsMap.values());


  useEffect(() => {
    const fetchStockData = async () => {
      const stockData = JSON.parse(localStorage.getItem('stockdata'));

      // Filter stock data based on stock or stockCode
      const items = stockData?.data?.filter((item) => {
        const stockName = item.stock?.replaceAll(' ', '').toLowerCase();
        const stockCode = item.stock_code?.replaceAll(' ', '').toLowerCase();
        const searchTerm = searchvalue.toLowerCase();

        // Check if either stock name or stock code matches the search value
        return (
          stockName.includes(searchTerm) || stockCode.includes(searchTerm)
        );
      }).slice(0, 20);
      setQuery(items || []);
    };

    fetchStockData();
  }, [searchvalue]);

  const navigate = useNavigate();

  const handleRowClick = (item) => {
    navigate("/stock", { state: { data: item?.screenerData?.isin } });
  };

  const NextPage = () => {
    setPage(page + 1);
  };

  const PreviousPage = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    Stockscreenerlist();
  }, [page]);


  const handlesearch = async (items) => {
    setsearchvalue('');
    setsearch(false);
    navigate("/stock", { state: { data: items.isin } });
  };


  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.screenerData.stock;

    const splitStock = (stock) => {
      if (!stock) return '';

      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase(); // Convert to uppercase if needed
    };

    return (
      <div>
        {!imageError ? (
          <img
            className=""
            src={item?.stockData?.img || ""}
            alt="img"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              border: "0.5px solid #fff",
            }}
            onError={() => setImageError(true)}
          />
        ) : (
          <div
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              border: "0.5px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 500,
              backgroundColor: "#e6e6e6b0",
              fontSize: "16px",
              // fontWeight: "bold",
            }}
          >
            {splitStock(item?.screenerData?.stock)}
          </div>
        )}
      </div>
    );
  };
  const DropdownImage = ({ item }) => {
    const [imageError1, setImageError1] = useState(false);
    const stock = item?.stock;

    const splitStock1 = (stock) => {
      if (!stock) return '';

      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase(); // Convert to uppercase if needed
    };

    return (
      <div>
        {!imageError1 ? (
          <img
            className=""
            src={item?.img || ""}
            alt="img"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              marginLeft: '10%',
              border: "0.5px solid #fff",
            }}
            onError={() => setImageError1(true)}
          />
        ) : (
          <div
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              border: "0.5px solid #fff",
              display: "flex",
              alignItems: "center",
              marginLeft: '10%',
              justifyContent: "center",
              fontWeight: 500,
              backgroundColor: "#e6e6e6b0",
              fontSize: "16px",
              // fontWeight: "bold",
            }}
          >
            {splitStock1(item?.stock)}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {


    fetchData3();
  }, []);

  const fetchData3 = async () => {
    const res = await FetchData(
      `industrytasis`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setindustry(res.data)
    }
  };


  const Stockscreenerlist = async () => {
    // const res = await FetchData(`get/stockscreener?page=${page}&limit=12`, 'GET', null, true, false)
    const res = await FetchData(`catstocks`, "GET", null, true, false);
    if (res) {
      setStockscreener(res.data);
      const firstItem = res.data?.length > 0 ? res.data[0] : null;
      setCategory(firstItem._id)
      setfilteredItems(firstItem.stockall);

      setPaginationInfo(res.data?.pagination);
    }
  };

  const getButtonColor = (Compliance) => {
    switch (Compliance) {
      case "Halal":
        return "#dfffd6";

      case "Haram":
        return "#ffe7d6";
      case "Doubtful !":
        return "#f6f7b0";
    }
  };
  const handlestockvalue = (value) => {
    setCategory(value);
    const Items = Stockscreener?.find((item) => item?._id == value);
    setfilteredItems(Items.stockall);
  };
  const stocks = async () => {
    try {
      const res = await FetchData("stocklist", "GET", null, true, false);
      if (res) {
        const searchstock = res.data.data;
        const ALLSTOCK = JSON.stringify(searchstock);
        localStorage.setItem("stockdataALL", ALLSTOCK);
        // setStocklist(searchstock);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const PaginationIndex = () => {
    return (
      <div className="mx-auto my-4">
        <nav aria-label="Page navigation example">
          <ul className="pagination" style={{ marginLeft: "10px" }}>
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={PreviousPage}>
                Previous
              </button>
            </li>
            {paginationNumbers().map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${page === pageNumber ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => setPage(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${page === paginationInfo?.totalPages ? "disabled" : ""
                }`}
            >
              <button className="page-link" onClick={NextPage}>
                Next
              </button>
            </li>
          </ul>
        </nav>
        <div className="pagination my-2" style={{ marginLeft: "17px" }}>
          <p>Total Records: {paginationInfo?.totalItems}</p>
          <br />
          <p style={{ paddingLeft: "10px" }}>
            Total Pages: {paginationInfo?.totalPages}
          </p>
        </div>
      </div>
    );
  };
  const paginationNumbers = () => {
    const totalPages = paginationInfo?.totalPages || 0;
    const currentPage = page;
    const paginationRange = 10;
    const paginationStart = Math.max(
      1,
      currentPage - Math.floor(paginationRange / 2)
    );
    const paginationEnd = Math.min(
      totalPages,
      paginationStart + paginationRange - 1
    );
    const numbersToShow = [];
    for (let i = paginationStart; i <= paginationEnd; i++) {
      numbersToShow.push(i);
    }
    return numbersToShow;
  };

  const [activeButton, setActiveButton] = useState(0);
  const profiledetails = JSON.parse(localStorage.getItem("email"));
  return (
    <>
      <div className=" home-header" style={{ width: "95%", margin: "auto" }}>
        <div className="row padding-header ">


          <div className="col-lg-3 col-md-4 col-sm-6 pl-1">
            <div
              className="card mt-3 px-0"
              style={{ backgroundColor: "#fff", color: "#000000" }}
            >
              <div className="p-3">
                <div className="mb-0 profile-name">
                <span  >Assalamu Alaikum,</span>{" "}
                  
                  <span style={{ color: "#7E66BC" }}>
                    <span>{profiledetails.first_name}</span> <span>{profiledetails.last_name}</span>!
                  </span>
                </div>

                {/* <p className="fonttext profile-desc">
                  Get a Shariah compliance overview of your entire portfolio and view
                  purification amount.
                </p> */}
                <p className="fonttext profile-desc">
                Welcome back to <span className="fw-bold">
  Islamic Tijarat<sup style={{ fontSize: "0.6em"}}>TM</sup>
</span>
                 —your trusted companion in ethical investing. May every step you take be filled with Barakah, and may your investments always flourish with integrity and peace of mind.
                </p>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-center">
              
                {/* <div className="text-center mb-3 mb-md-0">
                  <img
                    className="mt-1 image-icon"
                    src="./images/trade.png"
                    alt="img"

                  />
                </div>
                <div className="text-center mt-4 mt-md-0">
                  <p

                    className="text-center mb-0 m-1 fontsherian"
                  >
                    % SHARIAH Compliant
                  </p>
                </div> */}
              </div>
              <hr />
              <div style={{height:"530px",borderRadius: "10px" }}>
                <Blogs themes={theme} />
              </div>
            </div>
          </div>
          <div
            className="col-lg-9 col-md-8 herocard  mt-0 p-3 "
            
          >
            {/* <input type='text' placeholder='Search stock & EFTs'className='w-100 px-3 search-box inputfield'/> */}
            <div className="mt-0 justify-content-center mb-2 ">
              {/* <div className="mt-2">
               <h5><span style={{color:'#7E66BC'}}>Get a high profits while trading in ISLAMIC TIJARATH !</span></h5>
              </div> */}
              {/* <h5 className="mb-4 ms-2"><span style={{color:'#7E66BC'}}>Get a high profits while trading in ISLAMIC TIJARATH !</span></h5> */}

              {/* <div className="d-flex">
                
              <input
                className="form-control ms-2 me-2 navsearch1"
                type="search"
                value={searchvalue}
                onChange={(e) => {
                  setsearch(true);
                  if (e.target.value.length == 0) {
                    setsearch(false);
                  }
                  setsearchvalue(e.target.value)
                  // Stockscreenerlist(e.target.value);
                }}
                placeholder="Search Stocks"
                aria-label="Search"
                style={{ border: "2px solid #ededed" ,borderRadius:'15px'}}

              />
            </div> */}
              {/* <div
              className="card P-3"
              style={{
                position: "absolute",
                top: '15%',
                right: "3%",
                width: "68vw",
                display: search ? "block" : "none",
                zIndex: 999,
              }}
            >
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <div
                  className="w-100"
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    fontSize: 14,
                  }}
                >
                  <table
                    className="table table-striped"
                    style={{ cursor: "pointer" }}
                  >
                    <thead>
                      <tr className="fs-6">
                        <th scope="col"></th>
                        <th scope="col">Stock</th>
                        <th scope="col">Industry group</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                    {query.length > 0 ? (
                 query.map((item, index) => (
                          <tr key={index} onClick={() => handlesearch(item)}>
                            <td>
                            <DropdownImage item={item}  />
                             
                            </td>
                            <td className="" >
                            <p className="fw-bolder mb-0">{item.stock}</p>
                            <span className="m-0" style={{color:'gray',fontSize:'12px'}}>{item.stock_code}</span>
                            </td>
                            <td className="" >
                            <span className="m-0" style={{color:'gray',fontSize:'12px'}}>{item.industry_group}</span>
                            </td>
                           
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-center">
                            <p className="fs-6 fw-bold">No Stocks found</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                 
                </div>
              </div>
            </div> */}
            </div>

            <div className="row g-2">
              {/* Stock Screener Card */}
              <div className="col-12 col-md-6 col-lg-3">
                <div
                  className="card py-3 border-0 text-white scrennercard1"
                  onClick={() => {
                    navigate("/screener");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex px-2 justify-content-between align-items-center flex-row">
                    <div className="p-2">
                      <h4 className="p-0 fw-bold m-0" style={{ fontSize: "18px" }}>
                        STOCK
                      </h4>
                      <p className="para p-0" style={{ fontSize: "16px" }}>
                        Screener
                      </p>
                    </div>
                  </div>
                  <div className="img-circle2 bgcard1 d-flex align-items-center">
                    <img
                      className="mx-auto img-fluid"
                      src="/images/card1t.png"
                      alt="img"
                      style={{ height: "22px", width: "22px" }}
                    />
                  </div>
                  <div className="px-3 d-flex mt-2 justify-content-between">
                    <div className="mt-2">
                      <p style={{ fontSize: "10px" }}>Only 25% out of 100%</p>
                    </div>
                    <div>
                      <img
                        className="mx-auto img-fluid"
                        src="/images/card1wt.png"
                        alt="img"
                        style={{ height: "30px", width: "27px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* IPO Screener Card */}
              <div className="col-12 col-md-6 col-lg-3">
                <div
                  className="card py-3 border-0 iopcard"
                  onClick={() => {
                    navigate("/iposcreen");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex px-2 justify-content-between text-white align-items-center flex-row">
                    <div className="p-2">
                      <h4 className="p-0 fw-bold m-0" style={{ fontSize: "18px" }}>
                        IPO
                      </h4>
                      <p className="para p-0" style={{ fontSize: "16px" }}>
                        Screener
                      </p>
                    </div>
                    <div className="img-circle2 bgcard2 d-flex align-items-center">
                      <img
                        className="mx-auto img-fluid"
                        src="/images/card2t.png"
                        alt="img"
                        style={{ height: "22px", width: "22px" }}
                      />
                    </div>
                  </div>
                  <div className="px-3 d-flex mt-2 justify-content-between text-white">
                    <div className="mt-2">
                      <p style={{ fontSize: "10px" }}>Only 25% out of 100%</p>
                    </div>
                    <div>
                      <img
                        className="mx-auto img-fluid"
                        src="images/card2wt.png"
                        alt="img"
                        style={{ height: "35px", width: "32.5px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Purification Calculator Card */}
              <div className="col-12 col-md-6 col-lg-3">
                <div
                  className="card py-3 border-0 purificationcard"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = "/purification";
                  }}
                >
                  <div className="d-flex px-2 justify-content-between align-items-center flex-row text-white">
                    <div className="p-2">
                      <h4 className="p-0 fw-bold m-0" style={{ fontSize: "18px" }}>
                        PURIFICATION
                      </h4>
                      <p className="para p-0" style={{ fontSize: "16px" }}>
                        Calculator
                      </p>
                    </div>
                    <div className="img-circle2 bgcard3 d-flex align-items-center">
                      <img
                        className="mx-auto img-fluid"
                        src="/images/card3t.png"
                        alt="img"
                        style={{ height: "22px", width: "22px" }}
                      />
                    </div>
                  </div>
                  <div className="px-3 d-flex mt-2 justify-content-between text-white">
                    <div className="mt-2">
                      <p style={{ fontSize: "10px" }}>Only 25% out of 100%</p>
                    </div>
                    <div>
                      <img
                        className="mx-auto img-fluid"
                        src="/images/card3wt.png"
                        alt="img"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Zakat Calculator Card */}
              <div className="col-12 col-md-6 col-lg-3">
                <div
                  className="card py-3 border-0 zakatcard"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = "/zakat";
                  }}
                >
                  <div className="d-flex px-2 justify-content-between align-items-center flex-row text-white">
                    <div className="p-2">
                      <h4 className="p-0 fw-bold m-0" style={{ fontSize: "18px" }}>
                        ZAKAT
                      </h4>
                      <p className="para p-0" style={{ fontSize: "16px" }}>
                        Calculator
                      </p>
                    </div>
                    <div className="img-circle2 bgcard4 d-flex align-items-center">
                      <img
                        className="mx-auto img-fluid"
                        src="/images/card4t.png"
                        alt="img"
                        style={{ height: "22px", width: "22px" }}
                      />
                    </div>
                  </div>
                  <div className="px-3 d-flex mt-2 justify-content-between text-white">
                    <div className="mt-2">
                      <p style={{ fontSize: "10px" }}>Only 25% out of 100%</p>
                    </div>
                    <div>
                      <img
                        className="mx-auto img-fluid"
                        src="/images/card3wt.png"
                        alt="img"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-12 d-flex col-md-12 ">
         
                <div className="col-lg-8 col-sm-12 col-md-8 me-2 mt-3">
                <h5 className="font-table-head">
                      <span >Trending </span>
                      <span style={{ color: "#7E66BC" }} >Compliant Stocks</span>
                    </h5>
                  <div className="d-flex justify-content-between mb-1">
                 
                    {/* <Link to="/stockpage">
                      <button
                        className="btn  text-dark fontview "
                    
                      >
                        View All{" "}
                        <i
                          class="fa-solid fa-arrow-right text-dark"
                          style={{
                            color: "black",
                            marginLeft: "5px",
                            fontSize: "10px",
                          }}
                        ></i>{" "}
                      </button>
                    </Link> */}
                  </div>
                  <div style={{ border: '1px solid #e0e3eb', borderRadius: '10px',backgroundColor:"#fff" }}>
                    <div className="ms-3 mt-2 text-dark tablehead-font">
                      Recently Added
                    </div>
                    <div className="d-flex justify-content-start p-2 heder-title1">

                      <div className="d-flex"
                        style={{
                          overflowX: 'auto',  // Enable horizontal scrolling
                          whiteSpace: 'nowrap', // Prevent content wrapping
                          padding: '5px 0', // Minimal padding for a compact layout
                        }}>
                        {Stockscreener && Stockscreener.map((button, index) => (
                          <div className="p-1 me-2 seleectvalue"
                            style={{
                              background: category === button._id
                                ? 'linear-gradient(135deg, #7E66BC, #C99DE9)' // Gradient for selected button
                                : 'linear-gradient(135deg, #F0F0F5, #FFF)', // Subtle gradient for unselected buttons
                              border: category === button._id
                                ? '1px solid #7E66BC'
                                : '1px solid #D3D3D3',
                              color: category === button._id ? '#fff' : '#333', // White text for selected, dark for unselected
                              borderRadius: '8px', // Soft rounded corners
                              padding: '5px 15px', // Reduced padding for compactness
                              cursor: 'pointer', // Indicate clickability
                              boxShadow: category === button._id
                                ? '0 2px 6px rgba(126, 102, 188, 0.5)'  // Slightly stronger shadow for selected
                                : '0 1px 3px rgba(0, 0, 0, 0.1)', // Subtle shadow for unselected
                              transition: 'all 0.2s ease', // Smooth transitions for hover effects
                              whiteSpace: 'nowrap', // Prevent text wrap
                            }}
                            onClick={() => handlestockvalue(button._id)}
                            onMouseEnter={(e) => e.target.style.transform = 'scale(1.03)'}
                            onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                          >
                            {button.categoryname.charAt(0).toUpperCase() + button.categoryname.slice(1)}
                          </div>
                        ))}
                      </div>

                    </div>
                    <div className="table-responsive" style={{height:'515px'}}> {/* This div makes the table scrollable on smaller screens */}
                      <table
                        className="table mt-0"
                        style={{ textAlign: 'start', overflow: 'hidden' }}>
                        <thead>
                          <tr
                            style={{ textAlign: 'start', fontSize: "14px" }}>
                            <th scope="col" className="text-secondary bg-transparent font-wight w-50">Name</th>
                            <th scope="col" className="text-secondary bg-transparent font-wight w-25 text-center">Industry</th>
                            <th scope="col" className="text-secondary bg-transparent font-wight">Compliance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(filteredItems) && filteredItems.map((item, index) => (
                            <tr
                              key={index}
                              className="mx-auto"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleRowClick(item)}
                            >
                              <th scope="row" className="w-50">
                                <div className="d-flex justify-content-start">
                                  <Imageview item={item} />
                                  <div className="">
                                    <h3 className="mb-0" style={{ padding: '0px 10px', fontSize: '14px' }}>
                                      {item?.stockData?.stock_code || ""}
                                    </h3>
                                    <p className="mb-0" style={{ padding: '0px 10px', fontSize: '12px', color: '#ACACAC' }}>
                                      {item?.screenerData?.stock || ""}
                                    </p>
                                  </div>
                                </div>
                              </th>
                              <td className="heder-title text-center w-50">
                                {item?.screenerData?.industry_group || ""}
                              </td>
                              {item?.screenerData?.status == 1 ? (
                                <td className="heder-title">
                                  <div className="d-flex p-1 justify-content-center" >
                                    <span className="heder-title" style={{ color: '#218303', fontWeight: "500" }}>Compliant</span>
                                    <span className="ms-1"><img src="/images/checklist.png" width={20} height={20} ></img></span>
                                  </div>
                                </td>
                              ) : item?.screenerData?.status == 2 || item?.screenerData?.status == 4 || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ? (
                                <td className="heder-title">
                                  <div className="d-flex p-1 justify-content-center">
                                    <span className="heder-title" style={{ color: '#D43A3A', fontWeight: "500" }}>Non Compliant</span>
                                    <span className="ms-1"><img src="/images/non-permisable.png" width={20} height={20} ></img></span>
                                  </div>
                                </td>
                              ) : item?.screenerData?.status == 3 ? (
                                <td className="heder-title">
                                  <div className="d-flex p-1 justify-content-center" >
                                    <span className="heder-title" style={{ color: '#2132CA', fontWeight: "500" }}>Unknown</span>
                                    <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                                  </div>
                                </td>
                              ) :
                                (<td className="heder-title">
                                  <div className="d-flex p-1 justify-content-center">
                                    <span className="heder-title" style={{ color: '#EFA625', fontWeight: "500" }}>Under Review</span>
                                    <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                                  </div>
                                </td>
                                )
                              }
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-4 col-sm-12  col-md-4 ms-2 mt-5"
                  style={{ border: '1px solid rgb(209, 209, 209)', borderRadius: '10px',backgroundColor:"#fff"}}>
                  < News themes={theme} />

                </div>
              </div>
   
    </div>
    
         </div>    
  </div>
  <div className="row">
  <div className="container mt-4 col-lg-12 col-md-12 col-sm-12">
           <span className="ms-2 fs-5 text-dark">Stocks by <span style={{ color: "#7E66BC" }}>categories</span></span>
             <div
           className="d-flex justify-content-start align-items-center mt-3"
            style={{
             flexWrap: 'wrap',
             padding: '10px 0',
             whiteSpace: 'nowrap',
             scrollBehavior: 'smooth',
             }}
            >
        {industry && industry.slice(0, visibleItems).map((category, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#fff',
              border:'1px solid #dfdfec',
              width: 'fit-content',
              borderRadius: '5px',  
              margin: '10px 10px',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 8px 0px',
              cursor: 'pointer',
              transition: 'transform 0.3s',
            }}
            className="p-2 d-flex flex-row align-items-center category-card"
            onClick={() => { navigate('/industry', { state: { value: category } }); }}
          >
            <div className="img-circle3-black d-flex align-items-center justify-content-center">
              <img
                src={category.icon || '/images/icons/card1.png'}
                alt="img"
                style={{ height: '15px', width: '16px' }}
              />
            </div>
            <div className="text-black text-center  ms-2" style={{fontSize:'12px'}}>{category.industry_group  }</div>
          </div>
        ))}
      </div>

      {/* Toggle Button */}
      <div className="mt-3 d-flex justify-content-end">
        <button onClick={handleToggle} className={`btn `} style={{color:theme?"#fff":"black",fontSize:'14px'}}>
        {isExpanded ? (
      <>
        See Less Categories<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
</svg>
      </>
    ) : (
      <>
        See More Categories <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
      </>
    )}
        </button>
      </div>
         </div>
  </div>
          </div>
    
    </>
  );
};

export default Whitedashboard;
