import React ,{useState , useContext,useEffect} from 'react';
import "./css/login.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import FetchData from "../fetchapi/Apifetch";
import { UserContext } from '../../components/Utills/UserContext';
import config from '../Utills/ApiRoute';
import { json, useLocation, useNavigate } from "react-router-dom";
const Register = () => {
  const navigate = useNavigate();
    const [buttonclick, setbuttonclick] = useState(false);
    const { theme, setTheme } = useContext(UserContext);
    const [canUpdateImage, setCanUpdateImage] = useState(true);
    const [profilePhoto, setProfilePhoto] = useState(null);

    const [profiledetails1, setProfileDetails] = useState({
      img:""
    });
    const [profilePhotoremove, setProfilePhotoremove] = useState(null);
    const validateForm = (data) => {
        var newErrors = {};
        for (const key in data) {
          const value = data[key];
          const trimmedValue = value && typeof value === 'string' ? value.trim() : value;
          if (!trimmedValue) {
            newErrors[key] = `${key} is required`;
          }
        }
        return Object.keys(newErrors).length === 0;
      };
      const [formdata, setFormdata] = useState({
        first_name: "",
        last_name: "",
        email: "",  
        password:"",
        status:'1',
        img:profiledetails1?.img,
        otpstatus:false,
        userstatus:false,
        goglelogin:false
      });
      
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormdata({ ...formdata, [name]: value });
      };
      const handleFileChange = (e) => {
        setProfilePhoto(e.target.files[0]);
        setCanUpdateImage(false);
        setProfilePhotoremove(e.target.files[0])
      };


      const Listcompliant = async (item) => {
        const formData = new FormData();
        formData.append('mediaUrls', profilePhoto);
        try {
          const token = false;
          const res = await FetchData("uploadimages", 'POST', formData, token,true );
          if (res.success) {
            setProfileDetails({...profiledetails1,["img"]:config.BASE_URL+"/"+res.url})
            setFormdata({...formdata,["img"]:config.BASE_URL+"/"+res.url})
            setProfilePhotoremove(res.url)

          } else {
            console.error("Please Check Crediential")
          }
          return null;
        } catch (error) {
          console.error('Error:', error);
          return null;
        }
      }


      const RemoveImage = async (item) => {
        try {
          const token = false
          const res = await FetchData("removeimage", 'POST',JSON.stringify({image:profilePhotoremove}), token,false );
          if (res.success) {
            setProfileDetails({...profiledetails1,["img"]:""})
            setCanUpdateImage(true);
          } else {
            console.error("Please Check Crediential")
          }
          return null;
        } catch (error) {
          console.error('Error:', error);
          return null;
        }
      }


      useEffect(() => {
        Listcompliant(profilePhoto)
        }, [profilePhoto])

    const AddData = async () => {
        setbuttonclick(true)
        try {
          const token = false;

          const res = await FetchData("add/user", 'POST', JSON.stringify(formdata), token, false);
          if (res.success) {
            toast.success("User Data Stored Successfully")
            navigate("/otpverification", { state: { data:formdata.email  } });
            setFormdata({ 
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                status: '1',
                img:''
              });
           
          } else {
            toast.error("Please Check Crediential")
            setbuttonclick(false)
          }
          return null;
        } catch (error) {
          console.error('Error:', error);
          toast.error(error.message)
          setbuttonclick(false)
          return null;
        }
      }
  return (
    <>
     <div className={`container-fluid mt-4 ${theme?'text-white':'text-black'}`} style={{width:"80%",border:"1px solid #ededed"}}>
    <ToastContainer />
        <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-12  d-flex align-items-center bg-white'>
        <div className='p-4 w-100'>
            <div className='mb-2'>
        <h3 className=''>Join Islamic Tijarat</h3>
        <p  className='text-secondary'>Sign up with email</p>
        </div>
      {/* <form> */}
      {/* <div class="mb-3 mt-3 ">
    <label for="exampleInputEmail1" class="form-label">Profile</label>
    <input type="file" class="form-control" name="image" id="exampleInputEmail1" value={formdata?.} aria-describedby="emailHelp" onChange={(e)=>{handleInputChange(e)}} />
  </div> */}
  <div className="mb-3 mt-0 text-center">
            <div style={{
              position: 'relative',
              cursor: canUpdateImage ? 'pointer' : 'not-allowed',
              display: 'inline-block'
            }}>
              <img
                src={profiledetails1?.img ? profiledetails1?.img : "/images/user.png"}
                alt="Profile"
                style={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  border: theme ? '2px solid #7e66bc' : '2px solid #ccc'
                }}
                onClick={() => canUpdateImage && document.getElementById('profilePhoto').click()}
              />
              <input
                type="file"
                id="profilePhoto"
                className="form-control"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                disabled={!canUpdateImage}
              />
              {profiledetails1?.img && !canUpdateImage && (
                <button
                  type="button"
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '-15px',
                    background: '#ff4d4d',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                  }}
                  onClick={() => { RemoveImage() }}
                >
                  X
                </button>
              )}
            </div>
          </div>
      <div class="mb-3 mt-2 ">
    <label for="exampleInputEmail1" class="form-label">First Name</label>
    <input type="email" class="form-control" name="first_name" id="exampleInputEmail1" value={formdata?.first_name} aria-describedby="emailHelp" onChange={(e)=>{handleInputChange(e)}} />
  </div>
  <div class="mb-3 mt-3 ">
    <label for="exampleInputEmail1" class="form-label">Last Name</label>
    <input type="email" class="form-control" name="last_name" value={formdata?.last_name} id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>{handleInputChange(e)}} />
  </div>
  <div class="mb-3 mt-3 ">
    <label for="exampleInputEmail1" class="form-label">Email</label>
    <input type="email" class="form-control" name="email" value={formdata?.email} id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>{handleInputChange(e)}} />
  </div>
  <div class="mb-3">
    <label for="exampleInputPassword1" class="form-label">Password</label>
    <input type="password" class="form-control" value={formdata?.password} name="password" id="exampleInputPassword1" onChange={(e)=>{handleInputChange(e)}} />
  </div>
  
  <button  class="btn btn-primary w-100" disabled={buttonclick} style={{backgroundColor:"#208203"}} onClick={()=>{AddData()}}>SIGN UP</button>
  <p className='text-center my-2'>Or <span className='text-primary'onClick={()=>{ window.location.href="/" }} >login</span></p>
     {/* <div className='d-flex'>
     <div className='card d-flex flex-row w-50 me-1 py-2 align-items-center justify-content-center btn-card'>
        <img src="images/icons/Google.png" alt='logo' style={{width:"21px",height:"22px"}} /> 
         <p className='mb-0 ms-1'>Google</p>
     </div>
     <div className='card d-flex flex-row w-50 py-2 align-items-center justify-content-center btn-card'>
        <img src="images/icons/meta.png" alt='logo' style={{width:"33px",height:"18.56px"}} /> 
         <p className='mb-0 ms-1'>Meta</p>
     </div>
     </div> */}
{/* </form> */}
    </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 p-0 img-layout1' >
        <img src="images/bg-image.jpg" alt='logo' style={{width:"100%",height:"100%"}} /> 
          <div className='text-overlay'>
          <img src="logonew.png" alt='logo' style={{width:"150px",height:"150px"}} /> 
          <img src={"images/app/itg-logo1.png"}  alt="Logo" style={{width:"280px",height:"60px"}} />
          {/* <h1 className='fw-bold'>Islamic Tijarath</h1> */}
          {/* <p className='paratext px-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim veniam, quis nostrud exercitation nisi ut aliquip ex ea commodo consequat. </p> */}
          {/* <div style={{margin:"auto 0px"}}>
          <p className='paratext mt-3'>Don’t have an account</p>
          <a className='paratext btn-card text-white'>Sign Up?</a>
          </div> */}
          </div>
        </div>
        </div>
    </div>
    </>
  )
}

export default Register
