// DisclaimerModal.js
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './DisclaimerModal.css'; // Import a CSS file for additional styles

const DisclaimerModal = ({ showModal, handleClose }) => {
    const [isChecked, setIsChecked] = useState(false); // State for the checkbox

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); 
    };

    const handleAgree = () => {
        if (isChecked) {
            handleClose(); // Close the modal if the checkbox is checked
        } else {
            alert("Please agree to the terms before proceeding.");
        }
    };

    return (
        <Modal show={showModal}  centered className="disclaimer-modal">
            <Modal.Header closeButton>
                <Modal.Title className="modal-title-value">Disclaimer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-value">
                <h5>Welcome to Islamic <span className="brand-color">Tijarat</span> <sup className="trademark">TM</sup></h5>
                <p>
                    Before proceeding, please read this disclaimer carefully. By using our website, you acknowledge that you have understood and agreed to the terms mentioned below.
                </p>
                {/* Rest of the disclaimer text */}
                <h6>Educational and Informational Purposes Only</h6>
                <p>
                    The content provided on Islamic Tijarat  is for <strong>educational and Shariah-awareness purposes</strong> only. The information, tools, and resources available on this platform are intended to offer guidance based on Shariah compliance standards. This information should not be considered as a recommendation, endorsement, or solicitation to engage in any form of trading, investment, or financial decision-making.
                </p>
                <h6>No Investment Advice</h6>
                <p>
                    Islamic Tijarat does not provide financial or investment advice. We do not recommend or advise the purchase, holding, or sale of any securities. Users are advised to seek independent financial advice from qualified professionals before making any investment decisions.
                </p>
                <h6 className='fw-bold'>Accuracy of Information</h6>
                <p>
                    While we strive to ensure that the information provided on our platform is accurate and up-to-date, <strong>Islamic Tijarat does not guarantee the accuracy, reliability, or completeness of the data displayed</strong>. The Shariah compliance status, financial ratios, and other metrics shown on the platform are subject to changes and may vary from the actual figures. Islamic Tijarat and its owners, partners, and associates are not responsible for any discrepancies or inaccuracies.
                </p>
                <h6 className='fw-bold'>No Liability</h6>
                <p>
                    Islamic Tijarat , its owners, affiliates, partners, and contributors will not be held liable for any losses, damages, or legal actions arising from the use of this platform. This includes, but is not limited to, investment losses, financial damages, or trading errors. All users are responsible for their own actions and decisions based on the information provided on the website.
                </p>
                <h6 className='fw-bold'>User Responsibility</h6>
                <p>
                    It is the responsibility of each user to verify the information presented and conduct their own research before making any decisions. By accessing and using Islamic Tijarat, you agree to release the platform and its affiliates from any and all legal liabilities associated with the use of the content or tools available.
                </p>
                <h6>Amendments and Updates</h6>
                <p>
                    Islamic Tijarat  reserves the right to amend, update, or change this disclaimer and other policies at any time without prior notice. Continued use of the platform constitutes acceptance of these changes.
                </p>
                <h6>Governing Law</h6>
                <p>
                    This disclaimer and any disputes arising out of or in connection with it shall be governed by and construed in accordance with the laws of <strong>Mumbai, India</strong>.
                </p>
                <p>
                    By proceeding to use this website, you agree to the above terms and conditions. If you do not agree, please exit the website immediately.
                </p>
                <p>
                    For any further clarifications or queries, feel free to reach out to us at:<br />
                    📧 <strong>info@islamictijarat.com</strong>
                </p>

                {/* Checkbox for agreeing to the terms */}
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="agreementCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="agreementCheckbox">
                        I have read and agree to the terms and conditions.
                    </label>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="primary" 
                    onClick={handleAgree} 
                    disabled={!isChecked} 
                    className="agree-button"
                >
                    I Agree
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DisclaimerModal;
