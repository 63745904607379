import "./App.css";
import Dashboard from "./components/zakat/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Login from "./components/Register/Login";
import Stock from "./components/pages/Stock";
import Relatednews from "./components/pages/Relatednews";
import {
  BrowserRouter as Router,Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import Profile from "./components/pages/Profile";
import StockPage from "./components/pages/StockPage";
import Screener from "./components/pages/Screener";
import Plan from "./components/Plans/Plan";
import Watchpage from "./components/watchpage/watchpage";
import Setting from "./components/Settingpage/Setting";
import Register from "./components/Register/Register";
import Purificationcaluculator from "./components/pages/Purificationcaluculator";
import Newdashboard from "./components/pages/Newdashboard";
import Newstock from "./components/pages/Newstock";
import Stock2 from "./components/pages/Stock";
import ZakatCalculator from "./components/zakat/ZakatCalculator";
import Whitedashboard from "./components/pages/whitetheam/whitedashboard";
import Bestscreenner from "./components/Bestscreenner/Bestscreenner";
import Zakatlight from "./components/pages/whitetheam/zakatlight/Zakatlight";
import Purifylight from "./components/pages/whitetheam/purify/Purifylight";
import Profilelight from "./components/pages/whitetheam/profilelight/Profilelight";
import Bestscreenstock from "./components/pages/whitetheam/Bestscreen/Bestscreenstock";
import { UserProvider, UserContext } from './components/Utills/UserContext';
import Forgotpassword from "./components/Register/Forgotpassword";
import Otpverification from "./components/Register/Otpverification";
import Newsettingpage from "./components/Settingpage/Newsettingpage";
import Iopscreen from "./components/pages/Iopscreen";
import Zakathcomingsoon from "./components/pages/ZakatCalculator";
import Chart from "./components/pages/Community/Chart";
import Industrybasestock from "./components/pages/Industrybasestock";
import Similarstock from "./components/pages/Similarstocks";
import Premium from "./components/pages/perimum"; 

function App() {
  // const token = JSON.parse(localStorage.getItem("login"));

  const Secure = (Component) => (
  <UserContext.Consumer>
    {({ user,theme }) => (user ?( 
      <div style={{backgroundColor:theme?'#171717':'#f6f6f6'}}>
        <Component /> 
      </div>
  ): <Navigate to="/" />)}
  </UserContext.Consumer>
  );  

  const InSecure = (Component) => (
  <UserContext.Consumer>
    {({ user,theme }) => (user ? <Navigate to="/dashboard" />: (
      <div style={{backgroundColor:theme?'#171717':'#f6f6f6'}}>
      <Component /> 
    </div>
    ))}
  </UserContext.Consumer>
  );

  const routes = [
    { path: "/dashboard1", element: Secure(Dashboard) },
    { path: "/", element: InSecure(Login) },
    { path: "/stock1", element: Secure(Stock) },
    { path: "/purification", element: Secure(Purificationcaluculator) },
    { path: "/Zakat", element: Secure(Zakathcomingsoon) },
    // { path: "/Zakat", element: Secure(ZakatCalculator) },
    { path: "/profile1", element: Secure(Profile) },
    { path: "/stockpage", element: Secure(StockPage) },
    { path: "/screener", element: Secure(Screener) },
    { path: "/relatednews", element: Secure(Relatednews) },
    { path: "/plan", element: Secure(Plan) },
    { path: "/ipo", element: Secure(Bestscreenner) },
    { path: "/iposcreen", element: Secure(Iopscreen) },
    { path: "/watchlist", element: Secure(Watchpage) },
    { path: "/profile", element: Secure(Setting) },
    { path: "/register", element: InSecure(Register) },
    { path: "/dashboard", element: Secure(Newdashboard) },
    { path: "/stock", element:Secure(Newstock)},
    { path: "/stock2", element:Secure(Stock2)},
    { path: "/dashboardlight", element:Secure(Whitedashboard) },
    { path: "/zakatlight", element:Secure(Zakatlight)},
    { path: "/purifylight", element:Secure(Purifylight)},
    { path: "/profilelight", element:Secure(Profilelight)},
    { path: "/ioplight", element:Secure(Bestscreenstock)},
    { path: "/userprofile", element:Secure(Newsettingpage)},
    { path: "/forgotpassword", element:InSecure(Forgotpassword)},
    { path: "/otpverification", element:InSecure(Otpverification)},
    // { path: "/community", element:Secure(Chart)},
    { path: "/industry", element:Secure(Industrybasestock)},
    { path: "/similarstock", element:Secure(Similarstock)},
    { path: "/premium", element:Secure(Premium)},
    
  ];
  return (
    <Router>
      <UserProvider>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
