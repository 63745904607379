import React, { useState, useEffect ,useRef} from "react";
import FetchData from "../fetchapi/Apifetch";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import "./style.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import { useLocation } from "react-router-dom";
import Profile from "./Profile";
import Relatednews from "./Relatednews";
import Fundemental from "./Detailpage/Fundemental";
import Techenical from "./Detailpage/Techenical";

// import { TradingViewEmbed, widgetType ,CompanyProfile} from 'react-tradingview-embed';
// // import { CompanyProfile } from 'react-tradingview-embed';

const Stock = () => {
  const location = useLocation();
  const widgetRef = useRef(null);
  const [loader, setloader] = useState(false);
  const [data, setData] = useState();

  const Stockdata111 = JSON.parse(localStorage.getItem("stockdataALL"));
  
  useEffect(() => {
  
    setData(location?.state?.value);
  },[location?.state?.value]);


  useEffect(() => {
    const fetchData = async () => {
      setloader(true)
      
      const res = await FetchData(
        `stockbasedlist?q=${location?.state?.data}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
     console.log('res',res.data)
        setData(res.data.data);
        setloader(false);
      }
    };
    if(location?.state?.data){
    fetchData();
    }
    console.log("hestock", location?.state);
  }, [location?.state?.data]);

  const [screen, setScreen] = useState("overview");
  const [Stockscreener, setStockscreener] = useState("");
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const Fixedvalue2 = (value) => {
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      const fixedValue = numberValue.toFixed(2);
      return fixedValue;
    } else {
      return ""; 
    }
  };
  
  const stockvalue = data?.stockData?.stock_code
  console.log('stockvalue',stockvalue)

  useEffect(() => {
    if (data) {
      const stockValue = data?.stockData?.stock_code;
      console.log('stockValue', stockValue);
      if (stockValue && widgetRef.current) {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
          "width": "100%",
          "height": "auto",
          "isTransparent": false,
          "colorTheme": "light",
          "symbol": stockValue,
          "locale": "en"
        });

        widgetRef.current.appendChild(script);

        return () => {
          widgetRef.current.innerHTML = '';
        };
      }
    }
  }, [data]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
      month: "short",
      day: "2-digit",
      hour: "numeric", 
      minute: "2-digit", 
      hour12: true, 
    };

    // Format the date
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    return formattedDate;
  };
  const NextPage = () => {
    setPage(page + 1);
  };

  const PreviousPage = () => {
    setPage(page - 1);
  };

  // useEffect(() => {
  //   Stockscreenerlist();
  // }, [page]);




  // const Stockscreenerlist = async () => {
  //   // const res = await FetchData(`get/stockscreener?page=${page}&limit=12`, 'GET', null, true, false)
  //   const res = await FetchData(
  //     `stocklist`,
  //     "GET",
  //     null,
  //     true,
  //     false
  //   );
  //   if (res) {
  //     // console.log(res.data);
  //     setStockscreener(res.data.data);
  //     setPaginationInfo(res.data.pagination);
  //   }
  // };



  
  const PaginationIndex = () => {
    return (
      <div className="mx-auto my-4">
        
   
        <nav aria-label="Page navigation example">
          <ul className="pagination" style={{ marginLeft: "10px" }}>
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={PreviousPage}>
                Previous
              </button>
            </li>
            {paginationNumbers().map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${page === pageNumber ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => setPage(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                page === paginationInfo?.totalPages ? "disabled" : ""
              }`}
            >
              <button className="page-link" onClick={NextPage}>
                Next
              </button>
            </li>
          </ul>
        </nav>
        <div className="pagination my-2" style={{ marginLeft: "17px" }}>
          <p>Total Records: {paginationInfo?.totalItems}</p>
          <br />
          <p style={{ paddingLeft: "10px" }}>
            Total Pages: {paginationInfo?.totalPages}
          </p>
        </div>
      </div>
    );
  };
  const paginationNumbers = () => {
    const totalPages = paginationInfo?.totalPages || 0;
    const currentPage = page;
    const paginationRange = 10;
    const paginationStart = Math.max(
      1,
      currentPage - Math.floor(paginationRange / 2)
    );
    const paginationEnd = Math.min(
      totalPages,
      paginationStart + paginationRange - 1
    );
    const numbersToShow = [];
    for (let i = paginationStart; i <= paginationEnd; i++) {
      numbersToShow.push(i);
    }
    return numbersToShow;
  };

  //  const { state } = useLocation()


  const filteredItems = Stockdata111
  ? Stockdata111
      .filter((item) => item?.screenerData.industry_group === data?.screenerData.industry_group)
      .filter((item) => item?.screenerData.stock !==  data?.screenerData.stock)
      .slice(0, 10)
  : [];


  return (
    <>
      <Navbar />

      <div style={{ marginTop: "5%" }} className="p-2">
    {loader &&( <div className="loader">
      <div className="spinner"></div>
    </div>)}
       <div _ngcontent-qal-c183="" className="container data-available-container" >
          <header  _ngcontent-qal-c183=""  className="stock-details-container overflow-hidden"  >
            <app-details-header _ngcontent-qal-c183="" _nghost-qal-c141="">
              <div _ngcontent-qal-c141="" className="container-fluid details-wrapper" >
                <div _ngcontent-qal-c141="" className="d-flex align-items-start justify-content-between mb-4 inner-wrapper" >
                  <div _ngcontent-qal-c141="" className="intro-wrapper d-flex align-items-center" n>
                    <div
                      _ngcontent-qal-c141=""
                      className="stock-logo-container flex-shrink-0 ratio ratio-1x1 me-3 me-md-4"
                    >
                      <img
                        _ngcontent-qal-c141=""
                        alt=""
                        className="img-fluid rounded-circle bg-light stock-image"
                        src={data?.stockData.img}
                      />
                      {/**/}
                    </div>
                    <div _ngcontent-qal-c141="">
                      <div
                        _ngcontent-qal-c141=""
                        className="company-name-wrapper d-flex align-items-end mb-2 mb-lg-3 flex-wrap"
                      >
                        <h5
                          _ngcontent-qal-c141=""
                          className="mb-0 stock-name lh-1 me-2"
                          style={{ fontSize: "26px", fontWeight: "700" }}
                        >
                          {data?.screenerData.stock}
                        </h5>
                        {/**/}
                        <h6
                          _ngcontent-qal-c141=""
                          className="mb-0 ticker-name lh-l"
                          style={{ color: "gray", fontWeight: "700" }}
                        >
                          {data?.stockData.stock_code}
                        </h6>
                        {/**/}
                      </div>
                      <div
                        _ngcontent-qal-c141=""
                        className="align-items-end gap-2 stock-price-details resp-slider d-lg-none"
                      >
                        <span
                          _ngcontent-qal-c141=""
                          className="stock-price lh-1 resp-slider-item "
                          style={{
                            color: "green",
                            fontSize: "12px",
                            fontWeight: "700",
                            fontSize: "18px",
                          }}
                        >
                          ₹{data?.screenerData.BSE_NSE}
                        </span>
                        {/**/}
                        <div className="d-flex">
                          <span
                            _ngcontent-qal-c141=""
                            className="d-flex align-items-center resp-slider-item"
                          >
                            <span
                              _ngcontent-qal-c141=""
                              className="stock-price-changes lh-1 positive"
                            >
                              {" "}
                              &#9652;$111.08{" "}
                            </span>
                          </span>
                          {/**/}
                          <span
                            _ngcontent-qal-c141=""
                            className="d-flex align-items-center percent-change resp-slider-item ms-2"
                          >
                            <span
                              _ngcontent-qal-c141=""
                              className="stock-price-changes lh-1 positive"
                            >
                              {" "}
                              &#9652;611.67%{" "}
                            </span>
                          </span>
                        </div>
                        {/**/}
                        <span
                          _ngcontent-qal-c141=""
                          className="stock-years lh-1 resp-slider-item"
                        >
                          Past 3 Years
                        </span>
                        {/**/}
                      </div>
                      {/**/}
                      <div _ngcontent-qal-c141="">
                        <div
                          _ngcontent-qal-c141=""
                          className="chips-container resp-slider align-items-center gap-2 d-none d-lg-flex"
                        >
                          <span
                            _ngcontent-qal-c141=""
                            className="stock-chip rounded-pill resp-slider-item "
                            style={{
                              border: "2px solid #E9FAE9",
                              borderRadius: "50%",
                              padding: "3px",
                              backgroundColor: "#e9fae9",
                              fontSize: "12px",
                            }}
                          >
                            {" "}
                            United States{" "}
                          </span>
                          {/**/}
                          <span
                            _ngcontent-qal-c141=""
                            className="stock-chip rounded-pill resp-slider-item"
                            style={{
                              border: "2px solid #E9FAE9",
                              borderRadius: "50%",
                              padding: "3px",
                              backgroundColor: "#e9fae9",
                              fontSize: "12px",
                            }}
                          >
                            {" "}
                            Information Technology{" "}
                          </span>
                          {/**/}
                          <span
                            _ngcontent-qal-c141=""
                            className="stock-chip rounded-pill resp-slider-item"
                            style={{
                              border: "2px solid #E9FAE9",
                              borderRadius: "50%",
                              padding: "3px",
                              backgroundColor: "#e9fae9",
                              fontSize: "12px",
                            }}
                          >
                            {" "}
                            Mega Cap{" "}
                          </span>
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-qal-c141=""
                    className="watchlist-wrapper d-none d-lg-block"
                    style={{ marginTop: "50px" }}
                  >
                    {/**/}
                    {/* <button
              _ngcontent-qal-c141=""
              className="btn shadow-none d-flex align-items-center watchlist-btn  btn-outline-success"
              tabIndex={0}
            >
              <i _ngcontent-qal-c141="" className="bi bi-star" />
              <span _ngcontent-qal-c141="">Watchlist</span>
            </button> */}
                    <span
                      _ngcontent-qal-c141=""
                      className="stock-price-changes lh-1 positive fonttitle"
                      style={{
                        color: "green",
                        fontSize: "12px",
                        fontWeight: "800",
                        fontSize: "28px",
                      }}
                    >
                      {" "}
                      ₹{data?.screenerData.BSE_NSE}
                    </span>
                    {/**/}
                    {/**/}
                  </div>
                </div>
                <div _ngcontent-qal-c141="" className="d-flex ">
                  <div
                    _ngcontent-qal-c141=""
                    className="chips-container d-flex align-items-center resp-slider gap-2 d-lg-none"
                  >
                    <span
                      _ngcontent-qal-c141=""
                      className="stock-chip rounded-pill resp-slider-item"
                      style={{
                        border: "2px solid #E9FAE9",
                        borderRadius: "50%",
                        padding: "1px",
                        backgroundColor: "#e9fae9",
                        fontSize: "10px",
                      }}
                    >
                      {" "}
                      United States{" "}
                    </span>
                    {/**/}
                    <span
                      _ngcontent-qal-c141=""
                      className="stock-chip rounded-pill resp-slider-item"
                      style={{
                        border: "2px solid #E9FAE9",
                        borderRadius: "50%",
                        padding: "1px",
                        backgroundColor: "#e9fae9",
                        fontSize: "10px",
                      }}
                    >
                      {" "}
                      Information Technology{" "}
                    </span>
                    {/**/}
                    <span
                      _ngcontent-qal-c141=""
                      style={{
                        border: "2px solid #E9FAE9",
                        borderRadius: "50%",
                        padding: "1px",
                        backgroundColor: "#e9fae9",
                        fontSize: "10px",
                      }}
                      className="stock-chip rounded-pill resp-slider-item"
                    >
                      {" "}
                      Mega Cap{" "}
                    </span>
                    {/**/}
                  </div>
                </div>
                <div _ngcontent-qal-c141="" className="d-flex mt-2">
                  <div
                    _ngcontent-qal-c141=""
                    className="stock-exchange-container d-flex align-items-center resp-slider gap-2 d-lg-none"
                  >
                    <span
                      _ngcontent-qal-c141=""
                      className="stock-exchange-chip red-chip d-flex align-items-center gap-1 rounded-pill resp-slider-item"
                    >
                      <i
                        _ngcontent-qal-c141=""
                        className="bi bi-clock text-secondary"
                      />
                      <span _ngcontent-qal-c141="">Jul 15, 03:10 PM</span>
                    </span>
                    <span
                      _ngcontent-qal-c141=""
                      className="stock-exchange-chip green-chip d-flex align-items-center gap-2 rounded-pill resp-slider-item"
                    >
                      <i
                        _ngcontent-qal-c141=""
                        className="bi bi-graph-up text-secondary"
                      />
                      <span _ngcontent-qal-c141="">NASDAQ</span>
                    </span>
                    <span
                      _ngcontent-qal-c141=""
                      className="stock-exchange-chip d-flex align-items-center gap-2 green-chip rounded-pill resp-slider-item"
                    >
                      <i
                        _ngcontent-qal-c141=""
                        className="bi bi-cash text-secondary"
                      />
                      <span _ngcontent-qal-c141="">Currency in USD</span>
                    </span>
                  </div>
                </div>
                {/**/}
                <div
                  _ngcontent-qal-c141=""
                  className="d-none d-lg-block"
                  style={{ marginLeft: "8%" }}
                >
                  <div
                    _ngcontent-qal-c141=""
                    className="align-items-end gap-2 mb-3 stock-price-details d-flex"
                  >
                    {/* <span _ngcontent-qal-c141="" className="" style={{fontStyle:'40px ',fontWeight:'800'}}>
              $129.24
            </span> */}
                    {/**/}

                    <span
                      _ngcontent-qal-c141=""
                      className="d-flex align-items-center"
                    >
                      <i
                        _ngcontent-qal-c141=""
                        className="bi bi-triangle-fill d-inline-block lh-1 positive"
                      />
                      <span
                        _ngcontent-qal-c141=""
                        className="stock-price-changes lh-1 positive"
                        style={{
                          color: "green",
                          fontSize: "12px",
                          fontWeight: "800",
                        }}
                      >
                        {" "}
                        &#9652; ₹{" "}
                        {Fixedvalue2(data?.screenerData.cashTotalAsset)}
                      </span>
                    </span>
                    {/**/}
                    <span
                      _ngcontent-qal-c141=""
                      className="d-flex align-items-center percent-change"
                    >
                      <i
                        _ngcontent-qal-c141=""
                        className="bi bi-triangle-fill d-inline-block lh-1 positive"
                      />
                      <span
                        _ngcontent-qal-c141=""
                        className="stock-price-changes lh-1 positive"
                        style={{
                          color: "green",
                          fontSize: "12px",
                          fontWeight: "800",
                        }}
                      >
                        {" "}
                        &#9652; 611.67%{" "}
                      </span>
                    </span>
                    {/**/}
                    <span
                      _ngcontent-qal-c141=""
                      className="stock-years lh-1"
                      style={{ color: "gray", fontSize: "14px" }}
                    >
                      Past 3 Years
                    </span>
                    {/**/}
                  </div>
                  <p
                    _ngcontent-qal-c141=""
                    className="mb-0 d-flex update-date align-items-center"
                    style={{ color: "grey", fontSize: "14px" }}
                  >
                    <span _ngcontent-qal-c141="">
                      {/* Last Updated:{formatDate(data?.screenerData.resultMonth)} */}
                    </span>{" "}
                    ·{" "}
                    <span className="ms-1" _ngcontent-qal-c141="">
                      {data?.stockData.stock_code}
                    </span>
                  </p>
                  <button
                    _ngcontent-qal-c141=""
                    className="btn shadow-none d-flex mt-2 align-items-center watchlist-btn  btn-outline-success"
                    tabIndex={0}
                  >
                    <i _ngcontent-qal-c141="" className="bi bi-star" />
                    <span _ngcontent-qal-c141="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-star"
                        viewBox="0 0 16 16"
                        className="me-2"
                      >
                        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                      </svg>{" "}
                      Watchlist
                    </span>
                  </button>

                  {/**/}
                </div>
                {/* <hr /> */}
              </div>
            </app-details-header>
            <div _ngcontent-qal-c183="">
              <app-details-tab-btns
                _ngcontent-qal-c183=""
                _nghost-qal-c142=""
                className="bordered-tabs-btns-container"
              >
                <div
                  _ngcontent-qal-c142=""
                  className="container-md px-0 bordered-tabs-btns-container bg-white"
                >
                  <div _ngcontent-qal-c142="" className="d-flex">
                    <ul
                      _ngcontent-qal-c142=""
                      className="resp-slider btns-wrapper align-items-center justify-content-end"
                    >
                      <li
                        _ngcontent-qal-c142=""
                        className="resp-slider-item"
                        onClick={() => {
                          setScreen("overview");
                        }}
                        style={{
                          borderBottom:
                            screen == "overview" ? "3px solid green" : "",
                        }}
                      >
                        <button
                          _ngcontent-qal-c142=""
                          className="btn tab-button shadow-none"
                        >
                          Overview{" "}
                        </button>
                      </li>
                      <li
                        _ngcontent-qal-c142=""
                        className="resp-slider-item"
                        onClick={() => {
                          setScreen("compliance");
                        }}
                        style={{
                          borderBottom:
                            screen == "compliance" ? "3px solid green" : "",
                        }}
                      >
                        <button
                          _ngcontent-qal-c142=""
                          className="btn tab-button shadow-none"
                        >
                          {" "}
                          Compliance{" "}
                        </button>
                      </li>
                      <li
                        _ngcontent-qal-c142=""
                        className="resp-slider-item"
                        onClick={() => {
                          setScreen("forecast");
                        }}
                        style={{
                          borderBottom:
                            screen == "forecast" ? "3px solid green" : "",
                        }}
                      >
                        <button
                          _ngcontent-qal-c142=""
                          className="btn tab-button shadow-none"
                        >
                          {" "}
                          Forecast{" "}
                        </button>
                      </li>
                      <li
                        _ngcontent-qal-c142=""
                        className="resp-slider-item"
                        onClick={() => {
                          setScreen("news");
                        }}
                        style={{
                          borderBottom:
                            screen == "news" ? "3px solid green" : "",
                        }}
                      >
                        <button
                          _ngcontent-qal-c142=""
                          className="btn tab-button shadow-none"
                        >
                          {" "}
                          News{" "}
                        </button>
                      </li>
                      <li
                        _ngcontent-qal-c142=""
                        className="resp-slider-item me-3"
                        onClick={() => {
                          setScreen("profile");
                        }}
                        style={{
                          borderBottom:
                            screen == "profile" ? "3px solid green" : "",
                        }}
                      >
                        <button
                          _ngcontent-qal-c142=""
                          className="btn tab-button shadow-none"
                        >
                          {" "}
                          Profile{" "}
                        </button>
                      </li>
                      {/**/}
                    </ul>
                  </div>
                </div>
              </app-details-tab-btns>
            </div>
            {/**/}
            {/**/}
          </header>
          {/**/}
          {screen == "overview" && (
            <div _ngcontent-qal-c183="">
              <div
                _ngcontent-qal-c183=""
                className="desktop-only-container  row"
              >
                <div
                  _ngcontent-qal-c183=""
                  className="right-side-container col-lg-4 col-md-4 col-sm-12"
                >
                  <div _ngcontent-qal-c183="" className="compliance-container">
                    <app-compliance-status-card
                      _ngcontent-qal-c183=""
                      _nghost-qal-c165=""
                    >
                      <section
                        _ngcontent-qal-c165=""
                        className="compliance-status-card card  w-100 "
                      >
                        <header
                          _ngcontent-qal-c165=""
                          className="d-flex align-items-center justify-content-between header p-4"
                        >
                          <h4
                            _ngcontent-qal-c165=""
                            className="mb-0 section-heading  me-2"
                          >
                            Shariah Compliance <br /> Status
                          </h4>
                          {/* <img
                  _ngcontent-qal-c165=""
                  src="/assets/images/section-icon-compliance.svg"
                  alt="compliance icon"
                  className="icon-img"
                /> */}
                        </header>
                        {/**/}
                        <div _ngcontent-qal-c165="">

                            {/* <img
                    _ngcontent-qal-c165=""
                    src="/assets/images/complanise-locked.svg"
                    alt="lock image"
                    className="lock-image me-3"
                  /> */}
                   {data?.screenerData?.status == 1 ? (
                    <div
                    _ngcontent-qal-c165=""
                    style={{
                      backgroundColor: "#27d17f1a",
                      color: "#009000",
                    }}
                    className="d-flex align-items-center justify-content-center py-3 compliance-chip locked-chip"
                  >
                            <h5
                            _ngcontent-qal-c165=""
                            className="mb-0 status-text fw-bold fs-2"
                          >
                            PERMISSIBLE
                          {/* HALAL{" "} */}
                          </h5>
                          </div>
                          ) : data?.screenerData?.status == 2 ? (
                            <div
                            _ngcontent-qal-c165=""
                            style={{
                              backgroundColor: "#f6f7b0",
                              color: "#adb000",
                            }}
                            className="d-flex align-items-center justify-content-center py-3 compliance-chip locked-chip"
                          >
                            <h5
                            _ngcontent-qal-c165=""
                            className="mb-0 status-text fw-bold fs-2"
                          >
                            NON PERMISSIBLE
                         {/* Haram{" "} */}
                          </h5>
                          </div>
                          ) : (
                            <div
                    _ngcontent-qal-c165=""
                    style={{
                      backgroundColor: "#ff67011a",
                      color: "#fc7318",
                    }}
                    className="d-flex align-items-center justify-content-center py-3 compliance-chip locked-chip"
                  >
                            <h5
                            _ngcontent-qal-c165=""
                            className="mb-0 status-text fw-bold fs-2"
                          >
                            DOUBTFUL
                          </h5>
                          </div>
                          )}
                           
                        
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                        </div>
                        {/**/}
                        {/**/}
                        <div
                          _ngcontent-qal-c165=""
                          className="py-4 px-3 px-md-4 btns-container d-flex justify-content-center"
                        >
                          <button
                            _ngcontent-qal-c165=""
                            className="btn mx-auto btn-success"
                            tabIndex={0}
                          >
                            {" "}
                            <i class="fa-solid fa-lock"></i> Sign up for free
                            &amp; Unlock!{" "}
                          </button>
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                        </div>
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                      </section>
                    </app-compliance-status-card>
                    {/**/}
                  </div>
                
                  {/**/}
                  {/**/}
                  <app-superinvestors-stock-overview-list
                    _ngcontent-qal-c183=""
                    _nghost-qal-c146=""
                  >
                    {/**/}
                  
                  </app-superinvestors-stock-overview-list>
                  <app-top-curated-lists
                    _ngcontent-qal-c183=""
                    _nghost-qal-c148=""
                  >
                    <section
                      _ngcontent-qal-c148=""
                      className="section mt-4 mb-4 card p-4"
                    >
                      <header
                        _ngcontent-qal-c148=""
                        className="d-flex align-items-center mb-3 mb-lg-4 gap-3 justify-content-between"
                      >
                        {/**/}
                        <h5
                          _ngcontent-qal-c148=""
                          className="section-heading mb-0 lh-base"
                          style={{ fontWeight: "600" }}
                        >
                          {" "}
                          Top User Collections <br />
                          <span
                            _ngcontent-qal-c148=""
                            className="symbol-name"
                            style={{ color: "green" }}
                          >
                            With RELIANCE.NS
                          </span>
                        </h5>
                        <a
                          _ngcontent-qal-c148=""
                          queryparamshandling="merge"
                          className="btn p-0 shadow-none text-nowrap view-all-btn text-success"
                          href="/cabinet/public-lists?lists=all"
                        >
                          {" "}
                          View All <i class="fa-solid fa-arrow-right"></i>{" "}
                          <i
                            _ngcontent-qal-c148=""
                            className="bi bi-arrow-right"
                          />
                        </a>
                        {/**/}
                        {/**/}
                      </header>
                      <div
                        _ngcontent-qal-c148=""
                        className="curated-list-container table-responsive"
                      >
                        {/**/}
                        <table
                          _ngcontent-qal-c148=""
                          className="table mb-0 table-responsive border"
                        >
                          <thead _ngcontent-qal-c148="">
                            <tr _ngcontent-qal-c148="">
                              <th _ngcontent-qal-c148="" scope="col">
                                Name
                              </th>
                              <th
                                _ngcontent-qal-c148=""
                                scope="col"
                                className="d-none d-md-table-cell"
                              >
                                Followers
                              </th>
                              <th
                                _ngcontent-qal-c148=""
                                scope="col"
                                className="text-end"
                              >
                                Stocks
                              </th>
                            </tr>
                          </thead>
                          <tbody _ngcontent-qal-c148="">
                            <tr _ngcontent-qal-c148="">
                              <td
                                _ngcontent-qal-c148=""
                                className="align-middle"
                                style={{ fontWeight: "8px" }}
                              >
                                <a
                                  _ngcontent-qal-c148=""
                                  className="d-flex flex-column justify-content-center text-decoration-none"
                                  href="/cabinet/list-details/165"
                                >
                                  <p
                                    _ngcontent-qal-c148=""
                                    className="mb-0 line-clamp-1 lh-base text-capitalize list-name"
                                  >
                                    Nifty 50 Index
                                  </p>
                                  <p
                                    _ngcontent-qal-c148=""
                                    className="mb-0 line-clamp-1 lh-base list-creator-name text-secondary"
                                  >
                                    By Dilnoza Mirsaidova
                                  </p>
                                  {/* <p
                          _ngcontent-qal-c148=""
                          className="mb-0 list-followers d-md-none"
                        >
                          18 Followers
                        </p> */}
                                </a>
                              </td>
                              <td
                                _ngcontent-qal-c148=""
                                className="align-middle d-none d-md-table-cell"
                              >
                                <a
                                  _ngcontent-qal-c148=""
                                  className="d-flex align-items-center justify-content-center text-decoration-none"
                                  href="/cabinet/list-details/165"
                                >
                                  <span
                                    _ngcontent-qal-c148=""
                                    className="list-followers"
                                  >
                                    18
                                  </span>
                                </a>
                              </td>
                              <td
                                _ngcontent-qal-c148=""
                                className="align-middle"
                              >
                                <a
                                  _ngcontent-qal-c148=""
                                  className="d-flex flex-column justify-content-center gap-1 text-decoration-none"
                                  href="/cabinet/list-details/165"
                                >
                                 
                                  <div class="circle-container">
                                    <div
                                      class="circle "
                                      style={{
                                        backgroundImage: `url("/images/circle-1.png")`,
                                      }}
                                    ></div>
                                    <div
                                      class="circle "
                                      style={{
                                        backgroundImage: `url("/images/circle-2.png")`,
                                      }}
                                    ></div>
                                    <div
                                      class="circle "
                                      style={{
                                        backgroundImage: `url("/images/circle-3.png")`,
                                      }}
                                    ></div>
                                  </div>
                                  <p className="text-end mb-0">+47 more</p>
                                </a>
                              </td>
                            </tr>
                            {/* <tr _ngcontent-qal-c148="">
                    <td _ngcontent-qal-c148="" className="align-middle">
                      <a
                        _ngcontent-qal-c148=""
                        className="d-flex flex-column justify-content-center text-decoration-none"
                        href="/cabinet/list-details/171"
                      >
                        <h6
                          _ngcontent-qal-c148=""
                          className="mb-0 line-clamp-1 lh-base text-capitalize list-name"
                        >
                          Nasdaq-100 Index
                        </h6>
                        <p
                          _ngcontent-qal-c148=""
                          className="mb-0 line-clamp-1 lh-base list-creator-name"
                        >
                          By Dilnoza Mirsaidova
                        </p>
                        <p
                          _ngcontent-qal-c148=""
                          className="mb-0 list-followers d-md-none"
                        >
                          16 Followers
                        </p>
                      </a>
                    </td>
                    <td
                      _ngcontent-qal-c148=""
                      className="align-middle d-none d-md-table-cell"
                    >
                      <a
                        _ngcontent-qal-c148=""
                        className="d-flex align-items-center justify-content-center text-decoration-none"
                        href="/cabinet/list-details/171"
                      >
                        <span _ngcontent-qal-c148="" className="list-followers">
                          16
                        </span>
                      </a>
                    </td>
                    <td _ngcontent-qal-c148="" className="align-middle">
                      <a
                        _ngcontent-qal-c148=""
                        className="d-flex flex-column justify-content-center gap-1 text-decoration-none"
                        href="/cabinet/list-details/171"
                      >
                        <ul
                          _ngcontent-qal-c148=""
                          role="list"
                          className="d-flex align-items-center justify-content-end stocks-list"
                        >
                          <li
                            _ngcontent-qal-c148=""
                            className="bg-light d-block overflow-hidden"
                          >
                            <img
                              _ngcontent-qal-c148=""
                              className="d-block img-fluid"
                              src="https://static2.finnhub.io/file/publicdatany/finnhubimage/stock_logo/ABNB.svg"
                              alt="Airbnb Inc"
                            />
                       
                          </li>
                          <li
                            _ngcontent-qal-c148=""
                            className="bg-light d-block overflow-hidden"
                          >
                            <img
                              _ngcontent-qal-c148=""
                              className="d-block img-fluid"
                              src="https://static2.finnhub.io/file/publicdatany/finnhubimage/stock_logo/ANSS.svg"
                              alt="ANSYS Inc"
                            />
                         
                          </li>
                          <li
                            _ngcontent-qal-c148=""
                            className="bg-light d-block overflow-hidden"
                          >
                            <img
                              _ngcontent-qal-c148=""
                              className="d-block img-fluid"
                              src="https://static2.finnhub.io/file/publicdatany/finnhubimage/stock_logo/CSGP.svg"
                              alt="CoStar Group Inc"
                            />
                          
                          </li>
                     
                        </ul>
                        <span
                          _ngcontent-qal-c148=""
                          className="d-block text-end more-span"
                        >
                          +98 more
                        </span>
                     
                      </a>
                    </td>
                  </tr> */}

                            {/**/}
                          </tbody>
                        </table>
                        {/**/}
                      </div>
                    </section>
                    {/**/}
                  </app-top-curated-lists>

                  <section
                      _ngcontent-qal-c148=""
                    
                      className="section mt-4 mb-4 card p-1"
                    >
                  {/* <TradingViewEmbed
        widgetType={widgetType.SYMBOL_PROFILE}
        widgetConfig={{
          symbol: `NASDAQ:${data?.stockData?.stock_code}`,
          colorTheme: 'dark',
          width: '100%',
          height: '100%',
          locale: 'en',
        }}
      /> */}
<div className="tradingview-widget-container" ref={widgetRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
     
                   {/* <CompanyProfile stockCode={data?.stockData?.stock_code}  /> */}
                    </section>



                    <section
                      _ngcontent-qal-c148=""
                    
                      className="section mt-4 mb-4 card p-1"
                    >
                      <Techenical symbol={data?.stockData?.stock_code}   />
                      </section>  
                    {/* <div style={{ height: '500px', width: '100%' }}>
      <CompanyProfile
        widgetConfig={{
          symbol: `NASDAQ:${data?.stockData?.stock_code}`,
          colorTheme: 'dark',
          width: '100%',
          height: '100%',
          locale: 'en',
        }}
      />
    </div>  */}
                </div>
            <div
          
                  _ngcontent-qal-c183=""
                  className="left-side-container col-lg-8 col-md-8 col-sm-12"
                >
                  <div
                    _ngcontent-qal-c183=""
                    className="chart-container p-lg-4 mb-4 card"
                  >
                    <h4
                      _ngcontent-qal-c183=""
                      className="company-chart-name px-3 px-lg-0 mb-2 mb-lg-4 d-none d-lg-block"
                    >
                      <span _ngcontent-qal-c183="">NVDA</span> Chart{" "}
                    </h4>
                    <app-trading-view
                      _ngcontent-qal-c183=""
                      _nghost-qal-c149=""
                    >
                      <section _ngcontent-qal-c149="" id="trading-view-chart">
                       
                        <div style={{ height: "450px", width: "99%" }}>
                          <TradingViewWidget
                            symbol={data?.stockData?.stock_code}
                            theme={Themes.LIGHT}
                            locale="en"
                            autosize
                            height="100%"
                            width="100%"
                            interval="1"
                            range="12M"
                            // hide_side_toolbar={true}
                            hide_top_toolbar={true}
                            allow_symbol_change={true}
                          />
                        </div>
                      </section>
                    </app-trading-view>
                  </div>
                  <div
                    _ngcontent-qal-c183=""
                    className="profile-container card p-4"
                  >
                    <div _ngcontent-qal-c183="">
                      <app-fundamentals
                        _ngcontent-qal-c183=""
                        _nghost-qal-c152=""
                      >
                        <section _ngcontent-qal-c152="">
                          <h4
                            _ngcontent-qal-c152=""
                            className="mb-3 mb-lg-4 section-heading me-2"
                          >
                            Key Statistics
                          </h4>
                          <div _ngcontent-qal-c152="" className="row">
                            <div
                              _ngcontent-qal-c152=""
                              className="col-xl-6 col-md-6 col-sm-12"
                            >
                              <div
                                _ngcontent-qal-c152=""
                                className="fundamentals__chart mb-4"
                              >
                                <p _ngcontent-qal-c152="">Today’s Range</p>
                                <div
                                  _ngcontent-qal-c152=""
                                  className="bar__chart"
                                >
                                  <span
                                    _ngcontent-qal-c152=""
                                    style={{ left: "42.9788%" }}
                                  />
                                </div>
                                <div
                                  _ngcontent-qal-c152=""
                                  className="bar__numbers d-flex justify-content-between"
                                >
                                  <span _ngcontent-qal-c152="">127.22</span>
                                  <span _ngcontent-qal-c152="">131.92</span>
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-qal-c152=""
                              className="col-xl-6 col-md-6 col-sm-12"
                            >
                              <div
                                _ngcontent-qal-c152=""
                                className="fundamentals__chart "
                              >
                                <p _ngcontent-qal-c152="">52 Week Range</p>
                                <div
                                  _ngcontent-qal-c152=""
                                  className="bar__chart"
                                >
                                  <span
                                    _ngcontent-qal-c152=""
                                    style={{ left: "88.6525%" }}
                                  />
                                </div>
                                <div
                                  _ngcontent-qal-c152=""
                                  className="bar__numbers  d-flex justify-content-between"
                                >
                                  <span _ngcontent-qal-c152="">39.24</span>
                                  <span _ngcontent-qal-c152="">140.76</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div _ngcontent-qal-c152="" className="row">
                            <div _ngcontent-qal-c152="" className="col">
                              <div
                                _ngcontent-qal-c152=""
                                className="fundamentals__other d-flex justify-content-between  align-item-center p-2 mt-2"
                                style={{
                                  border: "2px solid #ededed",
                                  borderRadius: "5px",
                                }}
                              >
                                <p _ngcontent-qal-c152="" className="mb-0">
                                  Today’s Open
                                </p>
                                {/**/}
                                <span _ngcontent-qal-c152="">128.26</span>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-qal-c152=""
                                className="fundamentals__other  d-flex justify-content-between align-item-center p-2 mt-2"
                                style={{
                                  border: "2px solid #ededed",
                                  borderRadius: "5px",
                                }}
                              >
                                <p _ngcontent-qal-c152="" className="mb-0">
                                  Volume
                                </p>
                                {/**/}
                                <span _ngcontent-qal-c152="">252.68M</span>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-qal-c152=""
                                className="fundamentals__other  d-flex justify-content-between align-item-center p-2 mt-2"
                                style={{
                                  border: "2px solid #ededed",
                                  borderRadius: "5px",
                                }}
                              >
                                <p _ngcontent-qal-c152="" className="mb-0">
                                  P/E Ratio (TTM)
                                </p>
                                {/**/}
                                <span _ngcontent-qal-c152="">199.80</span>
                                {/**/}
                              </div>
                            </div>
                            <div _ngcontent-qal-c152="" className="col">
                              <div
                                _ngcontent-qal-c152=""
                                className="fundamentals__other  d-flex justify-content-between align-item-center p-2 mt-2"
                                style={{
                                  border: "2px solid #ededed",
                                  borderRadius: "5px",
                                }}
                              >
                                <p _ngcontent-qal-c152="" className="mb-0">
                                  Market Cap
                                </p>
                                {/**/}
                                <span _ngcontent-qal-c152="">3.18T</span>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-qal-c152=""
                                className="fundamentals__other  d-flex justify-content-between align-item-center p-2 mt-2"
                                style={{
                                  border: "2px solid #ededed",
                                  borderRadius: "5px",
                                }}
                              >
                                <p _ngcontent-qal-c152="" className="mb-0">
                                  Avg. Volume
                                </p>
                                {/**/}
                                <span _ngcontent-qal-c152="">152.33M</span>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-qal-c152=""
                                className=" d-flex justify-content-between align-item-center p-2 mt-2"
                                style={{
                                  border: "2px solid #ededed",
                                  borderRadius: "5px",
                                }}
                              >
                                <p
                                  _ngcontent-qal-c152=""
                                  className="long__text mb-0"
                                >
                                  Dividend Yield
                                </p>
                                <span _ngcontent-qal-c152="">0.02%</span>
                              </div>
                            </div>
                          </div>
                        </section>
                      </app-fundamentals>
                    </div>
                  </div>
                  {/**/}
                  {/**/}
                  {/**/}
                  <div className="mt-3">
                    <Fundemental symbol={data?.stockData?.stock_code} />
                    </div>
                  <div _ngcontent-qal-c183="">
                    <app-related-stocks
                      _ngcontent-qal-c183=""
                      id="Related-Companies"
                      _nghost-qal-c153=""
                    >
                      {/**/}
                      <div
                        _ngcontent-qal-c153=""
                        className="main3 main__box mt-4 card p-4"
                      >
                        <h4 _ngcontent-qal-c153="" className="main3-text">
                          {" "}
                          Alternate Halal Stocks{" "}
                        </h4>
                        <table
                          _ngcontent-qal-c153=""
                          className="table mb-0 border "
                        >
                          <thead _ngcontent-qal-c153="" className="table--head">
                            <tr
                              _ngcontent-qal-c153=""
                              className="table--head--row"
                            >
                              <th
                                _ngcontent-qal-c153=""
                                scope="col"
                                role="button"
                                className="table--heading"
                              >
                                {" "}
                                Name {/**/}
                              </th>
                              <th
                                _ngcontent-qal-c153=""
                                scope="col"
                                role="button"
                                className="table--heading text-end"
                              >
                                {" "}
                                Price {/**/}
                              </th>
                              <th
                                _ngcontent-qal-c153=""
                                scope="col"
                                role="button"
                                className="table--heading text-end"
                              >
                                {" "}
                                Change {/**/}
                              </th>
                              <th
                                _ngcontent-qal-c153=""
                                scope="col"
                                className="table--heading text-center"
                              >
                                {" "}
                                Compliance{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table--body p-3">
                            {filteredItems &&
                              filteredItems.map((item, index) => (
                                <tr
                                  key={index}
                                  className="table--body--row"
                                  onClick={() => setData(item)}
                                >
                                  <td className="table--data align-middle">
                                    <p className="d-flex text-decoration-none align-items-center">
                                      <div className="data--img me-3">
                                        <img
                                          className=""
                                          src={item?.stockData.img}
                                          alt="img"
                                          style={{
                                            height: "40px",
                                            width: "40px",
                                            borderRadius: "50%",
                                            border: "0.5px solid #ededed",
                                          }}
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "./images/dummy.png";
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <h6 className="mb-0 company--name">
                                          {item?.screenerData.stock}
                                        </h6>
                                        <p className="mb-0 stock--name">
                                          {item?.stockData.stock_code}
                                        </p>
                                      </div>
                                    </p>
                                  </td>
                                  <td className="table--data text-end align-middle">
                                    <p className="price--data text-decoration-none d-flex align-items-center justify-content-end align-middle">
                                      {item?.screenerData.cashTotalAsset ===
                                        undefined ||
                                      item?.screenerData.cashTotalAsset ===
                                        null ||
                                      isNaN(
                                        Number(
                                          item?.screenerData.cashTotalAsset
                                        )
                                      )
                                        ? "N/A"
                                        : Number(
                                            item?.screenerData.cashTotalAsset
                                          ).toFixed(2)}{" "}
                                      {/* Example: Format price to 2 decimal places */}
                                    </p>
                                  </td>
                                  <td className="table--data text-end align-middle">
                                    <p className="text-decoration-none d-flex align-items-center justify-content-end data--price--change red--text">
                                      <i className="bi-caret-down-fill" />
                                      <span className="ms-1">
                                        {" "}
                                        {item?.screenerData.debtAsset ===
                                          undefined ||
                                        item?.screenerData.debtAsset === null ||
                                        isNaN(
                                          Number(item?.screenerData.debtAsset)
                                        )
                                          ? "N/A"
                                          : Number(
                                              item?.screenerData.debtAsset
                                            ).toFixed(2)}
                                        %
                                      </span>{" "}
                                      {/* Example: Format percentage */}
                                    </p>
                                  </td>
                                  {item?.screenerData?.status == 1 ? (
                                    <td className="text-center">
                                      {" "}
                                      <button
                                        className="btn"
                                        style={{
                                          backgroundColor: "#DFFFD6",
                                          color: "#218303",
                                        }}
                                      >
                                        HALAL{" "}
                                        <i
                                          class="fa-solid fa-check"
                                          style={{ color: "green" }}
                                        ></i>{" "}
                                      </button>
                                    </td>
                                  ) : item?.screenerData?.status == 2 ? (
                                    <td className="text-center">
                                      {" "}
                                      <button
                                        className="btn"
                                        style={{
                                          backgroundColor: "#ffd6d6",
                                          color: "#9f0000",
                                        }}
                                      >
                                        HARAM{" "}
                                        <i
                                          class="fa-solid fa-times"
                                          style={{ color: "red" }}
                                        ></i>{" "}
                                      </button>
                                    </td>
                                  ) : (
                                    <td className="text-center">
                                      {" "}
                                      <button
                                        className="btn"
                                        style={{
                                          backgroundColor: "#feffd6",
                                          color: "#9f8500",
                                        }}
                                      >
                                        {" "}
                                        UNKNOWN
                                        <i
                                          class="fa-solid fa-circle-info"
                                          style={{ color: "#9f8500" }}
                                        ></i>{" "}
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        {/* <PaginationIndex /> */}
                      </div>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                    </app-related-stocks>
                  </div>
                 
                  {/**/}
                  {/**/}
                </div>
              </div>
              {/**/}
              {/**/}
            </div>
          )}
          {screen == "profile" && <Profile value={data?.stockData.industry} />}
          {screen == "news" && <Relatednews />}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Stock;
