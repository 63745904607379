import React from 'react';
import ReactApexChart from 'react-apexcharts';

class Semigraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 55],
      options: {
        chart: {
          type: 'donut',
          height: 350,
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10,
          }
        },
        grid: {
          padding: {
            bottom: -80
          }
        },
        legend: {
          show: true,
          fontSize: '16px',
          fontFamily: 'Poppins',
          fontWeight: 600,
          position: 'bottom',
          labels: {
            colors: ['#fff'],
          },
          markers: {
            width: 12,
            height: 12,
            radius: 0,
          }
        },
        colors: ['#F6D8D8', '#D43A3A'],
        dataLabels: {
          enabled:false,
          formatter: (val) => `${val.toFixed(1)}%`,
          style: {
            fontSize: '16px',
            
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            colors: ['#fff'],
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => `${value}%`,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height={350} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default Semigraph;
