import React,{useState} from 'react'
import "./style.css"
const Zakatlight = () => {

    const [Cash, setCash] = useState(false);

    const [formdata, setFormdata] = useState({
      cash: "",
      goldandsilver: "",
      stockfund: "",
      business: '',
      property: "",
      loans: "",
      immediatebills: '',
      employeewages: "",
  })
  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };
  // const handleCheckboxChange = (e) => {
  //   const { name, value } = e.target;
  //   // setErrors({ ...errors, [name]: error });
  //   setFormdata({ ...formdata, [name]: value });
  // };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormdata({
        ...formdata,
        [name]: checked, // Update the checkbox state with true/false
    });
};
    return (

        <div>
        {/* <Navbarwhite />   */}
    <div
      className="home-header top-header"
      style={{width:'90%',margin:'auto'}}
    >
      <div className="row" style={{marginTop:'7%'}}>
        <div className="col-lg-6 col-md-6 mt-2">
          <div
            className="card p-4 pt-2 "
            style={{ alignItems: "center",width:'95%',borderRadius:'10px'}}
          >
            <div>
              <div className="mt-2 mb-3">
         <span className='fs-4 '><span style={{color:'#7E66BC'}}>Zakat</span> Calculator</span>
              </div>
              <div className="mt-2 mb-3" style={{width:'80%'}}>
         <span className='fs-5 '>Select all the items that you think 
         may apply to you.</span>
              </div>
    
              <p className=""  style={{fontWeight:'400',lineHeight:'25px' ,fontSize:'16px',fontFamily:'Poppins, sans-serif'}}>
              Based on the selections, you would be presented to enter the details in the next steps.
              </p>
              {/* <button
                className="btn text-white btn-sm"
                style={{ backgroundColor: "#009000",borderRadius:'20px' ,fontFamily:'Poppins, sans-serif'}}
              >
            Learn more
              </button> */}
            </div>
            <div className='row w-100 mt-3 p-0 mb-4'>
                <div className='col-md-12 col-lg-12 d-flex'  style={{fontSize:'18px'}}>
                    <span className='me-3 '>Select your Currency</span>
                    <select className='form-select form-select-sm text-white' style={{width:'25%',backgroundColor:'#7E66BC',fontWeight:400}} >
                        <option value='USD'>USD($)</option> 
                        </select>
                </div>
                
    
            </div>
           
            
          </div>
        </div>
     
        <div className="col-lg-6 col-md-6 herocard mt-2">
  <div className="card p-4" style={{borderRadius: '10px' }}>
    <div>
      <div className="row">
        <div className="col-6 text-start">
          <span style={{fontSize:'20px',fontWeight:400}}>Select what you own:</span>
        </div>
      </div>

      <div className="row mt-3 p-0 justify-content-center">
        <div className="col-10  d-flex flex-column align-items-start">
          <div className="mt-2">
  <input
  type="checkbox"
  class="custom-checkbox me-3"
  name="cash"
  onChange={handleCheckboxChange}
  checked={formdata?.cash}
/>
            <span className="">Cash (In Hand/In Bank Account)</span>
          </div>

          <div className="mt-4">
          <input
  type="checkbox"
  class="custom-checkbox me-3"
  name="goldandsilver"
  onChange={handleCheckboxChange}
  checked={formdata?.goldandsilver}
/>
            <span className="">Gold & Silver</span>
          </div>
          <div className="mt-4">
          <input
  type="checkbox"
  class="custom-checkbox me-3"
  name="stockfund"
  onChange={handleCheckboxChange}
  checked={formdata?.stockfund}
/>
            <span className="">Stock/Fund Investments</span>
          </div>
          <div className="mt-4">
          <input
  type="checkbox"
  class="custom-checkbox me-3"
  name="business"
  onChange={handleCheckboxChange}
  checked={formdata?.business}
/>
            <span className="">Business Assets</span>
          </div>
          <div className="mt-4">
          <input
  type="checkbox"
  class="custom-checkbox me-3"
  name="property"
  onChange={handleCheckboxChange}
  checked={formdata?.property}
/>
            <span className="">Property (for trading)</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className="row">
        <div className="col-6 text-start mt-4">
          <span style={{fontSize:'20px',fontWeight:400}}>Select your liabilities:</span>
        </div>
      </div>
      <div className="row mt-3 p-0 justify-content-center">
        <div className="col-10  d-flex flex-column align-items-start">
          <div className="mt-2">
          <input
  type="checkbox"
  class="custom-checkbox me-3"
  name="loans"
  onChange={handleCheckboxChange}
  checked={formdata?.loans}
/>         
  <span className="">Loans</span>
          </div>
          <div className="mt-4">
          <input type="checkbox"
  class="custom-checkbox me-3"
  name="immediatebills"
  onChange={handleCheckboxChange}
  checked={formdata?.immediatebills}
/>
<span className="">Immediate bills/Taxes/Rent</span>
          </div>
          <div className="mt-4">
          <input
  type="checkbox"
  class="custom-checkbox me-3"
  name="employeewages"
  onChange={handleCheckboxChange}
  checked={formdata?.employeewages}
/>
    <span className="">Employee Wages/Dealer Payments</span>
          </div>
          <div className="mt-5 mb-5 w-100">
            <button className='btn text-white w-100' style={{backgroundColor:'#7E66BC'}}>
                Next
            </button>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    </div>
    
    {/* <Footer /> */}
        </div>
      )
}

export default Zakatlight