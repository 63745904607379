import React ,{useState ,useEffect,useContext } from 'react';
import "./css/login.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import FetchData from "../fetchapi/Apifetch";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../components/Utills/UserContext';
import { signInWithEmailAndPassword,signInWithPopup } from 'firebase/auth';
import { auth ,googleAuthProvider} from "../../firebase";



const Forgotpassword = () => {
    const { theme, setTheme } = useContext(UserContext);

  const [buttonclick, setbuttonclick] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [formdata1, setFormdata1] = useState({
    first_name: "",
    last_name: "",
    email: "",  
    password:"",
    status:'1'
  });

  // const [Value,setValue] =
  useEffect(() => {
    stocksall();
    // stocks1();
  }, []);

  const stocksall = async (value) => {
    try {
      // alert('hello');
      const res = await FetchData("stocksall", "GET", null, true, false);
      if (res) {
        const searchstock = res.data;
        const jsonLoginData = JSON.stringify(searchstock);
        localStorage.setItem("stockdata",jsonLoginData);
        // setStocklist(searchstock);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




    const CheckEmaildata = async (emailvalue) => { 
      const res = await FetchData(
        `usercheck?q=${emailvalue}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
    //  console.log('res',res.data)
        // setData(res.data.data);
        // setloader(false);
      }
    };


  const LoginCheck = async () => {
    try {
        setbuttonclick(true)
        if (!email || !password) {
            setbuttonclick(false)
            toast.error("Please enter both email and password.");
            return;
        }
        const token = false;
        const data = {
            email: email,
            password: password,
            user_type:1
        };
        const res = await FetchData("login", 'POST', JSON.stringify(data), token, false);
        if (res.data) {
            localStorage.setItem("login",true);
            setUser(true)
            const jsonLoginData = JSON.stringify(res.data);
            localStorage.setItem("email",jsonLoginData);
            toast.success("Login successfully");
            setEmail('');
            setPassword('');  
            navigate("/dashboard");
            // window.location.href = "/dashboard" 
        }
    } catch (error) {
        toast.error(error.message);
        setbuttonclick(false)
    }
}


// const handlegooglelogin = () => {
//   signInWithPopup(auth,googleAuthProvider)
//     .then(async (result) => {
//       const { user } = result;
//       console.log('result', result);

//       // Define CheckEmaildata function here
//       const checkEmailData = async (emailValue) => {
//         const res = await FetchData(
//           `usercheck?q=${emailValue}`,
//           "GET",
//           null,
//           true,
//           false
//         );
//         if (res.success === true) {
//           localStorage.setItem("login",true);
//           setUser(true)
//           toast.success("Login successfully");
//           const jsonLoginData = JSON.stringify(user);
//           localStorage.setItem("email",jsonLoginData);
//           navigate("/dashboard");
//         } 
//         else {
//           const token = false;
//           const formselect = {     
//                email: user.email,
//                first_name: user.displayName,
//                password:'',
//                last_name:user.displayName,
//                status:'1'
//            };
//           const res = await FetchData("add/user", 'POST', JSON.stringify(formselect), token, false);
//           if (res.success) {
//             localStorage.setItem("login",true);
//             setUser(true)
//             toast.success("Login successfully");
//             const jsonLoginData = JSON.stringify(user);
//             localStorage.setItem("email",jsonLoginData);
//             navigate("/dashboard");
//             return true;
//           }
          
//         }
      
//       };
//       const checkUser = await checkEmailData(user.email);
//     })
//     .catch((error) => {
//       console.error('Error during Google login:', error);
//     });
// };


  return (
    <>
    <div className={`container-fluid mt-5 ${theme?'text-white':'text-black'}`} style={{width:"80%",border:"1px solid #ededed"}}>
    <ToastContainer />
        <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-12  d-flex align-items-center'>
        <div className='p-4 w-100'>
        <h3 className='' style={{marginTop:"-10%"}}>Forgot Your Password? </h3>
        <p className='mb-5'>Don’t worry, happens to all of us. Enter your email below 
        to recover your password</p>
      <form>
  <div class="mb-3 mt-3 ">
    <label for="exampleInputEmail1" class="form-label">Email</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>{setEmail(e.target.value)}} />
  </div>

  
  <button type="submit" class="btn btn-primary w-100 mt-5" disabled={buttonclick} style={{backgroundColor:"#7E66BC"}} onClick={()=>{LoginCheck()}}>SUBMIT</button>
  <p className='text-center my-2 mt-4'>Or login with</p>
     <div className='d-flex'>
     <div className='card d-flex flex-row w-50 me-1 py-2 align-items-center justify-content-center btn-card' 
    //  onClick={()=>{handlegooglelogin()}}
     >
        <img src="images/icons/Google.png" alt='logo' style={{width:"21px",height:"22px"}} /> 
         <p className='mb-0 ms-1'>Google</p>
     </div>
     <div className='card d-flex flex-row w-50 py-2 align-items-center justify-content-center btn-card'>
        <img src="images/icons/meta.png" alt='logo' style={{width:"33px",height:"18.56px"}} /> 
         <p className='mb-0 ms-1'>Meta</p>
     </div>
     </div>
</form>
    </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 p-0 img-layout' >
        <img src="images/backgroundlogin.jpeg" alt='logo' style={{width:"100%",height:"100%"}} /> 
          <div className='text-overlay'>
          <img src="images/logo.png" alt='logo' style={{width:"300px",height:"225px"}} /> 
          <h1 className='fw-bold'>Islamic Tijarath</h1>
          <p className='paratext px-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim veniam, quis nostrud exercitation nisi ut aliquip ex ea commodo consequat. </p>
          <div style={{margin:"auto 0px"}}>
          <p className='paratext mt-3'>Don’t have an account</p>
          <a className='paratext btn-card text-white' onClick={()=>{window.location.href="/register"}} >Sign Up?</a>
          </div>
          </div>
        </div>
        </div>
    </div>


    
    </>
  )
}

export default Forgotpassword