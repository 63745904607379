

module.exports = {
        // BASE_URL:"http://213.21.36.52:4001/",
        // APIBASE_URL:"http://213.210.36.52:4001/api",

    //  BASE_URL: "http://localhost:4001/",
    //  APIBASE_URL: "http://localhost:4001/api",

    //  BASE_URL:"http://3.108.189.162",
    //  APIBASE_URL:"http://3.108.189.162/api",

     BASE_URL:"https://islamicapi.qcodesinfotech.com",
     APIBASE_URL:"https://islamicapi.qcodesinfotech.com/api",

    //  BASE_URL:"http://3.26.29.45:4001/",
    //  APIBASE_URL:"http://3.26.29.45:4001/api",
};
