import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../footer/Footer'

const Plan = () => {
    const [screen,setScreen]=useState('monthly');
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState(true);

  const handleCheckboxChange = () => {
    setIsChecked1(false);
    setIsChecked(!isChecked);
  
  };

  const handleCheckboxChang1 = () => {
    setIsChecked(false);
    setIsChecked1(!isChecked1);
  };
  return (
   
   <>
   <Navbar/> 
    <div className='container-fluid' style={{width:"90%",marginTop:"8%"}}>

        <div className='card p-4'>

            <div>
                <h3 style={{color:"#218303"}}>Upgrade to Premium</h3>
                <div className='d-flex justify-content-between'>
                    <h6 className=''> Make Better Halal Investment Decisions</h6>
                    <div>
                        <button className={`btn  ${screen=='monthly'?'btn-success btn-sm':'btn-outline-success btn-sm'} me-3`} style={{borderRadius:'15px'}} onClick={()=>setScreen('monthly')} >Monthly</button>
                        <button className={`btn  ${screen=='yearly'?'btn-success btn-sm':'btn-outline-success btn-sm'}`} style={{borderRadius:'15px'}} onClick={()=>setScreen('yearly')} >Yearly</button>
                    </div>
                </div>
            </div>

          {screen =="monthly" && 
          <div>
            <div className='d-flex justify-content-center mb-5 mt-5'>
            <div className='me-4' style={{border:isChecked1?"2px solid green":"2px solid black",padding:"7px 15px",borderRadius:'10px'}}>
            <input 
        type='checkbox' 
        name="plan" 
        className='form-check-input me-4' 
        style={{
          appearance: 'none', /* Hide default checkbox */
          width: '20px',
          height: '20px',
          borderRadius: '15px',
          backgroundColor: isChecked1 ? 'green' : '', // Conditional background color
          border: '1px solid #ccc', // Optional border for checkbox
          cursor: 'pointer', // Change cursor on hover
        }}
        onChange={handleCheckboxChang1}
        checked={isChecked1}
      />
                <span className='font-para'>$199.00/Month</span>
            </div>
            <div  style={{border:isChecked?"2px solid green":"2px solid black",padding:"7px 15px",borderRadius:'10px'}}>
            {/* <i class="fa-solid fa-check me-5"></i> */}
            <input 
        type='checkbox' 
        name="plan" 
        className='form-check-input me-4' 
        style={{
          appearance: 'none', /* Hide default checkbox */
          width: '20px',
          height: '20px',
          borderRadius: '15px',
          backgroundColor: isChecked ? 'green' : '', // Conditional background color
          border: '1px solid #ccc', // Optional border for checkbox
          cursor: 'pointer', // Change cursor on hover
        }}
        onChange={handleCheckboxChange}
        checked={isChecked}
      />

                <span className='font-para  '>$299.00/Month</span>
            </div>
        </div>

          <div className='mx-auto w-100 d-flex flex-column mt-3 mb-5'>
              <button className='btn btn-success w-25 mx-auto btn-md' style={{borderRadius:"20px"}}>Subscribe Now</button>
              <p className='font-para mx-auto text-secondary mt-5 '>*Subscriptions renew automatically. For information regarding refund <br/> and payment policy, please refer to our Terms of Use</p>
          </div>
           
           <div className='mt-3'>
            <div className='mt-3 d-flex justify-content-around  flex-wrap text-center row'>
          <div className='card d-flex align-items-center col-lg-4  col-sm-12' style={{width:"32%",padding:"20px"}}>
             <img src='images/increase.png' alt='img' height={50} width={50}/>
             <h5 className='mt-3'>Shariah Compliance Detailed Report</h5>
             <p className='font-para mt-2'>Get access to in-depth Shariah- compliance reports, including detailed business and financial screening results.</p>
          </div>
          <div className='card d-flex align-items-center col-lg-4   col-sm-12' style={{width:"32%",padding:"20px"}}>
             <img src='images/increase.png' alt='img' height={50} width={50}/>
             <h5 className='mt-3'>Shariah Compliance Detailed Report</h5>
             <p className='font-para mt-2 '>Get access to in-depth Shariah- compliance reports, including detailed business and financial screening results.</p>
          </div>
          <div className='card d-flex align-items-center col-lg-4   col-sm-12' style={{width:"32%",padding:"20px"}}>
             <img src='images/increase.png' alt='img' height={50} width={50}/>
             <h5 className='mt-3'>Stock forecast</h5>
             <p className='font-para mt-2'>Get access to in-depth Shariah- compliance reports, including detailed business and financial screening results.</p>
          </div>
          </div>
          <div className='mt-3 d-flex justify-content-around flex-wrap  text-center row'>
          <div className='card d-flex align-items-center col-lg-4  col-sm-12  ' style={{width:"32%",padding:"20px"}}>
             <img src='images/increase.png' alt='img' height={50} width={50}/>
             <h5 className='mt-3'>Investment checklist</h5>
             <p className='font-para mt-2'>Get access to in-depth Shariah- compliance reports, including detailed business and financial screening results.</p>
          </div>
          <div className='card d-flex align-items-center  col-lg-4  col-sm-12' style={{width:"32%",padding:"20px"}}>
             <img src='images/increase.png' alt='img' height={50} width={50}/>
             <h5 className='mt-3'>Shariah Compliance Detailed Report</h5>
             <p className='font-para mt-2'>Get access to in-depth Shariah- compliance reports, including detailed business and financial screening results.</p>
          </div>
          <div className='card d-flex align-items-center col-lg-4 col-sm-12 ' style={{width:"32%",padding:"20px"}}>
             <img src='images/increase.png' alt='img' height={50} width={50}/>
             <h5 className='mt-3'>Shariah Compliance Detailed Report</h5>
             <p className='font-para mt-2'>Get access to in-depth Shariah- compliance reports, including detailed business and financial screening results.</p>
          </div>
          </div>
          </div>
        </div>
          }
          {screen =="yearly" && 
           <h2>yearly Plan</h2>
          }
        </div>
        <Footer />
    </div>
   </>
  )
}

export default Plan