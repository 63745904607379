import React from 'react'

function Bestscreenstock() {
    const tabledata = [
        {
          COMPANY: "APPLE INC",
          Exchange: "NASDAQ",
          Industry: "Consumer Electronics",
          StockScore: "55",
          MarketCap: "$2.43T",
          Price: "$146.92",
          D: "0.06%",
          EBITDA: "111.86B",
          PE: "28.43x",
          DE: "4.13",
        },
      ];
      const repeatedItems = Array(15).fill(tabledata[0]).map((item, index) => (
  <tr className='text-center' key={index} >
    <td>{item.COMPANY}</td>
    <td>{item.Exchange}</td>
    <td>{item.Industry}</td>
    <td>{item.StockScore}</td>
    <td >{item.MarketCap}</td>
    <td>{item.Price}</td>
    <td>{item.D}</td>
    <td>{item.EBITDA}</td>
    <td>{item.PE}</td>
    <td>{item.DE}</td>
  </tr>
));

  return (
    <div>

        <div className='row mt-5 d-flex justify-content-center'>
        <div className="col-lg-12 col-md-12 herocard mt-5 p-3" >
                  <div className="mt-3 d-flex justify-content-between mb-3">
                    <div className="mt-2">
                    <h4 className="ms-2"><span style={{color:'#7E66BC'}}>Best</span><span  > Stock Screener</span></h4>
                    </div>
                    <div className="d-flex ">
                        <div>
                    <input
                className="form-control ms-2 me-2 navsearch1"
                type="search"
                placeholder="Search"
                aria-label="Search"
                style={{ border: "0.5px solid #333333" ,borderRadius:'20px'}}
              />
              </div>
              <div>
              <img src="/images/setting1.png" width={32} />
              </div>
              <div className="ms-2">
              <img src="/images/list1.png" width={32} />
              </div>
                    </div>
                  </div>
                  <div className="row">
                    
                    <div className='ms-5 d-flex gap-2'>
                    <div>
                        <img src="/refresh.png" alt="refresh" height={25} width={25} />
                    </div>
                    <div  className='text-center' style={{border: "1.3px solid #EEEEEE", width: "fit-content", borderRadius: "10px", padding: '8px 20px',fontSize:"12px",color:'#7E66BC'}}>
     overview
    </div>
                    {['stock score', 'valuation', 'financials', 'Earnings & Revenue', 'Performance', 'Ownership', 'Forecast', 'Dividends'].map((item, index) => (
    <div key={index} className='text-center' style={{border: "1.3px solid #EEEEEE",backgroundColor:'#EEEEEE', width: "fit-content", borderRadius: "10px", padding: '8px 20px',fontSize:"12px"}}>
        {item}
    </div>
))}
</div>
<div>
</div>

                  <div className="col-lg-12  col-md-12 mt-4 ">
               
       <div>

                    <table
                      class="table mt-2 table-responsive border-none"
                      style={{
                        textAlign: "start",
                        backgroundColor: "#1B1B1B",
                        overflow: "hidden", 
                          fontSize:"12px"
                      }}
                    >
                      <thead style={{borderBottom:'1.5px solid #484848',borderTop:'1.5px solid #484848',}} >
                        <tr className='text-white' >
                          <th scope="col" className='tablehead'>TICKER/COMPANY</th>
                          <th scope="col" className='tablehead'>Exchange</th>
                          <th scope="col" className='tablehead' >Industry</th>
                          <th scope="col" className='tablehead' >Stock Score</th>
                          <th scope="col" className='tablehead' >Market Cap</th>
                          <th scope="col" className='tablehead' >Price</th>
                          <th scope="col" className='tablehead' >1D%</th>
                          <th scope="col" className='tablehead' >EBITDA</th>
                          <th scope="col"  className='tablehead'>P/E</th>
                          <th scope="col"  className='tablehead' >D/E</th>
                        </tr>
                      </thead>
                      <tbody>
                      {repeatedItems}
                      </tbody>
                     
                    </table>
                    </div>
                  </div>
                </div>
                
                </div>
                </div>
            
    </div>
  )
}

export default Bestscreenstock